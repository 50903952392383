import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import Link from '../../../../../components/Link';
import Paragraph from '../../../../../components/Paragraph';
import { ListedPlayer } from './styles';
import reservedSlotIcon from '../../../../../assets/images/reservedSlot-icon.svg';
import availableSlotIcon from '../../../../../assets/images/availableSlot-icon.svg';
import LoadImage from '../../../../../components/common/LoadImage';
import { FriendsIcon } from '../../../../Friends/components/FriendCard/styles';

const PlayerModal = ({
  player,
  reserved,
  available,
  id,
  gameType,
}) => {
  const { accountInfo } = useSelector(state => state.session);
  const isWildCard = player?.slotType === 'WILD_CARD';
  const isPending = player?.status === 'PENDING';

  const teamAverageLevel = (player?.user?.gameLevel?.doubles + player?.partner?.gameLevel?.doubles) / 2;
  const teamAveragePoints = (player?.user?.points?.doubles + player?.partner?.points?.doubles) / 2;
  const teamAverageRank = (player?.user?.rank?.doubles + player?.partner?.rank?.doubles) / 2;

  const userLevel = player?.user?.gameLevel?.[gameType];
  const partnerLevel = player?.partner?.gameLevel?.[gameType];

  const isFriend = (userId) => accountInfo?.friendsIds?.includes(userId);

  return (
    <ListedPlayer you={accountInfo?.userId === player?.userId} isPending={isPending}>
      {reserved || available || isWildCard ? (
        isWildCard && gameType === 'singles' ? (
          <Link to={`/accounts/${player?.user?.id}`}>
            <div className="listPlayer">
              <div className="playerInfo">
                <div className="nr">
                  <Paragraph small bold className="id">{id + 1}</Paragraph>
                </div>
                {player?.user?.profilePicture ? (
                  <img
                    className="avatarImg"
                    src={LoadImage(`accounts/${player?.user?.userId}/${player?.user?.profilePicture}`, true)}
                    alt={player?.user?.firstName
                      && player?.user?.lastName
                      && player?.user?.firstName.charAt(0) + player?.user?.lastName.charAt(0)}
                  />
                ) : (
                  <div className="avatar-placeholder">
                    <Paragraph medium bold className="text-uppercase">
                      {player?.user?.firstName
                        && player?.user?.lastName
                        && player?.user?.firstName.charAt(0) + player?.user?.lastName.charAt(0)}
                    </Paragraph>
                  </div>
                )}

                <div className="nameWrapp">
                  <div>
                    <Paragraph bold normal>
                      {isFriend(player?.user?.userId) && (
                        <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                      )}
                      {player?.user?.firstName}
                      {' '}
                      {player?.user?.lastName}
                    </Paragraph>
                    <Paragraph className="id" smaller>
                      #
                      {player?.userId}
                    </Paragraph>
                  </div>
                  <div className="gameLevel">
                    <Paragraph bold>
                      <Trans ns="events" i18nKey="playerGameLevel">Game Level</Trans>
                      :
                      {' '}
                      {userLevel}
                    </Paragraph>
                  </div>
                  <div>
                    <Paragraph small className="max-content wildcard">
                      <div className="popup">
                        <Paragraph>
                          <Trans ns="events" i18nKey="wildcard">Wildcard</Trans>
                        </Paragraph>
                      </div>

                      <img src={LoadImage('wildcard.svg')} alt="" />
                      <Trans ns="events" i18nKey="wildcard">Wildcard</Trans>
                    </Paragraph>

                    {isPending
                      ? (
                        <Paragraph small className="max-content pending">
                          <img src={LoadImage('pending-clock.svg')} alt="" />
                          <Trans ns="events" i18nKey="pending">Pending</Trans>
                        </Paragraph>
                      ) : ''}
                  </div>
                </div>
              </div>
              <div className="rank">
                <Paragraph bold normal><Trans ns="events" i18nKey="playerRank">Rank</Trans></Paragraph>
                <Paragraph bold normal>
                  {Number.isNaN(player?.user?.rank) || player?.user?.rank === 0 || player?.user?.rank === null
                    ? '-'
                    : player?.user?.rank}
                </Paragraph>
              </div>
            </div>
          </Link>
        ) : isWildCard && gameType === 'doubles' ? (
          <div className="team">
            <div className="group">
              <div className="nr">
                <Paragraph bold>{id + 1}</Paragraph>
              </div>
              <div className="players">
                <Link to={`/accounts/${player?.user?.id}`}>
                  <div className="player">
                    <div className="avatar">
                      {player?.user?.profilePicture ? (
                        <img
                          className="avatarImg"
                          src={LoadImage(`accounts/${player?.user?.userId}/${player?.user?.profilePicture}`, true)}
                          alt={player?.user?.firstName
                            && player?.user?.lastName
                            && player?.user?.firstName.charAt(0) + player?.user?.lastName.charAt(0)}
                        />
                      ) : (
                        <div className="avatar-placeholder">
                          <Paragraph medium bold className="text-uppercase">
                            {player?.user?.firstName
                              && player?.user?.lastName
                              && player?.user?.firstName.charAt(0) + player?.user?.lastName.charAt(0)}
                          </Paragraph>
                        </div>
                      )}

                    </div>
                    <div className="playerInfo">
                      <div className="playerName">
                        <Paragraph bold normal>
                          {isFriend(player?.user?.userId) && (
                            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                          )}
                          {player?.user?.firstName}
                          {' '}
                          {player?.user?.lastName}
                        </Paragraph>
                        <Paragraph className="id" smaller>
                          #
                          {player?.userId}
                        </Paragraph>
                      </div>
                      <Paragraph smaller>
                        <Trans ns="events" i18nKey="playerGameLevel">Game Level</Trans>
                        :
                        {' '}
                        {userLevel}
                      </Paragraph>
                      <div>
                        <Paragraph small className="max-content wildcard">
                          <div className="popup">
                            <Paragraph>
                              <Trans ns="events" i18nKey="wildcard">Wildcard</Trans>
                            </Paragraph>
                          </div>

                          <img src={LoadImage('wildcard.svg')} alt="" />
                          <Trans ns="events" i18nKey="wildcard">Wildcard</Trans>
                        </Paragraph>

                        {isPending
                          ? (
                            <Paragraph small className="max-content pending">
                              <img src={LoadImage('pending-clock.svg')} alt="" />
                              <Trans ns="events" i18nKey="pending">Pending</Trans>
                            </Paragraph>
                          ) : ''}
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to={`/accounts/${player?.partner?.id}`}>
                  <div className="player">
                    <div className="avatar">
                      {player?.partner?.profilePicture ? (
                        <img
                          className="avatarImg"
                          src={LoadImage(`accounts/${player?.partnerId}/${player?.partner?.profilePicture}`, true)}
                          alt={player?.partner?.firstName
                            && player?.partner?.lastName
                            && player?.partner?.firstName.charAt(0) + player?.partner?.lastName.charAt(0)}
                        />
                      ) : (
                        <div className="avatar-placeholder">
                          <Paragraph medium bold className="text-uppercase">
                            {player?.partner?.firstName
                              && player?.partner?.lastName
                              && player?.partner?.firstName.charAt(0) + player?.partner?.lastName.charAt(0)}
                          </Paragraph>
                        </div>
                      )}
                    </div>
                    <div className="playerInfo">
                      <div className="playerName">
                        <Paragraph bold normal>
                          {isFriend(player?.partner?.userId) && (
                            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                          )}
                          {player?.partner?.firstName}
                          {' '}
                          {player?.partner?.lastName}
                        </Paragraph>
                        <Paragraph className="id" smaller>
                          #
                          {player?.partnerId}
                        </Paragraph>
                      </div>
                      <Paragraph smaller>
                        <Trans ns="events" i18nKey="playerGameLevel">Game Level</Trans>
                        :
                        {' '}
                        {partnerLevel}
                      </Paragraph>
                      <div>
                        <Paragraph small className="max-content wildcard">
                          <div className="popup">
                            <Paragraph>
                              <Trans ns="events" i18nKey="wildcard">Wildcard</Trans>
                            </Paragraph>
                          </div>

                          <img src={LoadImage('wildcard.svg')} alt="" />
                          <Trans ns="events" i18nKey="wildcard">Wildcard</Trans>
                        </Paragraph>

                        {isPending
                          ? (
                            <Paragraph small className="max-content pending">
                              <img src={LoadImage('pending-clock.svg')} alt="" />
                              <Trans ns="events" i18nKey="pending">Pending</Trans>
                            </Paragraph>
                          ) : ''}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="teamInfo">
              <div className="teamAvgLevel">
                <Paragraph smaller>
                  <Trans ns="events" i18nKey="avgLevel">Avg. Level</Trans>
                </Paragraph>
                <Paragraph bold normal>{teamAverageLevel}</Paragraph>
              </div>

              <div className="teamAvgPoints">
                <Paragraph smaller>
                  <Trans ns="events" i18nKey="avgPoints">Avg. Points</Trans>
                </Paragraph>
                <Paragraph bold normal>{teamAveragePoints}</Paragraph>
              </div>

              <div className="teamAvgRank">
                <Paragraph smaller>
                  <Trans ns="events" i18nKey="avgRank">Avg. Rank</Trans>
                </Paragraph>
                <Paragraph bold normal>{teamAverageRank}</Paragraph>
              </div>

              <Paragraph small bold className="max-content">
                (
                <Trans ns="events" i18nKey="wildcard">Wild Card</Trans>
                )
              </Paragraph>
            </div>
          </div>
        )
          : (
            <div className={reserved ? 'reserved' : 'reserved available'}>
              <Paragraph small bold>{id + 1}</Paragraph>
              <img src={reserved ? reservedSlotIcon : availableSlotIcon} alt="reservedSlot" />
              <Paragraph normal bold className="reserved-paragraph">
                {' '}
                {reserved ? (
                  <>
                    <Trans ns="events" i18nKey="reservedSlot">Reserved Slot /</Trans>
                    <img src={LoadImage('wildcard.svg')} alt="" />
                    {' '}
                    <Trans ns="events" i18nKey="wildcard">Wildcard</Trans>
                  </>
                ) : (<Trans ns="events" i18nKey="availableSlot">Available Slot</Trans>)}
              </Paragraph>
            </div>
          )
      ) : gameType === 'singles' ? (
        <Link to={`/accounts/${player?.user?.id}`}>
          <div className="listPlayer">
            <div className="playerInfo">
              <div className="nr">
                <Paragraph small bold className="id">{id + 1}</Paragraph>
              </div>
              {player?.user?.profilePicture ? (
                <img
                  className="avatarImg"
                  src={LoadImage(`accounts/${player?.user?.userId}/${player?.user?.profilePicture}`, true)}
                  alt={player?.user?.firstName
                    && player?.user?.lastName
                    && player?.user?.firstName.charAt(0) + player?.user?.lastName.charAt(0)}
                />
              ) : (
                <div className="avatar-placeholder">
                  <Paragraph medium bold className="text-uppercase">
                    {player?.user?.firstName
                      && player?.user?.lastName
                      && player?.user?.firstName.charAt(0) + player?.user?.lastName.charAt(0)}
                  </Paragraph>
                </div>
              )}

              <div className="nameWrapp">
                <div>
                  <Paragraph bold normal>
                    {isFriend(player?.user?.userId) && (
                      <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                    )}
                    {player?.user?.firstName}
                    {' '}
                    {player?.user?.lastName}
                  </Paragraph>
                  <Paragraph className="id" smaller>
                    #
                    {player?.userId}
                  </Paragraph>
                </div>
                <div className="gameLevel">
                  <Paragraph>
                    <Trans ns="events" i18nKey="playerGameLevel">Game Level</Trans>
                    :
                    {userLevel}
                  </Paragraph>
                </div>
                {isPending
                  ? (
                    <Paragraph small className="max-content pending">
                      <Trans ns="events" i18nKey="pending">Pending</Trans>
                      <img src={LoadImage('pending-clock.svg')} alt="" />
                    </Paragraph>
                  ) : ''}
              </div>
            </div>

            <div className="rank">
              <Paragraph bold normal><Trans ns="events" i18nKey="playerRank">Rank</Trans></Paragraph>
              <Paragraph bold normal>
                {Number.isNaN(player?.user?.rank) || player?.user?.rank === 0 || player?.user?.rank === null
                  ? '-'
                  : player?.user?.rank}
              </Paragraph>
            </div>
          </div>
        </Link>
      ) : (
        <div className="team">
          <div className="group">
            <div className="nr">
              <Paragraph bold>{id + 1}</Paragraph>
            </div>
            <div className="players">
              <Link to={`/accounts/${player?.user?.id}`}>
                <div className="player">
                  <div className="avatar">
                    {player?.user?.profilePicture ? (
                      <img
                        className="avatarImg"
                        src={LoadImage(`accounts/${player?.user?.userId}/${player?.user?.profilePicture}`, true)}
                        alt={player?.user?.firstName
                          && player?.user?.lastName
                          && player?.user?.firstName.charAt(0) + player?.user?.lastName.charAt(0)}
                      />
                    ) : (
                      <div className="avatar-placeholder">
                        <Paragraph medium bold className="text-uppercase">
                          {player?.user?.firstName
                            && player?.user?.lastName
                            && player?.user?.firstName.charAt(0) + player?.user?.lastName.charAt(0)}
                        </Paragraph>
                      </div>
                    )}
                  </div>
                  <div className="playerInfo">
                    <div className="playerName">
                      <Paragraph bold normal>
                        {isFriend(player?.user?.userId) && (
                          <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                        )}
                        {player?.user?.firstName}
                        {' '}
                        {player?.user?.lastName}
                      </Paragraph>
                      <Paragraph className="id" smaller>
                        #
                        {player?.user?.userId}
                      </Paragraph>
                    </div>
                    <Paragraph smaller>
                      <Trans ns="events" i18nKey="playerGameLevel">Game Level</Trans>
                      :
                      {' '}
                      {userLevel}
                    </Paragraph>
                    {isPending
                      ? (
                        <Paragraph small className="max-content pending">
                          <Trans ns="events" i18nKey="pending">Pending</Trans>
                          <img src={LoadImage('pending-clock.svg')} alt="" />
                        </Paragraph>
                      ) : ''}
                  </div>
                </div>
              </Link>
              <Link to={`/accounts/${player?.partner?.id}`}>
                <div className="player">
                  <div className="avatar">
                    {player?.partner?.profilePicture ? (
                      <img
                        className="avatarImg"
                        src={LoadImage(`accounts/${player?.partnerId}/${player?.partner?.profilePicture}`, true)}
                        alt={player?.partner?.firstName
                          && player?.partner?.lastName
                          && player?.partner?.firstName.charAt(0) + player?.partner?.lastName.charAt(0)}
                      />
                    ) : (
                      <div className="avatar-placeholder">
                        <Paragraph medium bold className="text-uppercase">
                          {player?.partner?.firstName
                            && player?.partner?.lastName
                            && player?.partner?.firstName.charAt(0) + player?.partner?.lastName.charAt(0)}
                        </Paragraph>
                      </div>
                    )}
                  </div>
                  <div className="playerInfo">
                    <div className="playerName">
                      <Paragraph bold normal>
                        {isFriend(player?.partner?.userId) && (
                          <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
                        )}
                        {player?.partner?.firstName}
                        {' '}
                        {player?.partner?.lastName}
                      </Paragraph>
                      <Paragraph className="id" smaller>
                        #
                        {player?.partnerId}
                      </Paragraph>
                    </div>
                    <Paragraph smaller>
                      <Trans ns="events" i18nKey="playerGameLevel">Game Level</Trans>
                      :
                      {' '}
                      {partnerLevel}
                    </Paragraph>
                    {isPending
                      ? (
                        <Paragraph small className="max-content pending">
                          <Trans ns="events" i18nKey="pending">Pending</Trans>
                          <img src={LoadImage('pending-clock.svg')} alt="" />
                        </Paragraph>
                      ) : ''}
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="teamInfo">
            <div className="teamAvgLevel">
              <Paragraph smaller>
                <Trans ns="events" i18nKey="avgLevel">Avg Level</Trans>
              </Paragraph>
              <Paragraph bold normal>{teamAverageLevel}</Paragraph>
            </div>

            <div className="teamAvgPoints">
              <Paragraph smaller>
                <Trans ns="events" i18nKey="avgPoints">Avg Points</Trans>
              </Paragraph>
              <Paragraph bold normal>{teamAveragePoints}</Paragraph>
            </div>

            <div className="teamAvgRank">
              <Paragraph smaller>
                <Trans ns="events" i18nKey="avgRank">Avg Rank</Trans>
              </Paragraph>
              <Paragraph bold normal>{teamAverageRank}</Paragraph>
            </div>
          </div>
        </div>
      )}
    </ListedPlayer>
  );
};

export default PlayerModal;
