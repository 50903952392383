export default {
  RAISE_HAND_SUCCESS: 'Ai ridicat mâna pentru un meci amical.',
  LOWER_HAND_SUCCESS: 'Ai coborât mâna.',
  createMatch: 'Meci Nou',
  createNewMatchInvite: 'Invitație la Meci',
  addPlayedMatch: 'Adaugă Meci Jucat',
  raiseHand: {
    text: 'Ridică mâna',
    title: 'Ridică mâna pentru un meci amical!',
    description: 'Arată celorlalți jucători că ești dornic să joci imediat.',
    gameTypeLabel: 'Joacă un meci amical de',
    within: 'în perioada',
    next3Days: 'Următoarele 3 Zile',
    next7Days: 'Următoarele 7 Zile',
    thisWeekend: 'În acest Weekend',
    withPlayers: 'cu Jucători',
    aboveMyLevel: 'Nivelul meu +/-1',
    myLevel: 'Doar nivelul meu',
    lower: 'Coboară mâna',
    daysLeft: '{{time}} zile',
    dayLeft: '{{time}} zi',
    singlesAndDoubles: 'Simplu & Dublu',
    sameLevelDescription: 'Același nivel',
    aboveLevelDescription: 'Nivel +/-1',
    tooltip: 'Dorește să joace un meci amical cât mai curând',
  },
  startMatch: 'Start',
  startMatchButton: 'Start Meci',
  activeMatches: 'Meciuri <1>({{ matches }})</1>',
  requests: 'Invitații <1>({{ requests }})</1>',
  scores: 'Scoruri',
  friendlyMatches: 'Meciuri Amicale',
  friendlyMatches_tennis: 'Meciuri Amicale de Tenis',
  friendlyMatches_padel: 'Meciuri Amicale de Padel',
  inviteFriends: 'Invită-ți prietenii sau alți jucători la un meci nou sau adaugă în Sportya un meci deja jucat',
  sportyaPartnerClubs: 'Cluburi Partenere Sportya',
  partnerClubsPopup: 'Cluburile Partenere Sportya sunt locații partenere în care poți participa la evenimente Sportya sau meciuri amicale.',
  wantToPlay: 'Vor să joace un meci',
  wantToPlayPopUp: 'Jucători care au ridicat mâna pentru un meci amical imediat.',
  viewMore: 'Vezi mai mult',
  nearby: 'În apropiere',
  distanceAway: '{{ kmDistance }} km',
  invite: 'Invită',
  rematch: 'Joacă din nou',
  invitePlayerInArea: 'Invită un jucător din apropiere',
  invitePlayerInAreaPopUp: 'Jucători pe o rază de proximitate de 30 km în jurul locației setate în profilul tău Sportya.',
  otherTopPicks: 'Alte recomandări',
  otherTopPicksPopUp: 'Jucători pe care i-ai întâlnit anterior în meciuri amicale sau de competiție și/sau prietenii tăi eligibili din Sportya.',
  searchSpecificPlayer: 'Vrei să cauți un jucător anume?',
  findAPlayer: 'Caută Jucător',
  singlesMatch: 'Meci de Simplu',
  doublesMatch: 'Meci de Dublu',
  singlesFriendlyMatch: 'Meci Amical de Simplu',
  doublesFriendlyMatch: 'Meci Amical de Dublu',
  scoresData: {
    opponent: 'Adversar',
    opponents: 'Adversari',
  },
  ranked: 'Competitiv',
  unranked: 'Antrenament',
  matchType: 'Tipul Meciului',
  matchFormat: 'Formatul Meciului',
  createdBy: 'Creat de',
  players: 'Jucători',
  yourTeam: 'Echipa Ta',
  opponentTeam: 'Echipa Adversă',
  viewInActivity: 'Vezi în Activitate',
  reportMatch: 'Raportează Meciul',
  matchId: 'ID Meci: {{ matchId }}',
  singleSet: '1 Singur Set',
  '2setsWithSuperTieBreak': '2 Seturi + Super Tiebreak',
  '2setsOutOf3': '2 Seturi din 3',
  later: 'Neselectat',
  playFriendlyMatch: 'Joacă un Meci Amical',
  playFriendlyMatchSubtitle: 'Arată-le celorlalți jucători că vrei să joci un meci amical cât mai curând posibil!',
  noMatch: 'Nedisputat',
  editMatch: 'Editare Meci',
  cancelMatch: 'Anulare Meci',
  confirmMatch: 'Confirmă Meciul',
  withdraw: 'Retrage-te',
  goToFriendlyMatches: 'Mergi la Meciuri Amicale',
  requestsArrow: 'Invitații',
  matches: 'Meciuri',
  latestRequest: 'Cea mai recentă invitație',
  nextMatch: 'Meciul următor',
  yourMatch: 'Meciul Tău',
  noRequestsOrMatches: 'Nu ai invitații sau meciuri amicale noi?',
  noRequestsOrMatchesDescription: 'Invită-ți prietenii sau alți jucători la un meci nou sau adaugă în Sportya un meci deja jucat',
  modalAcceptMatchTitle: 'Acceptă invitația la meci',
  modalDeclineMatchTitle: 'Refuză invitația la meci',
  modalCancelMatchTitle: 'Anulează Meciul',
  modalNoMatchFromRequestsTitle: 'Meci Nedisputat',
  modalNoMatchFromMatchesTitle: 'Meci Nedisputat',
  modalAcceptMatchSubtitle: 'Accepți invitația la meci?',
  modalDeclineMatchSubtitle: 'Sigur dorești să refuzi această invitație la meci?',
  modalCancelMatchSubtitle: 'Sigur dorești să anulezi acest meci?',
  modalNoMatchFromRequestsSubtitle: 'Sigur dorești să infirmi participarea la acest meci și să îl marchezi ca Nedisputat?',
  modalNoMatchFromMatchesSubtitle: 'Sigur dorești să infirmi participarea la acest meci și să îl marchezi ca Nedisputat?',
  modalWithdrawSubtitle: 'Sigur dorești să te retragi din acest meci?',
  accept: 'Acceptă',
  acceptMatch: 'Acceptă Meciul',
  decline: 'Refuză',
  cancel: 'Anulează',
  goBack: 'Înapoi',
  mark: 'Meci Nedisputat',
  addScore: 'Adaugă Scorul',
  confirmScore: 'Confirmă Scorul',
  changeScore: 'Schimbă Scorul',
  abandoned: 'Abandon',
  addScoreInfo: 'Scorul tău va fi în așteptare pentru confirmare din partea celorlalți participanți pentru maximum 48 de ore, după care va fi validat automat, iar punctele vor fi generate în cazul unui Meci Competitiv.',
  changeScoreInfo: 'Dacă scorul curent este incorect, vă rugăm să completați și să confirmați meciul cu scorul corect. Noile scoruri vor fi în așteptare pentru confirmare din partea celorlalți participanți pentru maximum 48 de ore, înainte de a fi validate.',
  confirmScoreInfo: 'Dacă scorul curent este incorect, vă rugăm să completați și să confirmați meciul cu scorul corect. Noile scoruri vor fi în așteptare pentru confirmare din partea celorlalți participanți pentru maximum 48 de ore, înainte de a fi validate.',
  confirmMatchScoreInfo: 'Dacă scorul curent este incorect, vă rugăm să completați și să confirmați meciul cu scorul corect. Noile scoruri vor fi în așteptare pentru confirmare din partea celorlalți participanți pentru maximum 48 de ore, înainte de a fi validate.',
  played: 'Jucat',
  seeLocation: 'Vezi pe Hartă',
  noActiveMatches: 'Nu ai meciuri amicale active',
  noMatchesSubtitle: 'Nu ai niciun meci amical programat în acest moment. Invită-ți prietenii sau alți jucători și jucați un meci împreună!',
  noOpenRequests: 'Nu ai primit invitații',
  noRequestsSubtitle: 'Nu ai primit încă invitații la meciuri amicale. Invită-ți prietenii sau alți jucători și jucați un meci împreună!',
  noScores: 'Nu ai scoruri validate',
  noScoresSubtitle: 'Nu ai jucat încă niciun meci amical cu scoruri validate. Asigură-te că au fost înregistrate și validate scorurile pentru toate meciurile jucate până acum sau invită-ți prietenii sau alți jucători și jucați un meci împreună!',
  reportReasons: {
    reason: 'Motivul',
    selectTheReason: 'Selecteaza Motivul',
    incorrectScore: 'Scor Incorect',
    undisputedMatch: 'Meci Nedisputat',
    other: 'Alt motiv',
    reportTextAreaInfo: 'Te rugăm să ne oferi mai multe informații (opțional)',
  },
  reportedMatch: 'Ai raportat acest meci',
  reportedMatchDescription: 'Vom analiza raportul tău și te vom contacta în cazul în care este necesară orice informație suplimentară.',
  dateAndTime: 'Data & Ora',
  clubOrAddress: 'Club sau Adresă',
  minute: 'Minute',
  hour: 'Ora',
  format: 'Format',
  decideLater: 'Decide mai târziu',
  sendInvite: 'Trimite Invitația',
  captain: 'Căpitan',
  partner: 'Partener',
  pending: 'În așteptare',
  accepted: 'Acceptat',
  declined: 'Refuzat',
  score: 'Scor',
  tie: 'Tie',
  matchDetails: 'Detalii Meci',
  selectMatchType: 'Selectează Tipul Meciului',
  proposedMatchFormat: 'Formatul de Meci propus',
  countrySelect: 'Se afișează locații din {{country}} <2>Schimbă Țara</2>',
  playerMinMaxLevelError: 'Nivelul adversarului nu este compatibil cu cerința de nivel pentru un Meci Competitiv. Te rugăm să selectezi un jucător cu un nivel de joc în intervalul {{min}}-{{max}}, sau să schimbi tipul meciului în Meci de Antrenament pentru a continua să joci cu jucătorul deja selectat.',
  teamMinMaxLevelError: 'Nivelul mediu al echipei nu este compatibil cu cerința de nivel pentru un Meci Competitiv. Te rugăm să selectezi o echipă cu un nivel mediu de joc în intervalul {{min}}-{{max}}, sau să schimbi tipul meciului în Meci de Antrenament pentru a continua să joci cu echipa deja selectată.',
  updateMatch: 'Actualizează Meciul',
  pendingScorePopup: 'Scorul așteaptă confirmare - Te rugăm să confirmi scorul sau să îl corectezi în maximum {{ dueTime }}',
  invitationDeclined: 'Invitație refuzată - Te rugăm să revizuiești detaliile meciului și să iei măsurile necesare',
  playerWithdrawn: 'Jucător retras - Te rugăm să revizuiești detaliile meciului și să iei măsurile necesare',
  noMatchReported: 'Meci raportat ca Nedisputat - Te rugăm să revizuiești detaliile meciului și să iei măsurile necesare',
  inactiveScoreUpdate: 'Scorul nu poate fi adăugat/confirmat/schimbat momentan din cauza retragerilor de jucători/jucătorilor neconfirmați',
  decideGameFormatLater: 'Jucătorii pot decide formatul meciului mai târziu sau îl pot completa după disputarea meciului.',
  hasTimeLimitError: 'Ne pare rău, dar nu poți adăuga un meci jucat în modul Competitiv mai vechi de 5 zile. Sistemul permite doar adăugarea meciurilor jucate în modul Competitiv în termen de 5 zile de la data meciului. Dacă totuși dorești să adaugi meciul în istoricul tău de meciuri amicale, poți adăuga acest meci ca Meci de Antrenament, fără puncte.',
  confirmMatchScore: 'Confirmă Meciul & Scorul',
  rankedFriendlyMatches: 'Meciuri Amicale Competitive',
  generalRank: 'Clasament General',
  rankedMatch: 'Meci Competitiv',
  unrankedMatch: 'Meci de Antrenament',
  totalMatches: 'Total Meciuri',
  allActivity: 'Toată Activitatea',
  minutesUntil: 'timp limită {{time}} min',
  hoursUntil: 'timp limită {{time}} h',
  daysUntil: 'timp limită {{time}} zile',
  monthsUntil: 'timp limită {{time}} luni',
  yearsUntil: 'timp limită {{time}} ani',
  saveMatch: 'Salvează Meciul',
  gameType: 'Tip de joc',
  generatesPoints: 'Generează puncte în clasament. Format de joc: 2 Seturi din 3.',
  doesntGeneratePoints: 'Nu generează puncte în clasament. Sunt disponibile diverse formate de joc. ',
  matchInvitation: 'Meci Nou',
  playedMatch: 'Meci Jucat',
  editParticipantWarning: 'Te rugăm să iei în considerare că schimbarea oricărui jucător din meci va reseta orice scor existent, prin urmare scorul va trebui adăugat din nou și confirmat pentru validarea finală.',
  playedMatchForm: {
    title: 'Ai adăugat cu succes meciul tău deja jucat cu:',
    desc: 'Meciul tău așteaptă confirmarea celorlalți participanți pentru a valida scorul final. Pentru a accelera procesul de validare a scorului final, îți sugerăm să contactezi personal ceilalți participanți și să îi încurajezi să confirme meciul și scorul tău.',
    button: 'Am înțeles',
  },
  matchInviteForm: {
    title: 'Ai trimis cu succes invitația ta la meci către:',
    desc: 'Pentru a te asigura că jucătorii invitați vor răspunde în timp util, îți sugerăm să îi contactezi personal și să îi încurajezi să accepte invitația.',
    button: 'Am înțeles',
  },
  invitationPendingConfirmation: 'Invitațiile la meci vor fi în așteptarea acceptării de către jucătorii invitați.',
  timeRequiredForPlayedMatch: 'Meciurile jucate în modul Competitiv pot fi adăugate în maximum 5 zile de la data meciului.',
  modalWithdrawTitle: 'Retragere din Meci',
  modalWithdraw: 'Sigur dorești să te retragi din acest meci?',
  guide: {
    quickGuide: 'Ghid Rapid',
    title: 'Ghid Rapid pentru Meciurile Amicale',
    intro: 'Experimentează bucuria de a juca tenis în Meciurile Amicale Sportya.<br>Joacă cu prietenii sau alți jucători în meciuri ocazionale de Antrenament sau în Meciuri Competitive, de Simplu sau de Dublu, și câștigă puncte pentru a urca în Clasament.',
    ranked: '<strong>Meciurile Competitive generează puncte în Clasament pentru câștigători</strong> atunci când scorurile finale sunt validate:<br><strong>+4 puncte</strong> per victorie împotriva unui nivel egal<br><strong>+2 puncte</strong> per victorie împotriva unui nivel inferior<br><strong>+6 puncte</strong> per victorie împotriva unui nivel superior',
    unranked: '<strong>Meciurile de Antrenament</strong> sunt meciuri ocazionale pentru distracție sau antrenament.',
    scores: '<strong>Scorurile</strong> trebuie confirmate de adversari (sau de cel puțin un adversar pentru Dublu) în maximum 48 de ore de la momentul adăugării pentru a fi validate. Orice modificare a scorului repornește cronometrul.',
    playedMatches: '<strong>Meciurile Competitive</strong> deja jucate pot fi adăugate în maximum 5 zile de la data meciului.',
    withdrawals: 'Orice <strong>Retragere</strong> sau raportare de  <strong>Meci Nedisputat</strong> din partea unui participant la meci necesită atenția Organizatorului meciului pentru a examina detaliile meciului și pentru a lua măsurile necesare.<br><strong>1 x Raportare de Meci Nedisputat</strong> într-un meci de Simplu anulează meciul pentru ambii jucători<br><strong>2 x Raportări de Meci Nedisputat</strong> de la echipa adversă într-un meci de Dublu anulează meciul pentru ambele echipe',
    contact: '<strong>Luați legătura personal cu ceilalți participanți</strong> la meci pentru a-i <strong>încuraja să accepte meciul</strong> sau pentru a-i informa în avans despre intenția de a face modificări sau de a anula meciul. Acest lucru va ajuta la evitarea oricăror confuzii sau inconveniente pentru alți jucători.',
  },
  report: 'Raportează',
  gotIt: 'Am înțeles',
  withdrawn: 'Retragere',
  canceled: 'Anulat',
  scoresChangeInfo: 'Poți modifica scorul pentru acest meci doar folosind opțiunea Schimbă Scorul după ce salvezi orice actualizări și închizi acest formular.',
  rankedTooltip: {
    title: 'Câștigătorii vor primi:',
    1: '+4 puncte per victorie împotriva unui nivel egal',
    2: '+2 puncte per victorie împotriva unui nivel inferior',
    3: '+6 puncte per victorie împotriva unui nivel superior',
  },
  rankBeforeMatch: 'Clasare înainte de meci:',
  inviteToMatch: 'Invită la Meci',
  cancelPopup: {
    title: 'Sigur vrei să părăsești această pagină?',
    stay: 'Rămân pe pagină',
    quit: 'Părăsesc pagina',
  },
  invitationMovedToMatches: 'Invitația a fost acceptată cu succes! Meciul {{matchId}} a fost mutat în ',
  matchMovedToScores: 'Scorul a fost validat cu succes! Meciul {{matchId}} a fost mutat în ',
  playedMatchMovedToScores: 'Scorul a fost validat cu succes! Meciul {{matchId}} a fost mutat în ',
  playedMatchMovedToMatches: 'Meciul a fost confirmat cu succes! Meciul {{matchId}} a fost mutat în ',
  scoreValidationInvalidWinner: 'Vă rugăm să verificați cu atenție scorul și să completați scorul corect și complet, astfel încât pe baza acestuia să se poată desemna un câștigător/o echipă câștigătoare.',
  landingPage: {
    header: {
      title: 'Joacă Meciul Perfect',
      description: `Participă la meciuri amicale neclasate sau cu miză în clasamente, atât la simplu, cât și la dublu, în tenis, padel și alte sporturi. Descoperă emoția jocului cu prietenii sau alți jucători în Sportya și explorează diverse stiluri de joc.`,
    },
    section1: {
      title: 'Revitalizează-ți Spiritul Competitiv',
      description: `Activează pasiunea pentru tenis, padel și alte sporturi cu racheta conectându-te cu jucători amatori din zona ta, prin Sportya. Fie că este vorba de provocarea vechilor prieteni sau de căutarea unor rivali noi, trăiește emoția meciurilor competitive și de antrenament. Urmărește-ți progresul și vezi cine iese învingător la sfârșitul fiecărui sezon.`,
    },
    section2: {
      title: 'Flex Play - Personalizează-ți Jocul, Așa Cum Îți Dorești',
      description: `Bucură-te de flexibilitatea supremă cu Meciuri Amicale, disponibile oricând, oriunde. Ia inițiativa folosind Sportya Chat pentru a te conecta cu jucători, a stabili ora și locația și a te pregăti pentru ziua meciului. Ai nevoie de sugestii pentru locuri de joacă? Verifică cluburile partenere și rezervă online terenurile și facilitățile sportive ale acestora.`,
    },
    section3: {
      title: 'Îmbunătățește-ți Jocul, Acceptă Provocări Noi ',
      description: `Fiecare jucător reprezintă o provocare unică, oferindu-ți ocazia de a evolua și de a deveni un atlet mai bun. Îmbrățișează stiluri de joc diverse și profită de oportunitățile de a-ți îmbunătăți jocul cu fiecare meci.`,
    },
    section4: {
      title: 'Ridică Mâna Pentru Un Meci Amical',
      description: `Folosește opțiunea „Ridică mâna” în Sportya și anunță alți jucători din regiunea ta că ești în căutarea unui meci în viitorul apropiat.`,
    },
    section5: {
      title: 'Meci Găsit? Rezervă un Teren / Facilități Sportive Prin Sportya ',
      description: `Descoperă terenuri și facilități sportive disponibile în zona ta și adună-ți prietenii pentru un meci de neuitat. Sportya va accelera călătoria ta de la găsirea meciului la rezervarea terenului / facilității sportive în doar câteva clicuri. Spune adio apelurilor plictisitoare și timpului pierdut.`,
    },
    actions: {
      title: 'Joacă cel mai bun meci!',
      button: 'Înscrie-te acum',
    }
  },
};
