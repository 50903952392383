export default {
  seoTitle: '{{club}} - Tenisový klub - {{location}} | Sportya',
  see_all: 'Zobrazit vše',
  find_court: 'Najděte si kurt a hrajte',
  view_details: 'Zobrazit podrobnosti',
  location: 'Místo',
  about: 'O programu',
  events: 'Události',
  amenities: 'Komfortní vybavenost',
  openNow: 'Otevřít',
  pickLocation: 'Vybrat místo',
  date: 'Datum',
  today: 'Dnes',
  hour: 'Hodina',
  anytime: 'Kdykoli',
  filterEvents: 'Stav událostí',
  search: 'Hledat',
  seeOnMap: 'Zobrazit na mapě',

  title: 'Pořádat akce a pronajmout hřiště pro místní hráče',
  modalTitle: 'Upravit profil klubu',
  seoTitle1: 'Pro kluby | Sportya',
  info1: {
    title: 'Spravovat kurty',
    desc: 'Mějte kurty plně pod kontrolou na jediný pohled.',
  },
  info2: {
    title: 'Vyhledat tenisové profesionály',
    desc: 'Zarezervujte si lekce s vysoce kvalifikovanými tenisovými profesionály na kurtu poblíž vašeho bydliště.',
  },
  info3: {
    title: 'Pořádat vlastní události',
    desc: 'Zaměřte se na péči o své hráče a zařízení.',
  },
  form: {
    title: 'Chcete se dozvědět víc?',
    desc: 'Poskytněte nám nějaké informace a my se vám ozveme.',
    firstName: 'Jméno',
    lastName: 'Příjmení',
    jobTitle: 'Pracovní pozice',
    email: 'E-mail',
    phone: 'Telefon',
    city: 'Město',
    clubName: 'Klub / Název místa',
    sport: 'Sport',
    numberOfCourts: 'Počet kurtů',
    button: 'Obraťte se na nás',
    ToS: 'Tím, že nás kontaktujete, potvrzujete, že souhlasíte se zpracováním svých osobních údajů, jak je popsáno v oddíle <1>Zásady ochrany soukromí<1>.',
    validation: {
      required: 'Požadované pole',
      invalidEmail: 'Neplatný e-mail',
      success: 'Vaše žádost byla odeslána. Děkujeme vám!',
      error: 'Vyskytl se nějaký problém',
    },
  },
  call: {
    title: 'Zavolejte nám',
    phoneNr: '+4 031 805 4042',
  },
  contact: {
    title: 'Spojte se s námi',
    email: 'join@sportya.net',
  },
  CLUB_LEAD_CREATED: 'Vaše žádost byla úspěšně odeslána!',
  CLUB_LEAD_ALREADY_EXIST: 'Klub již přiřadil vaši e-mailovou adresu.',
  CLUB_LEAD_CREATE_ERROR: 'Vaši žádost nelze odeslat!',
  sportyaOSCTA: 'Máte Klubový Nebo Organizátorský Účet?',
  sportyaOSButton: 'Přejděte na SportyaOS',
  tennisClubsFound: '<0>Nalezen {{ totalResults }} klub</0>',
  padelClubsFound: '<0>Nalezen {{ totalResults }} klub</0>',
  tennisClubsFound_plural: '<0>Bylo nalezeno {{ totalResults }} klubů</0>',
  padelClubsFound_plural: '<0>Bylo nalezeno {{ totalResults }} klubů</0>',
  tennisClubsFound_with_location: '<0>{{ totalResults }} klub</0> byl nalezen pro <2>{{ location }}</2>',
  padelClubsFound_with_location: '<0>{{ totalResults }} klub</0> byl nalezen pro <2>{{ location }}</2>',
  tennisClubsFound_plural_with_location: 'Bylo nalezeno <0>{{ totalResults }} klubů</0> pro <2>{{ location }}</2>',
  padelClubsFound_plural_with_location: 'Bylo nalezeno <0>{{ totalResults }} klubů</0> pro <2>{{ location }}</2>',
  startingPriceFrom: "Od {{ startingPrice }} {{ currency }}",
  indoorCourts: "Indoor Courts",
  noClubsFound: "Pro toto hledání nebyly nalezeny žádné výsledky",
  filters: 'Filtry',
  onlyAvailableCourts: "Pouze volné pozemky",
  onlyAvailableSlots: "Pouze volné pozemky",
  showMap: "Zobrazit mapu",
  selfService: "Self-Service",
  clearAll: "Vymazat vše",
  apply: "Použít",
  surface: "Povrch",
  surfaceType: {
    grass: 'Trávník',
    clay: 'Antuka',
    artificial: 'Umělý',
    hard: 'Pevný',
  },
  courtType: "Typ pozemku",
  courtTypes: {
    outdoor: "Vnější",
    indoor: "Uvnitř",
  },
  courtSize: "Dimenze",
  courtSizes: {
    singles: 'Dvouhry',
    doubles: 'Čtyřhry',
  },
  seeOtherAvailabilities: "Podívejte se na další možnosti",
  currentlyUnavailable: "Momentálně nedostupné",
  comingSoon: "Již brzy",
  court: "Kurt",
  withLighting: "s osvětlením",
  checkout: "Potvrzení",
  addPlayersToFriendlyMatches: "Přidejte hráče pro přátelský zápas",
  gameType: "Typ hry",
  bookingId: "ID rezervace: <1>{{ bookingId }}",
  start: "Datum",
  startTime: "Čas začátku",
  duration: "Trvání",
  orderId: "Èíslo objednávky",
  totalToBePaid: "Celkem k úhradě (včetně DPH)",
  totalWithVat: "Celkem (včetně DPH)",
  billingData: "Fakturační údaje",
  updateBillingData: "Aktualizujte fakturační údaje",
  billingDataNotSet: "Fakturační údaje nejsou nastaveny!",
  clickToUpdateBillingData: "Kliknutím upravíte fakturační údaje vaší společnosti.",
  selectBillingInformation: "Vyberte fakturační údaje",
  individual: "Fyzická osoba",
  company: "Společnost",
  durationMin: '{{ duration }} minut',
  payWithCard: 'Platit kartou',
  agreeBookingTos: 'Pokračováním v rezervaci potvrzuji, že souhlasím s \n <1>Smluvní vztahy a podmínky</1> a <4>Zásady vracení</4>.',
  friendlyMatch: 'Přátelský Zápas',
  changePlayer: 'Změnit hráče',
  additionalInfo: 'Dodatečné informace',
  bookACourt_tennis: 'Rezervní Země',
  bookACourt_padel: 'Rezervní Země',
  selfServiceCourts: 'Self-Service pozemky',
  cancellationWithRefund: 'Zrušení s vrácením peněz - minimálně <1>{{ hours }} hodin předem</1>',
  available: 'Dostupný',
  notAvailable: 'Není k dispozici',
  yourBooking: 'Moje rezervace',
  selfServiceCourtsPopupDescription: 'Na konci každého zápasu musí hráči připravit hřiště na další zápas.',
  cancellationWithRefundPopupDescription: 'Rezervace v tomto klubu lze zrušit alespoň {{ hours }} hodin před začátkem.',
  failedToFetchData: 'Klubová data se nepodařilo načíst.',
  myBookingTooltip: "Moje rezervace <br /> {{ startHour }} - {{ endHour }} ({{ duration}} minut)",
  totalActiveCourts: '{{ totalCourts }} Kurty ({{ courtSurfaces }})',
  noAvailableCourts: 'Online rezervace soudu bude brzy k dispozici.',
  couldNotFetchCourtsBookings: 'Rezervace se nepodařilo načíst. Zkus to znovu!',
  upcomingNoBookings: 'Nejsou žádné budoucí rezervace',
  historyNoBookings: 'Nejsou žádné rezervace',
  myBookings: 'Moje rezervace',
  upcoming: 'Budoucí',
  history: 'Historický',
  confirmedStatus: 'Dokončeno',
  canceledStatus: 'Nehotový',
  pendingStatus: 'Čekající',
  cancelBooking: 'Zrušit rezervaci',
  refunded: 'Náhrada',
  lateCancellation: 'Pozdní zrušení',
  lateCancellationText: 'Opravdu chcete zrušit tuto rezervaci? \n\n V souladu se storno podmínkami klubu bude rezervace zrušena <1>BEZ VRÁCENÍ PENĚZ</1>',
  earlyCancellation: 'Zrušení rezervace',
  earlyCancellationText: 'Opravdu chcete zrušit tuto rezervaci? \n\n V souladu se storno podmínkami klubu bude rezervace zrušena <1>S VRÁCENÍM PENĚZ</1>',
  successPaymentStatus: 'Úspěch',
  pendingPaymentStatus: 'Čekající',
  refundedPaymentStatus: 'Náhrada',
  minBookingTime: 'Minimum Booking Time',
  sportType: 'Sport Type',
};
