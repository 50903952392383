export default {
  seoTitle: 'Găsește un Eveniment | Sportya',
  leaderboardSeoTitle: 'Clasamente | Sportya',
  leaderboards: 'Clasamente',
  results: 'Rezultate',
  newEventsNearYou: 'Evenimente Recomandate',
  seeAll: 'Vezi toate',
  seeEvent: 'Vezi Eveniment',
  seeMyEvents: 'Evenimentele Mele',
  goToEvent: 'Vezi Evenimentul ⟶',
  goToEvent2: 'Vezi Evenimentul',
  newEvent: 'Eveniment Nou',
  newEvents: 'Evenimente Noi',
  undisputed: 'Nedisputat',
  recommendedEvents: 'Evenimente Recomandate',
  announcedWithdrawal: 'Retragere anunțată',
  penalty: 'Penalizare',
  pickLocation: 'Locație',
  pickCategory: 'Categorie',
  pickYear: 'An',
  pickMonth: 'Lună',
  all: 'Toate',
  filter_all: 'Filtre',
  clearAll: 'Resetează filtrele',
  teamGameLevel: 'Nivel echipă de joc',
  allActive: 'Evenimente Active',
  availableEntries: 'Locuri Disponibile',
  wildcard: 'Wildcard',
  prizes: 'Premii',
  filters: 'Filtre',
  gameType: 'Tip de Joc: {{ gameType }}',
  gameTypeText: 'Tip de Joc',
  playerGameLevel: 'Nivel Joc',
  playerRank: 'Loc',
  eventLevel: 'Nivelul Competiției',
  eventStatus: 'Status Eveniment',
  age: 'Vârstă',
  playMode: 'Mod de Joc',
  singles: 'Simplu',
  doubles: 'Dublu',
  Singles: 'Simplu',
  Doubles: 'Dublu',
  joinNowLink: '<0>Participă Acum</0>',
  joinNow: 'Participă Acum',
  viewLocation: 'Vezi Locație',
  eventCategory: 'Categorie: ',
  pendingPlayer: 'Nedisputat',
  eventPeriod: 'Perioadă eveniment: ',
  surfaceType: 'Tip suprafață: ',
  noOfCourts: 'Nr. terenuri: ',
  organizerName: 'Organizator: ',
  organizerPhone: 'Telefon: ',
  organizerEmail: 'E-mail: ',
  tournamentDirector: 'Director Turneu: ',
  refereeName: 'Nume Arbitru: ',
  supervisor: 'Reprezentant Sportya: ',
  followEvent: 'Urmărește',
  unfollowEvent: 'Nu mai urmări',
  viewAllUpdates: 'Vezi toate actualizările ({{ total }})',
  viewLessUpdates: 'Restrânge',
  priceNotAvailable: 'Preț Indisponibil',
  topUpNow: 'Încarcă cu fonduri acum',
  officialUpdates: 'Actualizări Oficiale',
  viewCompetition: 'Vezi Competiția',
  competitions: 'Competiții',
  allWinners: 'Campioni',
  level: 'Nivel',
  champion: 'Campion',
  champions: 'Campioni',
  firstServe: '1st Serve',
  league: 'League',
  finalist: 'Finalist',
  finalists: 'Finalists',
  avgLevel: 'Nivel Mediu',
  avgRank: 'Cls. Mediu',
  avgPoints: 'Punctaj Mediu',
  readMore: 'Vezi mai multe',
  officialSponsors: 'Official Sponsors',
  waitingList: 'Lista de Așteptare',
  mainList: 'Tablou Principal',
  acceptanceList: 'Listă de Acceptare',
  preRegistrationList: 'Lista de Sortare',
  sortingList: 'Lista de Sortare',
  confirmPayment: 'Confirmați plata',
  matchStart: 'Start Meciuri: <1>nu mai devreme de {{ date }}</1>',
  restrictions: 'Restricții',
  restrictionsLevel: 'Nivel maxim acceptat {{ level }}',
  restrictionsTeamLevel: 'Nivel echipă max. admis - {{ level }}',
  restrictionsGender: 'Gen: {{ gender }}',
  summerAndPremium: 'Doar Conturi Premium',
  restrictionsDoubleLevel: 'Nvl. jucător Dublu max. acceptat {{ level }}',
  gameLevel: 'Nivel <1>{{ level }}</1>',
  closed: 'Închis',
  tablePlayers: 'Tablou:<1>{{ players }} Jucători</1>',
  tableGroups: 'Tablou:<1>{{ players }} Echipe</1>',
  eliminatory: 'Eliminatoriu',
  groups: 'Grupe',
  group: 'Grupa {{type}}',
  eventFound: '<0>{{ totalResults }} eveniment</0> găsit în <2>{{ location }}</2>',
  eventsFound: '<0>{{ totalResults }} evenimente</0> găsite în <2>{{ location }}</2>',
  eventFoundNc: '<0>{{ totalResults }} eveniment</0> găsit',
  eventsFoundNc: '<0>{{ totalResults }} evenimente</0> găsite',
  in: 'în',
  noResults: 'Nu a fost găsit niciun rezultat.',
  noEventsFound: 'Nu a fost găsit nici un eveniment',
  joinAndPay: 'Înscrie-te și plătește',
  ballToBeAnnounced: 'Va fi anunțată în curând',
  sortList: 'Lista de Sortare',
  qualification: 'Calificări',
  qualifying: 'Calificări',
  phaseStatuses: {
    waitingList: 'Lista de Așteptare',
    mainList: 'Tabloul Principal',
    sortingList: 'Lista de Sortare',
    undefined: 'Indisponibil',

    upcomingCompetitions: {
      upcoming: 'ÎN CURÂND',
      playing: 'SE JOACĂ',
    },

    openingSoon: 'Se Deschid În Curând',
    openingSoonStartDate: 'Înscrierile pe {{ listType }} se deschid pe {{ date }}',
    openingSoonEndDate: '',

    preRegistrationsOpen: 'Pre-Înscrieri deschise',
    preRegistrationsOpenStartDate: 'Înscrierile pe Lista de Sortare se închid pe {{ date }}',
    preRegistrationsOpenEndDate: 'Sortarea va avea loc pe {{ date }}',

    sortPending: 'În Așteptarea Sortării',
    sortPendingStartDate: 'Înscrierile pe Tabloul Principal se deschid pe {{ date }}',
    sortPendingEndDate: '',

    registrationsOpen: 'Deschise',
    registrationsOpenStartDate: '',
    registrationsOpenEndDate: 'Înscrierile pe Tabloul Principal se vor închide pe {{ date }}',

    drawPending: 'În Așteptarea Tragerii la Sorți',
    drawPendingStartDate: '',
    drawPendingEndDate: 'Înscrieri încă deschise pe Lista de Așteptare pâna pe {{ date }}',

    readyToPlay: 'Gata de start',
    readyToPlayStartDate: '',
    readyToPlayEndDate: 'Înscrieri încă deschise pe Lista de Așteptare pâna pe {{ date }}',

    playing: 'În desfășurare',
    playingStartDate: '',
    playingEndDate: 'Înscrieri încă deschise pe Lista de Așteptare pâna pe {{ date }}',

    resultsPending: 'Rezultate în Așteptare',
    resultsPendingStartDate: '',
    resultsPendingEndDate: '',

    ended: 'Desfășurat',
    endedStartDate: '',
    endedEndDate: '',
  },
  registrationsOpenMainList: '<0>Tablou Principal</0>: {{ available }} Loc liber - Grăbește-te!',
  registrationsOpenMainList_plural: '<0>Tablou Principal</0>: {{ available }} Locuri libere - Grăbește-te!',
  registrationsOpenMainListFull: '<0>Tablou Principal</0>: Plin',
  registrationsOpenMainListFull_plural: '<0>Tablou Principal</0>: Plin',
  registrationClosedMainList: '<0>Tablou Principal</0>: Închis',
  registrationClosedMainList_plural: '<0>Tablou Principal</0>: Închis',

  registrationsOpenWaitingList: '<0>Lista de Așteptare</0>: Deschisă ({{ occupied }} Locuri ocupate)',
  registrationsOpenWaitingList_plural: '<0>Lista de Așteptare</0>: Deschisă ({{ occupied }} Locuri ocupate)',
  registrationsOpenWaitingListAll: '<0>Lista de Așteptare</0>: Deschisă  ({{ occupied }} Locuri ocupate)',
  registrationClosedWaitingListAll: '<0>Lista de Așteptare</0>: Închisă',
  registrationClosedWaitingList: '<0>Lista de Așteptare</0>: Închisă',

  preRegistrationsOpenSortList: '<0>Lista de Sortare</0>',
  preRegistrationsOpenSortList_plural: '<0>Lista de Sortare</0>',

  preRegistrationClosedSortList: '<0>Lista de Sortare</0>: Închisă',
  // preRegistrationsSortListFull: "<0>Listă de Sortare</0>: Plină",

  slotsAvailable: ' Locuri libere',
  slotAvailable: ' Loc liber',
  slotsAvailable_space: ' Locuri libere',
  slotAvailable_space: ' Loc liber',
  slotsOccupied: '{{occupiedSlots}} Locuri ocupate',
  slotOccupied: '{{occupiedSlots}} Loc ocupat',

  joinCompetition: {
    seoTitle: 'Înscrie-te în Competiție | Sportya',
  },

  eventDetails: {
    seoTitle: '{{eventName}} - Evenimente | Sportya',
  },

  competitionDetails: {
    seoTitle: 'Nivel {{competitionLevel}}, {{gameType}}, {{eventName}} - Evenimente | Sportya',
    title: 'Ligi Locale - Ghid tehnic și Întrebari Frecvente',
    text: 'Înscrie-te <1>GRATUIT</1> într-o Ligă Locală Sportya și joacă tenis <1>CÂND VREI, UNDE VREI</1> cu adversari de același nivel. Liga Locală propune, ca format de joc, evoluția într-o grupă cu mai mulți jucători, în sistem fiecare-cu-fiecare, ce se derulează pe parcursul a mai multe săptămâni.',
  },

  openingSoon: 'Se Deschid În Curând',
  preRegistrationsOpen: 'Pre-Înscrieri deschise',
  sortPending: 'În așteptare',
  registrationsOpen: 'Înscrieri deschise',
  drawPending: 'În așteptare tragere la sorți',
  readyToPlay: 'Gata de start',
  playing: 'În desfășurare',
  resultsPending: 'Rezultate în așteptare',
  ended: 'Desfășurat',
  endedStartDate: '',
  endedEndDate: '',

  gender: 'Gen',
  male: 'Masculin',
  female: 'Feminin',
  onlymale: 'Doar Masculin',
  onlyfemale: 'Doar Feminin',
  mixt: 'Mixt',
  onlymixt: 'Mixt',
  allAges: 'Toate vârstele',
  customAge: 'Alege inverval',
  weekdays: 'În timpul săptămânii',
  weekend: 'În weekend',
  resetToDefault: 'Resetează filtrele',
  shirtSize: 'Mărimea Tricoului',
  apply: 'Aplică',
  officialBall: 'Mingea Turneului',
  playerPackageTitle: 'Pachet de Participare',
  playerPackage: `Pachetul de participare standard conține:
- 2 mingi noi pentru fiecare meci de simplu (se returnează organizatorului după meci);
- o sticlă de apă plată pentru fiecare jucător.`,
  winners: {
    title: 'Tablou Principal',
    subtitle: 'Campioni',
    downloadPdf: 'Download PDF',
    querterfinals: 'Sferturi',
    semifinals: 'Semifinale',
    final: 'Finală',
  },

  join: {
    matchStart: 'Start Meciuri',
    package: {
      title: 'Pachet de Participare (Gratuit)',
      subtitle: 'Pachetul de participare standard conține:',
      1: '2 mingi noi pentru fiecare meci de simplu',
      2: 'o sticlă de apă plată pentru fiecare jucător',
      return: 'La finalul meciului, mingile se returnează organizatorului. Pachetul de participare dar si alte produse achiziționate prin intermediul Magazinului de Turneu (în cazul în care au fost disponibile) vor fi livrate către recepția Clubului Organizator și pot fi ridicate direct de la turneu. ',
    },

    sprinten: {
      title: 'Fă parte din schimbare!',
      1: 'Susține bursele de kinetoterapie pediatrică și ajută zeci de copii cu dizabilități locomotorii să se bucure de mișcare și să aibă șansa la o viață normală.',
      2: 'Program susținut de Fundația ProAm Events prin proiectul SPRINTEN.',
      donate: 'Donează și tu!',
    },

    duoJoin: 'Selecteaza partenerul de Dublu',
    searchPartner: 'Caută-ți partenerul de joc și realizează înscrierea',
    playerAdded: 'Jucătorul a fost adăugat echipei tale!',
    changePlayer: 'Schimbă jucătorul',
    payment: {
      title: 'Metodă de Plată',
      selectMethod: 'Alege Metoda de Plată',
      virtualWallet: 'Portofel Virtual',
      creditCard: 'Card de Credit',
      availableBalance: 'Fonduri Disponibile',
      insufficientFunds: 'Fonduri Insuficiente',
      negativeFunds: 'Fonduri Negative',
      topUpFirst: 'Te rugăm să îți <0>Încarci Portofelul Virtual</0> pentru a utiliza această metodă de plată. Alternativ, poți să plătești cu cardul de Credit / Debit',
      confirmPay: 'Confirmă și Plătește',
      redirect: 'Vei fi redirecționat către',
    },
  },
  amountToBePaid: 'Suma de Plată',
  remainingBalance: 'Balanță',
  confirmPay: 'Confirmă și Plătește',
  pleaseConfirmPaymentForEvent: 'Te rugăm să confirmi realizarea plății cu fonduri din Portofelul Virtual, pentru evenimentul selectat.',
  summerPremiumAccountPrice: '<0>Cont Premium: {{ price }} {{ currency }}</0>',
  clubEntryFee: 'Taxă Înscriere Club: <1><2>{{ primaryClubFee }}</2></1>',
  clubEntryFee2: 'Taxă Înscriere Club',
  premiumDiscount: 'Reducere pt. Premium',
  getPremium: 'Achiziționează Contul Premium',
  withdraw: 'Retrage-te',
  joined: 'Înscris',
  summary: 'Rezumat',
  bookingFee: 'Taxă Servicii Sportya ',
  partnerBookingFee: 'Partener - Taxă Servicii Sportya ',
  bookingFeeDiscounted: 'Taxă Servicii Sportya (Redusă)',
  partnerBookingFeeDiscounted: 'Partener - Taxă Servicii Sportya (Redusă) ',
  premiumDiscountIncluded: '(incl. -{{ amount }} {{ currency }} Reducere pt. Premium)',
  secondGameDiscountIncluded: '(incl. -{{ amount }} {{ currency }} Reducere pt. a 2-a Competitie)',
  getPremiumDiscount: 'Achiziționează un Cont Premium și plătește doar <1>{{ amount }} {{ currency }}</1>',
  learnMore: 'Află mai multe',
  participationFee: 'Taxă Înscriere Club',
  participationFeeInfo: 'Se plătește direct organizatorului, la turneu. Club organizator: <1>{{ clubName }}</1>.',
  yourTShirtSize: 'Dimensiunea tricoului tău',
  sprintenDonation: 'Donație SPRINTEN',
  sprintenDonationProduct: 'Donație proiect SPRINTEN: {{ amount }} {{ currency }}',
  tShirtProduct: 'T-Shirt Premium Sportya Play On {{ gender }} {{ size }}',
  shoppingBasket: 'Coş de Cumpărături',
  couponDiscount: 'Coupon <1>Remove Coupon</1>',
  totalToBePaid: 'Total de plată',
  inclVAT: 'incl. TVA',
  addCoupon: 'Adaugă Cupon',
  joinLevelMissing: 'Te rugăm să-ți setezi nivelul de joc înainte să participi într-o competiție!',
  payRule: `Prin continuarea înregistrării confirm că am citit, am înțeles și sunt de acord cu <1>Sportya - Reguli și Regulamente</1>. Confirm că sunt apt fizic și îmi asum întreaga răspundere pentru participare. Sunt de acord ca numărul meu de telefon să fie distribuit organizatorului acestui eveniment și/sau, în cazul Ligilor Locale, jucătorilor grupei din care fac parte. 

  De asemenea, confirm că am citit, am înțeles și sunt de acord cu <3>Polița de Retur</3>. Sunt de acord ca prestația serviciilor să înceapă în timpul perioadei de 14 zile de retragere din contract. Am luat la cunoștință faptul că dreptul meu de retragere se va pierde după executarea completă a serviciilor achiziționate.`,

  learnMoreDiscount: 'Obține un Discount <1>Află mai Multe</1>',
  discountModalTitle: 'Discount înscriere la 2 probe',
  discountModalDescription: `Jucatorii posesori de conturi <1>GRATUITE</1> beneficiaza de un discount in valoare de <3>15 RON</3> la inscrierea in 2 probe din cadrul unui turneu Silver sau Special, respectiv <5>25 RON</5> la inscrierea in 2 probe din cadrul unui turneu Gold. 
  
  Discount-ul se aplica automat in momentul inscrierii, conditia fiind ca inregistrarea in prima proba, indiferent daca este de tip simplu, dublu sau pe echipe, sa fie validata in sistem (plata realizata) inainte de realizarea rezervarii in cea de-a 2-a proba.`,
  change: 'Schimbă',
  players: 'Jucători',
  cancel: 'Anulează',
  widthdraw: 'Retrage',
  withdrawText1: 'Sigur dorești să te retragi din acest eveniment? \n Retragerile din turnee pot fi penalizate prin puncte sau restricționarea dreptului de înscriere la turnee, în funcție de perioada în care au loc și de numărul acestora. Retragerea din lista de așteptare nu este penalizată.',
  withdrawText2: 'Mai multe informații despre retrageri și consecințele acestora sunt disponibile în <1> Regulamentul general </1> (capitolul 5). Returnarea sumei aferente comenzii online se va face în conformitate cu <3> Politica de returnare. </3>',
  filtersList: {
    champions: 'Turneul Campionilor',
    firstServe: '1st Serve',
    silver: 'Silver',
    gold: 'Gold',
    special: 'Special',
    platinum: 'Platinum',
    league: 'Ligă Locală',
  },
  sortBy: 'Sortează după',
  startDate: 'Dată start',
  newest: 'Cel mai recent',
  showEndedEvents: 'Evenimente Încheiate',
  joinedEvents: 'Evenimentele Mele',
  reservedSlot: 'Loc Rezervat /',
  availableSlot: 'Loc Disponibil',
  underMaintenance: 'Acest eveniment este în Mentenanță. Înregistrările sunt suspendate pentru moment.',
  underMaintenanceButton: 'În mentenanță',
  competitionsUnavailable: 'Competițiile pentru acest eveniment vor fi anunțate în curând.',
  min1event: 'Min. un eveniment jucat',
  min3event: 'Min. 3 evenimente jucate',
  joinNowButton: {
    ended: 'Desfășurat',
    playing: 'În desfășurare',
    resultsPending: 'Rezultate în Așteptare',
    drawPending: 'În Așteptarea Tragerii la Sorți',
    readyToPlay: 'Gata de Start',
  },
  withdrawal: {
    early: {
      title: 'Renunțare la Participare',
      subtitle: 'Te rugăm să confirmi retragerea din competiție.',
      desc: 'Dacă vei confirma retragerea, vei renunța la locul ocupat în competiție, iar Taxa Serviciilor Sportya va fi returnată în Portofelul Virtual al jucătorului care a făcut înregistrarea.',
    },
    announced: {
      title: 'Retragere ANUNȚATĂ de pe {{list}}',
      subtitle: 'Te rugăm să confirmi retragerea din competiție.',
      desc: 'Dacă vei confirma retragerea, vei renunța la locul ocupat în competiție, iar Taxa Serviciilor Sportya va fi returnată în Portofelul Virtual al jucătorului care a făcut înregistrarea.',
    },
    late: {
      title: 'Retragere TÂRZIE de pe {{list}}',
      subtitle: 'Te rugăm să confirmi retragerea din competiție.',
      desc: 'Dacă vei confirma retragerea, vei renunța la locul ocupat în competiție, iar Taxa Serviciilor Sportya NU va fi returnată în Portofelul Virtual.',
    },
    denied: {
      title: 'Retragerile Online nu mai sunt permise',
      desc: 'În acest moment, retragerile online nu mai sunt disponibile. Cu toate acestea, te poți retrage din eveniment prin contactarea Directorului de Turneu. Gestul tău îi va permite să atenueze impactul retragerii. Te rugăm să ai în vedere că Taxa Serviciilor Sportya NU va fi returnată în Portofelul Virtual.',
    },
    disclaimer: 'Te rugăm să verifici <1>Regulile și Reglementările Sportya</1> și <3>Politica de Retur</3> pentru mai multe informații.',
  },
  frozenLeaderboard: 'Clasamentul oficial va fi recalculat săptămânal începând cu data de 28 decembrie 2021. Vă mulțumim pentru răbdare.',
  sortListOpen: 'Înscrieri deschise pe Lista de Sortare',
  pending: 'Înscriere în curs de validare',
  stages: {
    1: 'Finală',
    2: 'Semifinale',
    3: 'Sferturi',
    4: 'Sferturi',
  },
  quickFind: 'Caută rapid cel mai bun eveniment pentru tine',
  tennisNationalCalendar: 'Alege calendarul tău național de Tenis',
  padelNationalCalendar: 'Alege calendarul tău național de Padel',
  tennisQuickFindTitle: 'Caută rapid cel mai bun eveniment de <1>tennis</1> pentru tine',
  padelQuickFindTitle: 'Caută rapid cel mai bun eveniment de <1>padel</1> pentru tine',
  specificMonth: 'Dată personalizată',
  showFeaturedEvents: 'Show Featured Events',
  hideFeaturedEvents: 'Hide Featured Events',
  lfp: {
    noPartner: 'Nu ai încă partener pentru Dublu?',
    whosInterested: 'Vezi cine este interesat',
    findAPartner: 'Găsește un partener pentru Dublu',
    eligible: `Înainte de a te adăuga pe listă sau de a contacta un jucător, te rugăm să te asiguri că poți îndeplini criteriile de eligibilitate pentru această competiție alături de partenerul de echipă pe care dorești să îl contactezi.
    
    Adăugarea ta pe listă sau contactarea unui potențial partener pentru echipa de Dublu nu generează nicio obligație pentru înscrierea în această competiție. <1>Află mai multe</1>`,
    registrationNotRequired: 'Adăugarea ta pe lista de jucători în căutarea unui partener pentru echipa de Dublu nu generează nicio obligație pentru înscrierea în această competiție.',
    findMore: 'Află mai multe',
    imLooking: 'Caut partener',
    optOut: 'Renunț',
    contact: 'Mesaj',
    playersLFP: '{{ total }} jucători în căutare de parteneri',
    playerLFP: '{{ total }} jucător în căutare de partener',
    join: 'Adaugă-te pe listă!',
    beFirst: 'Arată-le altor jucători în căutare de parteneri de Dublu pentru această competiție că și tu ești interesat.',
    eligibilityCriteria: `Te rugăm să verifici criteriile de eligibilitate pentru această competiție înainte de a căuta un potențial partener.
    
    Adăugarea ta pe lista de jucători în căutarea unui partener pentru echipa de Dublu nu generează nicio obligație pentru înscrierea în această competiție. <1>Află mai multe</1>`,
    iAmLFP: 'Caut un partener',
  },
  fmBanner: {
    desc: `În timp ce aștepți să fie anunțate noi evenimente în țara ta, poți juca un meci amical cu prietenii sau alți jucători pe Sportya`,
  },
};
