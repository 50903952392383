import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';

import ToggleVisible from '../../common/ToggleVisible';
import LoadImage from '../../common/LoadImage';
import { getAccountInfo, getAccountProfileCompletionSuccess, setMasterSport } from "../reducers/session";

const ContentWrapper = styled.div`
    position: relative;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 44px;
    background: #ffffff;
    height: 40px;
    min-width: 145px;

    > div {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background: none;
        border: none;
        align-items: center;
        gap: 8px;
    }

    span {
        color: ${props => props.theme.colors.primary};
        font-weight: bold;
        font-size: 14px;
        flex: 2;
    }

    ul {
        position: absolute;
        top: 55px;
        left: 0;
        background: white;
        padding: 10px;
        border-radius: 5px;
        color: black;
        list-style: none;
        transition: all 0.5s ease;
        box-shadow: 0 6px 20px 0 #282E2D66;
        z-index: 10;

        ${props => props.theme.functions?.mediaQueryMax('1023px', `
          top: 50px;
          height: max-content;
        `)}

        > li {
            padding: 10px;
            border-radius: 3px;
            transition-duration: 0.5s;
            cursor: pointer;
            display: flex;
            gap: 8px;
            font-weight: bold;
            font-size: 14px;
            min-width: 120px;

            &:hover, &.active {
                background: ${props => props.theme.colors.lightBlue};
            }
        }
    }

    img:last-of-type {
        margin-left: 5px;
        //max-width: 12px;
        height: auto;
    }

    ${props => props.theme.functions?.mediaQueryMax('1223px', `
      zoom: 0.85;
    `)}
    
    ${props => props.mobileOnly && props.theme.functions?.mediaQueryMin('1024px', `
      display: none;
    `)}

    ${props => props.mobileOnly && props.theme.functions?.mediaQueryMax('1023px', `
      position: fixed;
      bottom: 20px;
      zoom: 1;
      left: 50%;
      transform: translate(-50%, -50%);
      background: ${props.theme.colors.primary};
      min-width: auto;
      
      span {
        color: ${props.theme.colors.white};
        font-size: 16px;
      }

      ul {
        top: auto;
        bottom: 50px;
        
        li {
          font-size: 16px;
        }
      }

      > div {
        img {
          filter: brightness(0) invert(1);
        }
        
        img:last-of-type {
          display: none;
        }
      }
    `)}
`;

const SportSelector = ({ activeSports, mobileOnly }) => {
  const dispatch = useDispatch();
  const { accountInfo, masterSport } = useSelector((state) => state?.session);
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);

  useEffect(() => {
    if (accountInfo?.favoriteSport && !localStorage.getItem('masterSport')) {
      dispatch(setMasterSport(accountInfo?.favoriteSport));
      localStorage.setItem('masterSport', accountInfo?.favoriteSport);
    }
  }, [accountInfo?.favoriteSport]);

  if (!activeSports) return <></>;

  const handleSport = async (value) => {
    setIsComponentVisible(!isComponentVisible);
    dispatch(setMasterSport(value));
    localStorage.setItem('masterSport', value);
    await dispatch(getAccountInfo({ masterSport: value }));
    await dispatch(getAccountProfileCompletionSuccess());
  };

  return (
    <ContentWrapper mobileOnly={mobileOnly}>
      <div onClick={() => setIsComponentVisible(!isComponentVisible)}>
        <img src={LoadImage(`sports/${masterSport}.svg`)} alt={masterSport} />
        <span>
          <Trans ns="common" i18nKey={`sports.${masterSport}`} />
        </span>
        <img alt="Select Sport" src={LoadImage('arrow-down.svg')} />
      </div>

      {isComponentVisible && (
        <ul ref={ref}>
          {!isEmpty(activeSports)
            && activeSports.map((sport, key) => (
              <li key={key} onClick={() => handleSport(sport)} className={masterSport === sport ? 'active' : ''}>
                <img src={LoadImage(`sports/${sport}.svg`)} alt={sport} />
                <Trans ns="common" i18nKey={`sports.${sport}`} />
              </li>
            ))}
        </ul>
      )}
    </ContentWrapper>
  );
};

export default SportSelector;
