export default {
  seoTitle: 'Намерете събитие | Sportya',
  leaderboardSeoTitle: 'Класирания| Sportya',
  leaderboards: 'Класирания',
  results: 'Резултати',
  newEventsNearYou: 'Препоръчани Събития',
  seeAll: 'Виж всички',
  seeEvent: 'Вижте Събитие',
  seeMyEvents: 'Моите Събития',
  goToEvent: 'Вижте Събитието ⟶',
  newEvent: 'Ново Събитие',
  newEvents: 'Нови Събития',
  recommendedEvents: 'Препоръчани Събития',
  pickLocation: 'Локация',
  pickCategory: 'Категория',
  pickYear: 'Година',
  pickMonth: 'Месец',
  all: 'Всички',
  filter_all: 'Филтри {{amount}}',
  clearAll: 'Задаване на нови филтри',
  teamGameLevel: 'Ниво на екипа',
  allActive: 'Активни събития',
  availableEntries: 'Свободни места',
  wildcard: 'Wildcard',
  prizes: 'Награди',
  filters: 'Филтри',
  gameType: 'Формат: {{ gameType }}',
  gameTypeText: 'Формат',
  playerGameLevel: 'Ниво на игра',
  playerRank: 'Място',
  eventLevel: 'Ниво на турнира',
  eventStatus: 'Статус на Събитието',
  age: 'Възраст',
  playMode: 'Вид на игра',
  singles: 'Сингъл',
  doubles: 'Двойки',
  Singles: 'Сингъл',
  Doubles: 'Двойки',
  joinNowLink: '<0>Участвай сега</0>',
  joinNow: 'Присъедини се сега',
  viewLocation: 'Виж местоположението',
  eventCategory: 'Категория: ',
  eventPeriod: 'Период на Събитието: ',
  surfaceType: 'Вид настилка: ',
  noOfCourts: 'Брой кортове: ',
  organizerName: 'Организатор: ',
  organizerPhone: 'Телефон: ',
  organizerEmail: 'E-mail: ',
  tournamentDirector: 'Директор на турнира: ',
  refereeName: 'Име на Съдия: ',
  supervisor: 'Представител Sportya: ',
  followEvent: 'следвам',
  unfollowEvent: 'Прекратете следенето',
  viewAllUpdates: 'Виж всички актуализации ({{ total }})',
  viewLessUpdates: 'Вижте по-малко актуализации',
  priceNotAvailable: 'Цената не е налична',
  topUpNow: 'Заредете със средства сега',
  officialUpdates: 'Официални актуализации',
  viewCompetition: 'Виж Събитието',
  competitions: 'Събития',
  allWinners: 'Шампиони',
  level: 'Ниво',
  champion: 'Шампион',
  champions: 'Шампиони',
  firstServe: 'Първи сервис',
  league: 'Лига',
  finalist: 'Финалист',
  finalists: 'Финалист',
  avgLevel: 'Средно Ниво',
  avgRank: 'Средно Класиране',
  avgPoints: 'Среден точков резултат',
  readMore: 'Виж повече',
  officialSponsors: 'Официални спонсори',
  waitingList: 'Списък на Чакащи',
  mainList: 'Основна Схема',
  acceptanceList: 'Списък одобрени',
  preRegistrationList: 'Предварителен списък',
  sortingList: 'Предварителен списък',
  confirmPayment: 'Потвърдете плащането',
  matchStart: 'Начало мача: <1>не по-рано от {{ date }}</1>',
  restrictions: 'Ограничения',
  restrictionsLevel: 'Макс ниво {{ level }}',
  restrictionsTeamLevel: 'Максимално допустимо ниво отбор - {{ level }}',
  restrictionsGender: 'Пол: {{ gender }}',
  summerAndPremium: 'Само ПРЕМИУМ акаунти',
  restrictionsDoubleLevel: 'Максимално ниво играч на Двойки {{ level }}',
  gameLevel: 'Ниво <1>{{ level }}</1>',
  closed: 'Затворено',
  tablePlayers: 'Схема на игра:<1>{{ players }} Играчи</1>',
  tableGroups: 'Схема на игра:<1>{{ players }} Отбори</1>',
  eliminatory: 'Елиминации',
  groups: 'Групи',
  eventsFound: '<0>{{ totalResults }} събития</0> намерени в <2>{{ location }}</2>',
  eventFoundNc: '<0>{{ totalResults }} събитие</0> намерен',
  eventsFoundNc: '<0>{{ totalResults }} събития</0> намерени',
  in: 'в',
  noResults: 'Няма намерени резултати',
  noEventsFound: 'Не бяха намерени събития.',
  joinAndPay: 'Регистрирайте се и платете',
  ballToBeAnnounced: 'Скоро ще бъде обявено',
  sortList: 'Предварителен списък',
  phaseStatuses: {
    waitingList: 'Списък с чакащи',
    mainList: 'Основна схема',
    sortingList: 'Предварителен списък',

    openingSoon: 'Ще се отварят скоро',
    openingSoonStartDate: 'Регистрации на {{ listType }} ще са отворени на {{ date }}',
    openingSoonEndDate: '',

    preRegistrationsOpen: 'Предварителните регистрации са отворени',
    preRegistrationsOpenStartDate: 'Записванията в Предварителния списъка се затварят на {{ date }}',
    preRegistrationsOpenEndDate: 'Подреждането ще се извърши на {{ date }}',

    sortPending: 'Предстоящо подреждане',
    sortPendingStartDate: 'Записванията в Основната схема се отварят на{{ date }}',
    sortPendingEndDate: '',

    registrationsOpen: 'Отворени',
    registrationsOpenStartDate: '',
    registrationsOpenEndDate: 'Записванията в Основната схема се затворят на {{ date }}',

    drawPending: 'В очакване на тегленето на жребия',
    drawPendingStartDate: '',
    drawPendingEndDate: 'Записванията все още са отворени в Списъка на чакащите до {{ date }}',

    readyToPlay: 'Готови за Началото',
    readyToPlayStartDate: '',
    readyToPlayEndDate: 'Записванията все още са отворени в Списъка на чакащите до {{ date }}',

    playing: 'Текущ',
    playingStartDate: '',
    playingEndDate: 'Записванията все още са отворени в Списъка на чакащите до {{ date }}',

    resultsPending: 'Резултати в Очакване',
    resultsPendingStartDate: '',
    resultsPendingEndDate: '',

    ended: 'Приключил',
    endedStartDate: '',
    endedEndDate: '',
  },
  registrationsOpenMainList: '<0>Основна схема</0>: {{ available }} Свободно място - Побързай!',
  registrationsOpenMainList_plural: '<0>Основна схема</0>: {{ available }} Свободни места - Побързай!',
  registrationsOpenMainListFull: '<0>Основна схема</0>: Запълнена',
  registrationsOpenMainListFull_plural: '<0>Основна схема</0>: Запълнена',
  registrationClosedMainList: '<0>Основна схема</0>: Затворена',
  registrationClosedMainList_plural: '<0>Основна схема</0>: Затворена',

  registrationsOpenWaitingList: '<0>Списък с чакащи</0>: Отворени ({{ occupied }} Заети места)',
  registrationsOpenWaitingList_plural: '<0>Списък с чакащи</0>: Отворени({{ occupied }} Заети места)',
  registrationsOpenWaitingListAll: '<0>Списък с чакащи</0>: Отворени  ({{ occupied }} Заети места)',
  registrationClosedWaitingListAll: '<0>Списък с чакащи</0>: Затворено',
  registrationClosedWaitingList: '<0>Списък с чакащи</0>: Затворено',

  preRegistrationsOpenSortList: '<0>Предварителен списък</0>',
  preRegistrationsOpenSortList_plural: '<0>Предварителен списък</0>',

  preRegistrationClosedSortList: '<0>Предварителен списък</0>: Затворено',
  // preRegistrationsSortListFull: "<0>Предварителен списък</0>: Отворено",

  slotsAvailable: 'Свободни места',
  slotAvailable: ' Свободно място',
  slotsAvailable_space: ' Свободни места',
  slotAvailable_space: ' Свободно място',
  slotsOccupied: '{{occupiedSlots}} Заети места',
  slotOccupied: '{{occupiedSlots}} Заето място',

  joinCompetition: {
    seoTitle: 'Присъединете се към състезанието| Sportya',
  },

  eventDetails: {
    seoTitle: '{{eventName}} - Събития | Sportya',
  },

  competitionDetails: {
    seoTitle: 'Ниво {{competitionLevel}}, {{gameType}}, {{eventName}} - Събитие | Sportya',
    title: 'Провеждането на Местните лиги Tenis partener',
    text: 'Запиши се <1>GRATUIT</1> В една Местна лига Sportya и играй тенис <1>КОГАТО ИСКАВ, КЪДЕТО ИСКАШ</1> с противник от същото ниво. Местната лига предлага, като формат на игра, участието в една група с повече играчи, всеки срещу всеки, провеждайки се в рамките на няколко седмици.',
  },

  openingSoon: 'Ще се отворят скоро',
  preRegistrationsOpen: 'Първоначални регистрации отворени',
  sortPending: 'В изчакване',
  registrationsOpen: 'Отворени регистрации',
  drawPending: 'В очакване на жребия',
  readyToPlay: 'Готови за игра',
  playing: 'Текущ',
  resultsPending: 'Резултати в очакване',
  ended: 'Проведен',
  endedStartDate: '',
  endedEndDate: '',

  gender: 'Пол',
  male: 'Мъж',
  female: 'Жена',
  onlymale: 'Само Мъжки',
  onlyfemale: 'Само Женски',
  mixt: 'Смесени',
  onlymixt: 'Смесени',
  allAges: 'Всички възрасти',
  customAge: 'Избери интервал',
  weekdays: 'През седмицата',
  weekend: 'През уикенда',
  resetToDefault: 'Задай нови филтри',
  shirtSize: 'Размер тениска',
  apply: 'Приложи',
  officialBall: 'Топки на турнира',
  playerPackageTitle: 'Пакет за Участие',
  playerPackage: `Стандартен пакет за участие съдържа:
- 2 нови топки за всеки мач на сингъл (връщат се на организатора след мача);
- едно шише вода за всеки мач.`,
  winners: {
    title: 'Основна схема',
    subtitle: 'Шампиони',
    downloadPdf: 'Свали PDF',
    querterfinals: 'Четвъртфинали',
    semifinals: 'Полуфинали',
    final: 'Финал',
  },

  join: {
    matchStart: 'Начало на мачовете',
    package: {
      title: 'Пакет за участие (Безплатно)',
      subtitle: 'Стандартен пакет за участие съдържа:',
      1: '2 нови топки за всеки мач',
      2: 'едно шише вода за всеки играч',
      return: 'След края на мача, топките се връщат на организатора. Пакетът за участие и други продукти, закупени чрез Tournament Store (ако има такива), ще бъдат доставени на рецепцията на Клуба Организатор и могат да бъдат взети директно на турнира. ',
    },

    sprinten: {
      title: 'Бъди част от промяната!',
      1: 'Подкрепи стипендиите за педиатрична физиотерапия и помагни на десетки деца с двигателни увреждания да се насладят на упражненията и да имат шанс за нормален живот.',
      2: 'Програма, подкрепена от фондация ProAm Events чрез проекта SPRINTEN.',
      donate: 'Дарете!',
    },

    duoJoin: 'Изберете партньора на Двойки',
    searchPartner: 'Намерете своя партньор в играта и се регистрирайте',
    playerAdded: 'Играчът е добавен към вашия отбор!',
    changePlayer: 'Сменете партньора',
    payment: {
      title: 'Начин на плащане',
      selectMethod: 'Изберете начин на плащане',
      virtualWallet: 'Виртуален портфейл',
      creditCard: 'Кредитна карта',
      availableBalance: 'Налични средства',
      insufficientFunds: 'Недостатъчни средства',
      negativeFunds: 'Отрицателни средства',
      topUpFirst: 'Моля <0>Заредите виртуаления портфейл</0> за да използвате този начин на плащане. Като алтернатива можете да платите с кредитна/дебитна карта.',
      confirmPay: 'Потвърди и плати',
      redirect: 'Ще бъдете пренасочени към',
    },
  },
  amountToBePaid: 'Сума за плащане',
  remainingBalance: 'Баланс',
  confirmPay: 'Потвърди и плати',
  pleaseConfirmPaymentForEvent: 'Моля, потвърдете окончателното си плащане с вашия виртуален портфейл за това избрано събитие.',
  summerPremiumAccountPrice: '<0>ПРЕМИУМ акаунт: {{ price }} {{ currency }}</0>',
  clubEntryFee: 'Такса за участие - Клуб: <1><2>{{ primaryClubFee }}</2></1>',
  clubEntryFee2: 'Такса за участие - Клуб',
  premiumDiscount: 'Премиум отстъпка',
  getPremium: 'Вземи ПРЕМИУМ',
  withdraw: 'Оттегли се',
  joined: 'Регистрирали',
  summary: 'Обобщение',
  bookingFee: 'Sportya системна такса  ',
  partnerBookingFee: 'Партньор - Sportya системна такса ',
  premiumDiscountIncluded: '(вкл. -{{ amount }} {{ currency }} PПремиум отстъпка)',
  secondGameDiscountIncluded: '(вкл. -{{ amount }} {{ currency }} отстъпка за 2-ро състезание)',
  getPremiumDiscount: 'Вземете ПРЕМИУМ акаунт и плащайте само <1>{{ amount }} {{ currency }}</1>',
  learnMore: 'Повече',
  participationFee: 'Такса за участие - Клуб',
  participationFeeInfo: 'Плаща се директно на организатора, на място, на събитието. Клуб организатор: <1>{{ clubName }}</1>.',
  yourTShirtSize: 'Размер на твоята тениска',
  sprintenDonation: 'Дарение SPRINTEN',
  sprintenDonationProduct: 'Дарение за SPRINTEN: {{ amount }} {{ currency }}',
  tShirtProduct: 'Тениска Premium Sportya Play On {{ gender }} {{ size }}',
  shoppingBasket: 'Кошница',
  couponDiscount: 'Купон <1>Remove Coupon</1>',
  totalToBePaid: 'Тотално за плащане',
  inclVAT: 'с ДДС',
  addCoupon: 'Прибави Купон',
  joinLevelMissing: 'Моля, задайте нивото на игра, преди да участвате в състезание!',
  payRule: `С избирането на бутона до него потвърждавам, че съм прочел, разбрал и съм съгласен с <1> Sportya - Правила и разпоредби</1>. Потвърждавам, че съм физически подготвен и поемам пълната отговорност за участието. Съгласен съм моят телефонен номер да бъде предоставен на организатора на това събитие и/или, в случай на Местни лиги, на играчите от групата, към която принадлежа. 

  Потвърждавам също, че съм прочел, разбрал и съм съгласен <3>Полиция за връщане</3>. Съгласен съм, че предоставянето на услуги ще започне през 14-дневния период за отказ. Информиран съм, че правото ми на отказ ще бъде загубено след пълното изпълнение на закупените услуги.`,
  learnMoreDiscount: 'Вземете Отстъпка <1>Виж повече</1>',
  discountModalTitle: 'Регистрация с отстъпка при участие на 2 нива',
  discountModalDescription: `Притежатели на акаунти<1>БЕЗПЛАТНИ</1> получават отстъпка на стойност<3>15 леи</3> при регистрация на 2 нива на игра в един турнир Silver или Special, съответно <5>25 леи</5> при регистрация на 2 нива на игра в един турнир Gold. 
  
  Отстъпката се прилага автоматично в момента на регистрация, като условието е регистрацията на първото ниво , независимо дали е сингъл, двойки или отборно, да бъде валидирана в системата (извършено плащане) преди да се направи регистрация на второто ниво.`,
  change: 'ПрИграчи',
  cancel: 'Отказ',
  widthdraw: 'Оттегляне',
  withdrawText1: 'Наистина ли искате да се оттеглите от това събитие? \n Оттегленията от турнири могат да бъдат санкционирани с точки или ограничаване на правото на регистрация за турнири, в зависимост от периода, в който се провеждат и техния брой. Оттеглянето от списъка на чакащите не се санкционира.',
  withdrawText2: 'Повече информация за тегленията и последиците от тях можете да намерите в <1> Общи разпоредби </1> (Глава 5). Възстановяването на сумата, свързана с онлайн поръчката, ще се извърши в съответствие с<3> Условия за връщане. </3>',
  filtersList: {
    champions: 'Турнира на Шампионите',
    firstServe: 'Първи сервис',
    silver: 'Silver',
    gold: 'Gold',
    special: 'Special',
    platinum: 'Platinum',
    league: 'Местна лига',
  },
  sortBy: 'Подреди по',
  startDate: 'Начална дата',
  newest: 'Най-скорошен',
  showEndedEvents: 'Завършили турнири',
  joinedEvents: 'Моите Събития',
  reservedSlot: 'Резервирано място /',
  availableSlot: 'Налично място',
  underMaintenance: 'Това събитие е в Поддръжка. В момента регистрациите са спрени.',
  underMaintenanceButton: 'В ремонт',
  competitionsUnavailable: 'Състезанията за това събитие ще бъдат обявени скоро.',
  min1event: 'Минимум едно изиграно събитие',
  min3event: 'Минимум 3 изиграни събития',
  joinNowButton: {
    ended: 'Приключил',
    playing: 'Текущ',
    resultsPending: 'Чакащи резултати',
    drawPending: 'В очакване на теглене на жребия',
    readyToPlay: 'Начало',
  },
  withdrawal: {
    early: {
      title: 'Отказ от Участие',
      subtitle: 'Моля, потвърдете оттеглянето си от състезанието.',
      desc: 'Ако потвърдите оттеглянето, вие ще се откажете от заетото място в състезанието, а таксата за Sportya системна услуга ще бъде върната във Виртуалния портфейл на регистрирания играч.',
    },
    announced: {
      title: 'ОБЯВЯВА Оттегляне от {{list}}',
      subtitle: 'Моля, потвърдете оттеглянето си от състезанието.',
      desc: 'Ако потвърдите оттеглянето, вие ще се откажете от заетото място в състезанието, а таксата за Sportya системна услуга ще бъде върната във Виртуалния портфейл на регистрирания играч',
    },
    late: {
      title: 'КЪСНО Оттегляне от {{list}}',
      subtitle: 'Моля, потвърдете оттеглянето си от състезанието..',
      desc: 'Ако потвърдите оттеглянето, вие ще се откажете от заетото място в състезанието, а таксата за Sportya системна услуга НЯМА да бъде върната във Виртуалния портфейл',
    },
    denied: {
      title: 'Онлайн оттеглянията вече не са позволени',
      desc: 'Онлайн оттегленията вече не са позволени. Въпреки това, можете да се оттеглите от събитието, като се свържете с директора на турнира. Това ще му позволи да смекчи въздействието от оттеглянето Ви. Моля, имайте предвид, че Sportya системна такса НЯМА да бъде възстановена във Виртуалния портфейл.',
    },
    disclaimer: 'Моля, проверете <1>Правилата и разпоредбите на Sportya</1> и <3>Условия за връщане</3> за повече информация.',
  },
  frozenLeaderboard: 'Официалните ранглисти засега са замразени, за да се позволи миграцията на данни от платформата Tenis Partener. Благодаря ви за разбирането!',
  sortListOpen: 'Отворени записвания в Първоначалния Списък',
  pending: 'Регистрацията е в процес на потвърждение',

  goToEvent2: 'Към събитието',
  undisputed: 'Безспорен',
  announcedWithdrawal: 'Обявено оттегляне',
  penalty: 'Наказание',
  pendingPlayer: 'Безспорен',
  group: 'Група {{type}}',
  eventFound: '<0>{{ totalResults }} събитие</0> намерен в <2>{{ location }}</2>',

  proximityRadius: 'Радиус',
  qualifying: 'Класира се',
  undefined: 'Няма',
  upcomingCompetitions: {
    upcoming: 'ПРЕДСТОЯЩ',
    playing: 'ИГРАЕ СЕ',
  },

  bookingFeeDiscounted: 'Sportya такса (с отстъпка)',
  partnerBookingFeeDiscounted: 'Sportya партньор такса (с отстъпка)',

  stages: {
    1: 'Финал',
    2: 'полуфинали',
    3: 'Четвъртфинали',
    4: 'Четвъртфинали',
  },
  nationalCalendar: 'Изберете Вашия национален календар',
  tennisNationalCalendar: 'Изберете своя национален тенис календар',
  padelNationalCalendar: 'Изберете своя национален календар за падел',
  tennisQuickFindTitle: 'Бързо намерете най-доброто <1>тенис</1> събитие за вас',
  padelQuickFindTitle: 'Бързо намерете най-доброто <1>Padel</1> събитие за вас',
  specificMonth: 'Изберете дати',
  showFeaturedEvents: 'Покажи специалните събития',
  hideFeaturedEvents: 'Скрий специалните събития',
  lfp: {
    noPartner: 'Все още нямате партньор в отбора на двойки?',
    whosInterested: 'Вижте кой се интересува',
    findAPartner: 'Намерете партньор за отбора на двойки',
    eligible: `Преди да добавите себе си към списъка или да се свържете с играч, уверете се, че отговаряте на критериите за участие в турнира със съотборника, с когото искате да се свържете.
    
    Ако добавите себе си към списъка или се свържете с потенциален съотборник на двойки, не сте задължени да се състезавате. <1>Открийте повече</1>`,
    registrationNotRequired: 'Вашето добавяне към списъка с играчи, търсещи партньор за отбора на Двойки, не поражда задължение за участие в това състезание.',
    findMore: 'Открийте повече',
    imLooking: 'Търся партньор',
    optOut: 'предавам се',
    contact: 'Съобщение',
    playersLFP: '{{ total }} играча търсят партньори',
    join: 'Присъединете се към списъка!',
    beFirst: 'Покажете на други играчи, които търсят партньори на двойки за този турнир, че се интересувате.',
    eligibilityCriteria: `Моля, проверете критериите за допустимост за този конкурс, преди да потърсите потенциален партньор.
    
    Вашето добавяне към списъка с играчи, търсещи партньор за отбора на Двойки, не поражда задължение за участие в това състезание. <1>Открийте повече</1>`,
    iAmLFP: 'Търся партньор',
  },
  goldNutritionCampaign2: `Това е събитие Sportya & Arena Tennis. 
  Регистрациите за жребий на двойки са налични онлайн на <1>Arena Tennis.</1> Играчите могат да се регистрират както на сингъл, така и на двойки.`,
  fmBanner: {
    desc: `Докато чакате да бъдат обявени нови събития във вашата страна, можете да играете приятелски мач с приятели или други играчи в Sportya`,
  },
  arenaTennis: `Това е събитие Sportya & Arena Tennis. Регистрациите за жребий на двойки са налични онлайн на <1>Arena Tennis</1>
  Играчите могат да се регистрират както на сингъл, така и на двойки.`,
};
