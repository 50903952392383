/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import Select from '../../../../components/Form/Select';
import Modal from '../../../../components/Modal/Modal';
import useModal from '../../../../components/Modal/useModal';
import AdvancedFilters from './AdvancedFilters';
import LoadImage from '../../../../components/common/LoadImage';
import { fetchAccountsLocations } from '../../reducers';
import { FiltersContainer } from '../../styles';
import { Button, P } from '../../../../components/Collection';

const currentYear = new Date().getFullYear();
const years = [];
for (let i = 2009; i <= currentYear; i++) {
  years.push({
    label: i,
    value: i,
  });
}
years.reverse();

const AccountsFilters = ({
  filters,
  setFilters,
  setReadableFilters,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('events');
  const { t: playerT } = useTranslation('player');
  const { t: commonT } = useTranslation('common');
  const { isShowing, toggle } = useModal();

  const { data: locations, status } = useSelector(state => state.accounts?.locations);
  const { data: { levels } } = useSelector(state => state.settings);
  const totalActiveFilters = Object.keys(filters)
    .filter(key => ['gender', 'minAge' || 'maxAge'].includes(key)).length;

  const handleLocations = () => {
    if (status === 'idle') {
      dispatch(fetchAccountsLocations({ filters }));
    }
  }

  const mapLocations = (data) => data?.map((value) => ({
    value: `${value.city}, ${value.country}`,
    label: value.city ? `${commonT(value.city)}, ${commonT(value.country)} (${value.total})` : commonT(value.name),
  }));

  const mapLevels = (data) => data?.map((value) => ({
    value: value.key,
    label: commonT(value.key),
  }));

  function handleFilters(data) {
    let newFilters = {};

    if (!data) {
      newFilters = {
        ...filters,
      };

      delete filters[this.name];
      delete newFilters[this.name];
    } else {
      newFilters = {
        ...filters,
        page: 1,
        [this.name]: data.value,
      };
    }
    setFilters(newFilters);
  }

  function handleModalFilters(data) {
    const modalFilters = {};
    Object.keys(data).forEach((item) => {
      if (['minAge', 'maxAge'].includes(item)) {
        if (data[item]) {
          modalFilters[item] = data[item];
        } else {
          delete filters[item];
        }
      } else if (!isEmpty(data[item])) {
        modalFilters[item] = [data[item]].join(',');
      } else {
        delete filters[item];
      }
    });

    const newFilters = {
      ...filters,
      page: 1,
      ...modalFilters,
    };

    setFilters(newFilters);
  }

  const resetFilters = () => {
    setFilters({});
    setReadableFilters(null);
  };

  const getFilters = {
    filterLocation: filters?.location?.split(','),
    get location() {
      if (this.filterLocation[0] === 'null') {
        return {
          value: `null,+${this.filterLocation[1]}`,
          label: commonT(`${this.filterLocation[1].replace(/ /g, '')}-All`),
        };
      }
      return {
        value: `${this.filterLocation[0]}, ${this.filterLocation[1]}`,
        label: `${commonT(this.filterLocation[0])}, ${commonT(this.filterLocation[1])}`,
      };
    },

    get gameLevelForSingles() {
      return {
        value: filters.gameLevel?.singles,
        label: filters.gameLevel?.singles,
      };
    },
    get gameLevelForDoubles() {
      return {
        value: filters.gameLevel?.doubles,
        label: filters.gameLevel?.doubles,
      };
    },
  };

  return (
    <FiltersContainer>
      <Button
        small
        outline
        noBorder
        noImgMargin
        minWidth="116px"
        margin="0 0 0 30px"
        padding="15px 18px"
        onClick={() => toggle()}
      >
        <img src={LoadImage('filter.svg')}  alt={''} />
        <P bold margin="0 0 0 10px">{t('filter_all')}</P>
        {totalActiveFilters > 0 && (
          <P small bold color="white" className="filters-amount">{totalActiveFilters}</P>)}
      </Button>
      <Select
        key="gameLevel[singles]"
        name="gameLevel[singles]"
        placeholder={playerT('singleLevel')}
        onChange={handleFilters}
        options={mapLevels(levels)}
        label={commonT('level')}
        {...(filters.gameLevel?.singles && { defaultValue: getFilters.gameLevelForSingles })}
        isClearable
      />

      <Select
        key="gameLevel[doubles]"
        name="gameLevel[doubles]"
        placeholder={playerT('doubleLevel')}
        onChange={handleFilters}
        options={mapLevels(levels)}
        label={commonT('level')}
        {...(filters.gameLevel?.doubles && { defaultValue: getFilters.gameLevelForDoubles })}
        isClearable
      />

      <Select
        key="location"
        name="location"
        placeholder={commonT('location')}
        onChange={handleFilters}
        options={mapLocations(locations)}
        label={commonT('location')}
        {...(filters.location && { defaultValue: getFilters.location })}
        isClearable
        onFocus={handleLocations}
        isLoading={status === 'loading'}
      />

      <Modal
        type2
        header
        title={t('filters')}
        noborder
        nobackground
        isShowing={isShowing}
        hide={toggle}
      >
        <AdvancedFilters {...{handleModalFilters, toggle, filters, resetFilters}}
        />
      </Modal>
    </FiltersContainer>
  );
};

export default AccountsFilters;
