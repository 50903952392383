export default {
  title: 'Achiziționează un Cont Premium',
  subtitle: 'Pentru a beneficia de toate funcționalitățile pe care Sportya le oferă și pentru a obține taxe reduse la înscrierea în turnee, alege contul <1>Premium</1>',
  seoTitle: 'Achiziționează un Cont Premium | Sportya',

  benefits: {
    title: 'Beneficii incluse pentru toate conturile',
    1: 'Profil personal, cu detalierea rezultatelor din meciuri, a clasamentului și statistici',
    2: 'Clasament național și internațional pentru jucătorii de tenis amatori',
    3: 'Discount 10% TENNIS WAREHOUSE EUROPE, cel mai mare retailer specializat, din Europa, pentru toate achizițiile',
    4: 'Standard de joc pentru toate evenimentele, incluzând mingi noi pentru fiecare meci de simplu și produse promoționale (depind de eveniment / nu sunt garantate)',
    5: 'Meciuri amicale cu orice membru Sportya înregistrat în sistem',
    6: 'Sistem de mesagerie personală, sistem de notificări',
    7: 'Acces la statistici bazate pe meciuri, rezultate din evenimente și comparații pentru toți membrii Sportya',
    8: 'Suport tehnic (pe e-mail sau telefon)',
  },

  info: {
    feeReduction: '1. Discount-urile aplicate taxelor de înscriere pentru Conturile Premium depind de evenimente și de țară. O reducere tipică este între 3 și 5 EUR și se aplică automat în momentul înscrierii. Se pot aplica reduceri suplimentare pentru anumite evenimente din calendar, cum ar fi evenimente cu format special, turnee de categorie Platinum, turnee internaționale etc.',
  },

  expirationDate: 'Valabilitate {{value}} Luni',
  activePlan: 'Planul tău <1>Premium</1> este activ până pe <3>{{expDate}}</3>',

  planConfirmation: {
    bestValue: 'Recomandat',
    noExpiration: 'Fară Dată de Valabilitate',
    currentPlan: 'Planul Curent',
    included: 'Inclus',
    extend: 'Prelungește',
    upgrade: 'Cumpără',
    learnMore: 'Află mai multe',
    confirmFinalPayment: 'Confirmă plata finală pentru <1>Premium {{period}}</1>',
    validity: 'Valabilitate',
    holder: 'Titular',
    totalOrder: 'Total',
    confirmPay: 'Confirmă și plătește',
    availableBalance: 'Fonduri Disponibile',
    redirected: 'Veți fi redirecționat către',
    redirecting: 'Redirecționare către Stripe...',
    redirectingClean: 'Redirecționare către',
  },

  bottomSection: {
    findMore: 'Află Mai Multe',
    remindMe: 'Amintește-mi Mai Târziu',
  },

  plans: {
    free: {
      title: 'Cont Gratuit',
      1: 'Acces COMPLET la Meciurile Amicale cu prietenii sau alți jucători',
      2: 'Preț ÎNTREG pentru înscrierea la evenimente',
      3: 'Acces înscrieri la Turnee Platinum, Turneele Campionilor, Turnee Masters, Turnee Internaționale și Turnee cu caracter special',
      expDate: 'Fară Dată de Valabilitate',
      price: 'Gratuit',
    },

    summer: {
      title: 'Cont de Vară',
      1: 'Acces COMPLET la Meciurile Amicale cu prietenii sau alți jucători',
      2: 'Acces înscrieri la Turnee Platinum, Turneele Campionilor, Turnee Masters, Turnee Internaționale și Turnee cu caracter special',
      3: 'Preț REDUS pentru înscrierea la evenimente',
      info: 'The Summer Account is dedicated to the player very involved in the circuit who wants to improve his game and ranking constantly and to participate in as many tournaments as possible during the summer , both PLATINUM and International tournaments and special tournaments, such as Champions Tournaments and Masters Tournaments. The Summer Account offers you access to all the facilities and benefits of the online platform. The registration for the tournaments implies the payment of the online reservation fee, at a reduced price, at the moment of the tournament registration as well as of the participation fee, to the organizer, at the place of the competition.',
      expDate: '6 Luni de Valabilitate',
      timePeriod: '1 Apr 2021 - 31 Oct 2021',
    },

    premium: {
        title: 'Cont Premium',
        1: 'Acces COMPLET la Meciurile Amicale cu prietenii sau alți jucători',
        2: 'Preț REDUS pentru înscrierea la evenimente',
        3: 'Acces înscrieri la Turnee Platinum, Turneele Campionilor, Turnee Masters, Turnee Internaționale și Turnee cu caracter special',
        info: 'The Premium Account is dedicated to the player who wants to participate in as many tournaments as possible throughout the year , both the PLATINUM category and International tournaments and special tournaments, such as Champions Tournaments and Masters Tournaments. The Premium account gives you access to all the facilities and benefits of the online platform. The registration for the tournaments implies the payment of the online reservation fee, at a reduced price, at the moment of the tournament registration as well as of the participation fee, to the organizer, at the place of the competition.',
        expDate: '12 Luni de Valabilitate',
        timePeriod: '1 Ian 2021 - 31 Dec 2021',
    },

    overlay: {
      free: {
        1: 'Acces înscrieri la Turnee Platinum, Turneele Campionilor, Turnee Masters, Turnee Internaționale și Turnee cu caracter special',
        2: 'Preț ÎNTREG pentru înscrierea la evenimente',
      },

      summer: {
        1: 'Acces înscrieri la Turnee Platinum, Turneele Campionilor, Turnee Masters, Turnee Internaționale și Turnee cu caracter special',
        2: 'Preț REDUS pentru înscrierea la evenimente',
      },

      premium: {
        1: 'Acces înscrieri la Turnee Platinum, Turneele Campionilor, Turnee Masters, Turnee Internaționale și Turnee cu caracter special',
        2: 'DISCOUNTS for online registration fees  + tournament participation fees, paid to the organizer',
      },
    },
  },
};
