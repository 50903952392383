export default {
  title: 'Намери играч',
  singleLevel: 'Ниво Сингъл',
  seoTitle: 'Играчи | Sportya',
  playerProfileSeoTitle: '{{име}} {{фамилия}} - Играчи | Sportya',
  allPlayers_tennis: 'Всички тенисисти',
  allPlayers_padel: 'Всички играчи на падел',
  doubleLevel: 'Ниво Двойки',
  playerHand: 'Дясна ръка',
  availabilityFriendly: 'Възможност за приятелски мачове',
  availabilityFriendlyNotSet: 'Възможността за приятелски мачове все още не е зададена',
  activity: 'Дейност',
  titles: 'Купи',
  leaderboards: 'Класирания',
  levelNotSet: 'Нивото не е зададено',
  singles: 'Сингъл',
  doubles: 'Двойки',
  points: 'Точки',
  stats: 'Статистики',
  pickYear: 'Година',
  allActivities: 'Дейности',
  level: 'Ниво',
  round: 'Кръг',
  oponent: 'Противник',
  eloRating: 'Рейтинг ELO',
  leaderboard: 'Класиране',
  score: 'Резултат',
  wL: 'W/L',
  winStatusShorthand: 'W',
  loseStatusShorthand: 'L',
  woStatusShorthand: 'W.O.',
  eloDescription: 'Ранг преди турнира:',
  playerFound: '<0>{{ totalResults }} играч</0> намерен в <2>{{ местоположение }}</2>',
  playersFound: '<0>{{ totalResults }} играчи</0> намерени в <2>{{ местоположение }}</2>',
  champion: 'Шампион',
  finalist: 'Финалист',
  week: 'Седмица',
  calculationDate: 'Дата на изчисляване',
  rank: 'Ранг',
  move: 'Действие',
  bestResults: 'Най-добрите резултати',
  pointsSummary: 'Обобщен резултат',
  playerRank: 'Ранг играч',
  totalPoints: 'Общо точки',
  competitionPoints: 'Точки от състезанието',
  friendlyMatchPoints: 'Точки от Приятелски мачове',
  sendMessage: 'Send Message',
  myEvents: 'Моите събития',
  setYourGameLevel: 'Задайте своето ниво на игра',
  setYourAvailability: 'Задайте вашата възможност за игра',
  mySettings: 'SМоите настройки',
  gameLevel: 'Ниво на игра',
  basePoints: 'Първоначални точки',
  penaltyPoints: 'Наказателни точки',
  newestPlayers_tennis: 'Последни играчи',
  newestPlayers_padel: 'Последни играчи',
  player: 'Играч',
  friendlyMatches: 'Приятелски мачове',
  registrationDate: 'Дата на Регистрация',
  findAPlayer_tennis: 'Намерете играч',
  findAPlayer_padel: 'Намерете играч',
  joined: 'Член от',
  upgradedAt: 'Промяна на нивото до',

  playerFoundNc: '<0>{{ totalResults }} играч</0> намерени',
  playersFoundNc: '<0>{{ totalResults }} играчи</0> намерени',
  best7results: 'Най-добри 7 резултата',

};
