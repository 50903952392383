import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import dateFormat from 'dateformat';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { MatchPlayedContainer } from './styles';

import { Contain, P } from '../../../../../../components/Collection';
import LoadImage from '../../../../../../components/common/LoadImage';
import Select from '../../../../../../components/Form/Select';
import Radio from '../../../../../../components/Form/VanillaRadio';
import YoureTheCaptain from '../../../../components/MatchFormSearchBar/components/PlayerOption';
import { RankedIcon } from '../../../../Matches/styles';
import { InfoIcon } from '../../../../styles';

import {
  AverageLevel,
  ChangeCountry,
  CheckboxContainer,
  DateAndTimeContainer,
  DateLabel,
  MatchFormButtons,
  MatchFormButtonsContainer,
  MatchFormTypesTitle,
  MatchOptionIcon,
  MatchOptions,
  MatchOptionTitle,
  MatchRankType,
  MatchRankTypeContainer,
  SelectedDateValue,
  StartDateContainer,
  Team,
  TeamsContainer,
  TeamTitle,
} from '../MatchInvitation/styles';

import {
  addMatchToList,
  changeSummaryTotals,
  createFriendlyMatch,
  editFriendlyMatch,
  replaceMatchInList,
} from '../../../../reducers';

import PlayerScores from './components/PlayerScore';
import SearchBar from '../../../../components/MatchFormSearchBar';
import ToggleVisible from '../../../../../../components/common/ToggleVisible';
import { MatchFormClearResults } from '../../../../components/MatchFormSearchBar/styles';
import RangeDatePicker from '../../../../../Events/components/EventFilters/RangeDatePicker';
import SelectGoogleAutocomplete from '../../../../../../components/Form/SelectGoogleAutocomplete';
import getCountryIsoCode from '../../../../../../components/common/getCountryIsoCode';
import { store as socketStore } from '../../../../../../components/Socket/store';
import { Country, Flag } from '../../../../../Auth/Register/styles';
import mixpanel from '../../../../../../mixpanel';
import { formatMatchId, getLocalDate, getWinner } from '../../../../helpers';
import ConfirmModal from '../components/ConfirmModal';
import parseScore from '../../../../helpers/parseScore';
import TooltipRankedMatches from '../MatchInvitation/components/rankedTooltip';
import CancelModal from '../components/CancelModal';
import SetRatingModal from '../../../../../../components/PlayerRating/components/SetRatingModal';
import handleNextPlayerRating from '../../../../../../components/PlayerRating/helpers/handleNextPlayerRating';

export const ErrorHandler = createContext(null);
const MatchPlayedForm = ({ match, edit, cancelModalState }) => {
  const routerHistory = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { t } = useTranslation('friendlyMatches');
  const { t: commonT } = useTranslation('common');
  const { t: playerT } = useTranslation('player');

  const {
    visible: isCancelModalVisible,
    handler: setIsCancelModalVisible,
  } = cancelModalState || {};

  const {
    isComponentVisible: isConfirmModalVisible,
    setIsComponentVisible: setIsConfirmModalVisible,
  } = ToggleVisible(false);

  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);
  const { accountInfo } = useSelector(state => state.session);

  const [ratePlayerData, setRatePlayerData] = useState({
    playerToRate: null,
    userPositionIndex: 0,
    thankYouModal: false,
    userId: accountInfo?.userId,
    matchInfo: null,
    handleSuccess: null,
  });

  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  const isRankedMatch = match?.ranked === false ? 'unranked' : 'ranked';
  const [rankedMatch, setRankedMatch] = useState(isRankedMatch);
  const isRanked = rankedMatch === 'ranked';

  const matchFormat = [
    { label: t('singleSet'), value: 'singleSet' },
    { label: t('2setsWithSuperTieBreak'), value: '2setsWithSuperTieBreak' },
    { label: t('2setsOutOf3'), value: '2setsOutOf3' },
  ];

  const getMatchFormat = valueToFind => matchFormat.find(({ value }) => value === valueToFind);

  const isTypeSelected = type => type === rankedMatch;

  const [scoreError, setScoreError] = useState(false);
  const [location, setLocation] = useState(match && match?.location);
  const [gameType, setGameType] = useState(match?.gameType || false);
  const [partner, setPartner] = useState(match?.playerPartner);
  const [opponent, setOpponent] = useState(match?.opponent);
  const [opponentPartner, setOpponentPartner] = useState(match?.opponentPartner);
  const [submittedForm, setSubmittedForm] = useState(false);
  const player = match?.player;

  const [isChangeCountryEnabled, enableChangeCountry] = useState(false);
  const [selectedCountry, selectCountry] = useState(null);

  const [selectedMatchFormat, selectMatchFormat] = useState(getMatchFormat(match?.format || '2setsOutOf3'));

  const dateFromMatch = match?.startDate && new Date(match?.startDate);
  const dayFromMatch = dateFromMatch?.getUTCDate();
  const monthFromMatch = dateFromMatch?.getUTCMonth() + 1;
  const yearFromMatch = dateFromMatch?.getUTCFullYear();

  const [selectedDate, selectDate] = useState(
    dateFromMatch ? { day: dayFromMatch, month: monthFromMatch, year: yearFromMatch } : null,
  );
  const { eligibleCountries } = useSelector(state => state.settings.data);

  const [isFormDisabled, disableForm] = useState(true);
  const [winner, declareWinner] = useState(null);
  const [winnerError, setWinnerError] = useState(winner === 'error');

  const [scoreForm, setScoreForm] = useState({
    player: {
      score: [
        ...match?.player?.score ? [...match?.player?.score] : [],
      ],
      ...match?.player?.type && { type: match?.player?.type },
    },
    opponent: {
      score: [
        ...match?.opponent?.score ? [...match?.opponent?.score] : [],
      ],
      ...match?.opponent?.type && { type: match?.opponent?.type },
    },
  });

  const currentDate = new Date();
  const dateToCheck = new Date(selectedDate?.year, (selectedDate?.month - 1), selectedDate?.day);
  const differenceInMs = currentDate.getTime() - dateToCheck.getTime();
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
  const hasTimeLimitError = ((differenceInDays > 6) && isRanked) && !edit;

  const isDoubles = gameType === 'doubles';
  const isSingles = gameType === 'singles';

  const myLevel = accountInfo?.gameLevel?.[gameType];

  const requiredLevelSingles = () => {
    const opponentLevel = opponent?.gameLevel?.singles;
    const minRequired = myLevel - 1;
    const maxRequired = myLevel + 1;
    return (opponent && isRanked) ? (opponentLevel >= minRequired && opponentLevel <= maxRequired) : true;
  };

  const requiredLevelDoubles = () => {
    const minRequired = (myLevel + partner?.gameLevel?.doubles) / 2 - 1;
    const maxRequired = (myLevel + partner?.gameLevel?.doubles) / 2 + 1;
    const opponentTeam = (opponent?.gameLevel?.doubles + opponentPartner?.gameLevel?.doubles) / 2;
    return (partner && opponent && opponentPartner && isRanked)
      ? (opponentTeam >= minRequired && opponentTeam <= maxRequired)
      : true;
  };

  const requiredLevelMin = () => (isDoubles ? (myLevel + partner?.gameLevel?.doubles) / 2 - 1 : myLevel - 1);
  const requiredLevelMax = () => (isDoubles ? (myLevel + partner?.gameLevel?.doubles) / 2 + 1 : myLevel + 1);
  const requiredLevelForFriendlyMatches = () => (isDoubles ? requiredLevelDoubles() : requiredLevelSingles());

  useEffect(() => {
    if (
      location
      && selectedDate
      && (scoreForm.player.score.length > 0 || scoreForm.opponent.score.length > 0)
      && !hasTimeLimitError
      && !scoreError
      && !winnerError
      && requiredLevelForFriendlyMatches()
    ) {
      if (gameType === 'doubles' && partner && opponentPartner && opponent) {
        return disableForm(false);
      }

      if (gameType === 'singles' && opponent) {
        return disableForm(false);
      }
    } else {
      return disableForm(true);
    }
  }, [
    gameType,
    partner,
    opponent,
    opponentPartner,
    selectedMatchFormat,
    rankedMatch,
    location,
    selectedDate,
    scoreForm,
    hasTimeLimitError,
    scoreError,
    winnerError,
    requiredLevelForFriendlyMatches,
  ]);

  const requiredLevel = {
    singles: {
      min: requiredLevelSingles().min,
      max: requiredLevelSingles().max,
    },
    doubles: {
      average: (myLevel + partner?.gameLevel?.doubles) / 2,
      opponentAverage: (opponent?.gameLevel?.doubles + opponentPartner?.gameLevel?.doubles) / 2,
      min: requiredLevelDoubles().min,
      max: requiredLevelDoubles().max,
    },
    min: requiredLevelMin(),
    max: requiredLevelMax(),
    forFriendlyMatches: requiredLevelForFriendlyMatches,
  };

  const dispatchForm = () => {
    declareWinner(getWinner(scoreForm));
    if (winner === 'error') {
      setWinnerError(true);
      return null;
    }
    setWinnerError(false);
    setSubmittedForm(true);

    const parsedScore = parseScore(scoreForm);

    const payload = {
      player: {
        ...accountInfo,
        score: [
          ...parsedScore?.player?.score,
        ],
        ...scoreForm?.player?.type && { type: scoreForm?.player?.type, reason: 'ABANDONED' },
      },
      playerPartner: partner,
      opponent: {
        ...opponent,
        score: [
          ...parsedScore?.opponent?.score,
        ],
        ...scoreForm?.opponent?.type && { type: scoreForm?.opponent?.type, reason: 'ABANDONED' },
      },
      opponentPartner,
      ranked: isRanked,
      gameType,
      startDate: new Date(
        selectedDate?.year,
        (selectedDate?.month - 1),
        selectedDate?.day,
        (new Date()).getTimezoneOffset() / -60,
      ),
      format: selectedMatchFormat?.value || false,
      location: { ...location?.value || location },
      timezoneOffset: (new Date()).getTimezoneOffset(),
    };

    return dispatch(edit ? editFriendlyMatch({ payload, id: match?._id }) : createFriendlyMatch({ payload }))
      .then(() => dispatch(changeSummaryTotals({ matches: 1, requests: 0 })));
  };

  const formTypes = [
    { title: t('rankedMatch'), desc: t('generatesPoints'), key: 'ranked' },
    { title: t('unrankedMatch'), desc: t('doesntGeneratePoints'), key: 'unranked' },
  ];

  const gameTypeOptions = [
    { label: playerT('singles'), value: 'singles' },
    { label: playerT('doubles'), value: 'doubles' },
  ];

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      if (success) {
        const { match: responseMatch } = data || {};
        const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const matchStartDate = getLocalDate(responseMatch.startDate, responseMatch.timezone);
        switch (message) {
        case 'SUCCESSFULLY_CREATED_FRIENDLY_MATCH':
          dispatch(addMatchToList({ match: responseMatch, list: 'matches' }));
          setIsConfirmModalVisible(true);

          // rating user
          handleNextPlayerRating({
            ratePlayerData: {
              ...ratePlayerData,
              matchInfo: responseMatch,
            },
            setRatePlayerData,
          });

          mixpanel.track('Friendly Matches MATCH CREATED', {
            sp_asset_type: 'PLAYED MATCH',
            sp_match_type: responseMatch.ranked ? 'Ranked' : 'Unranked',
            sp_game_type: responseMatch.gameType,
            sp_owner_level_single: responseMatch.player.gameLevel?.singles,
            sp_owner_level_double: responseMatch.player.gameLevel?.doubles,
            sp_start_date_day_of_week: weekday[matchStartDate.getDay()],
            sp_start_date: matchStartDate.toString(),
            sp_game_location_city: responseMatch.location?.city,
            sp_game_location_country: responseMatch.location?.country,
            sp_game_format: responseMatch.format,
            sp_match_id: formatMatchId(responseMatch.matchId),
          });
          break;
        case 'SUCCESSFULLY_UPDATED_FRIENDLY_MATCH':
          dispatch(replaceMatchInList({ match: responseMatch, list: 'matches' }));
          addToast(commonT(message), {
            appearance: 'success',
            autoDismiss: true,
          });
          mixpanel.track('Friendly Matches EDIT MATCH', {
            sp_asset_type: 'PLAYED MATCH',
            sp_match_type: responseMatch.ranked ? 'Ranked' : 'Unranked',
            sp_game_type: responseMatch.gameType,
            sp_game_location_city: responseMatch.location?.city,
            sp_game_location_country: responseMatch.location?.country,
            sp_game_format: responseMatch.format,
            sp_match_id: formatMatchId(responseMatch.matchId),
          });

          routerHistory.push('/friendly-matches/available');
          break;
        default:
        }
      } else {
        addToast(commonT(message), {
          appearance: 'error',
          autoDismiss: true,
        });
      }

      setSubmittedForm(false);
    };

    if (socket) {
      socket.removeAllListeners('friendly-matches-response');
      socket.on('friendly-matches-response', listenForResponse);

      return () => socket.removeAllListeners('friendly-matches-response');
    }
  }, [socketClientId]);

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      if (['SUCCESSFULLY_RATED_PLAYER', 'COULD_NOT_RATE_PLAYER'].includes(message)) {
        addToast(commonT(message), {
          appearance: success ? 'success' : 'error',
          autoDismiss: true,
        });
      }
    };

    if (socket) {
      socket.removeAllListeners('accounts-response');
      socket.on('accounts-response', listenForResponse);

      return () => socket.removeAllListeners('accounts-response');
    }
  }, [socketClientId]);

  const countries = eligibleCountries?.slice().sort((a, b) => a.name.localeCompare(b.name)).map(country => ({
    value: (country.key).toUpperCase(),
    label: (
      <Country>
        <Flag src={LoadImage(`flags/${country.key}.svg`)} />
        {' '}
        <P small bold>{commonT(`countriesByKey.${country.key}`)}</P>
      </Country>
    ),
  }));

  const handleCheckboxes = (isPlayer) => {
    const newScoreForm = { ...scoreForm };
    const playerType = isPlayer ? 'player' : 'opponent';
    const hasBye = newScoreForm[playerType]?.type;

    if (isPlayer) {
      delete newScoreForm.opponent.type;
    }

    if (!isPlayer) {
      delete newScoreForm.player.type;
    }

    if (hasBye) {
      delete newScoreForm[playerType].type;
    } else {
      newScoreForm[playerType].type = 'BYE';
    }

    declareWinner(getWinner(scoreForm));
    return setScoreForm(newScoreForm);
  };

  return (
    <>
      <MatchPlayedContainer>
        <P small margin="20px 0 0 0" textAlign="center">
          <Trans ns="friendlyMatches" i18nKey="timeRequiredForPlayedMatch">
            Match invitations will be pending acceptance from the invited players
          </Trans>
        </P>
        <MatchFormTypesTitle bold>
          <Trans ns="friendlyMatches" i18nKey="selectMatchType">
            Select the Match Type
          </Trans>
        </MatchFormTypesTitle>
        <MatchRankTypeContainer>
          {formTypes.map((formType, key) => (
            <MatchRankType
              key={key}
              isSelected={isTypeSelected(formType.key)}
              onClick={() => {
                selectMatchFormat(getMatchFormat('2setsOutOf3'));
                setRankedMatch(formType.key);
              }}
            >
              <CheckboxContainer>
                <Radio
                  onChange={() => { }}
                  checked={isTypeSelected(formType.key)}
                  label={formType.title}
                />
                {formType.key === 'ranked' && (
                  <RankedIcon src={LoadImage('friendly-matches/matches/ranked-match.svg')} alt="Ranked Match" />)}
              </CheckboxContainer>
              <Contain align="center">
                <P xSmall>
                  {formType.desc}
                </P>
                {formType.key === 'ranked' && (
                  <TooltipRankedMatches />
                )}
              </Contain>
            </MatchRankType>
          ))}
        </MatchRankTypeContainer>

        <MatchOptions>
          <MatchOptionTitle>
            <MatchOptionIcon src={LoadImage('friendly-matches/match-form/tennis-ball-icon.svg')} alt="Option" />
            <P bold>
              <Trans ns="friendlyMatches" i18nKey="matchDetails">
                Match Details
              </Trans>
            </P>
          </MatchOptionTitle>

          <Select
            height="50px"
            name="gameType"
            isClearable
            label={t('gameType')}
            isSearchable={false}
            placeholder={t('gameType')}
            onChange={(selectedGameType) => { setGameType(selectedGameType?.value); }}
            options={gameTypeOptions}
            labelOn={gameType}
            alignLabel
            higher
            lowInput
            {...match && {
              defaultValue: {
                ...match?.gameType === 'singles'
                  ? { label: playerT('singles'), value: 'singles' }
                  : { label: playerT('doubles'), value: 'doubles' },
              },
            }}
          />

          {isDoubles && (
            <TeamsContainer>
              <Team>
                <TeamTitle small bold>
                  <Trans ns="friendlyMatches" i18nKey="yourTeam">
                    Your Team
                  </Trans>
                  <AverageLevel type={gameType} hasTenth={requiredLevel.doubles.average % 1 === 0.5}>
                    {requiredLevel.doubles.average || '?'}
                  </AverageLevel>
                </TeamTitle>

                <YoureTheCaptain
                  isDoubles={isDoubles}
                  player={accountInfo}
                  playerInputPlaceholder
                  isMe
                  handler={() => null}
                />

                <SearchBar
                  isPartner
                  isDoubles={isDoubles}
                  handler={setPartner}
                  selectedOption={partner}
                  hasError={!requiredLevel.forFriendlyMatches()}
                  match={match}
                  teams={{ playerPartner: partner, opponent, opponentPartner }}
                />
              </Team>

              <Team>
                <TeamTitle small bold>
                  <Trans ns="friendlyMatches" i18nKey="opponentTeam">
                    Opponent Team
                  </Trans>
                  <AverageLevel type={gameType} hasTenth={requiredLevel.doubles.opponentAverage % 1 === 0.5}>
                    {requiredLevel.doubles.opponentAverage || '?'}
                  </AverageLevel>
                </TeamTitle>

                <SearchBar
                  isDoubles={isDoubles}
                  isOpponent={1}
                  handler={setOpponent}
                  selectedOption={opponent}
                  hasError={!requiredLevel.forFriendlyMatches()}
                  match={match}
                  teams={{ playerPartner: partner, opponent, opponentPartner }}
                />

                <SearchBar
                  isDoubles={isDoubles}
                  isOpponent={2}
                  handler={setOpponentPartner}
                  selectedOption={opponentPartner}
                  hasError={!requiredLevel.forFriendlyMatches()}
                  bottomMargin
                  match={match}
                  teams={{ playerPartner: partner, opponent, opponentPartner }}
                />
              </Team>
            </TeamsContainer>
          )}

          {isSingles && (
            <>
              <YoureTheCaptain
                isDoubles={isDoubles}
                player={accountInfo}
                playerInputPlaceholder
                isMe
                handler={() => null}
              />
              <SearchBar
                isDoubles={isDoubles}
                handler={setOpponent}
                selectedOption={opponent}
                hasError={!requiredLevel.forFriendlyMatches()}
                match={match}
              />
            </>
          )}

          {!requiredLevel.forFriendlyMatches() && (
            <P xSmall textAlign="center" color="error" margin="0 0 20px 0">
              <Trans ns="friendlyMatches" i18nKey="playerMinMaxLevelError">
                Opponent level not compatible for your Ranked Match. Please select a player within the Level Range
                {' '}
                {{ min: requiredLevel.min }}
                -
                {{ max: requiredLevel.max }}
                , or switch to Unranked Match to continue playing with the selected player.
              </Trans>
            </P>
          )}

          <Contain height="10px" />

          <Select
            name="matchFormat"
            height="50px"
            placeholder={t('matchFormat')}
            onChange={(e) => selectMatchFormat(getMatchFormat(e?.value))}
            options={matchFormat.filter(({ value }) => (isRanked && value === '2setsOutOf3') || !isRanked)}
            label={t('matchFormat')}
            isClearable
            isSearchable={false}
            alignLabel
            higher
            lowInput
            {...selectedMatchFormat && {
              value: selectedMatchFormat,
            }}
          />
        </MatchOptions>

        <PlayerScores
          setScoreForm={setScoreForm}
          handleCheckboxes={handleCheckboxes}
          scoreForm={scoreForm}
          isDoubles={isDoubles}
          playerPartner={partner}
          player={player}
          opponent={opponent}
          opponentPartner={opponentPartner}
          winner={winner}
          winnerError={winnerError}
          declareWinner={declareWinner}
          setWinnerError={setWinnerError}
          alreadyHasScore={!!match}
          setScoreError={setScoreError}
          scoreError={scoreError}
        />

        {!!match && (
          <Contain>
            <Contain align="start" padding="7px 6px 0 0">
              <InfoIcon src={LoadImage('friendly-matches/info.svg')} alt="Find out more" width="15px" height="15px" />
            </Contain>
            <P small>
              <Trans ns="friendlyMatches" i18nKey="scoresChangeInfo">
                Please be aware that changing any player in the match will reset any existing
                scores, therefore scores will have to be submitted again and confirmed for validation.
              </Trans>
            </P>
          </Contain>
        )}

        <MatchOptions>
          <MatchOptionTitle>
            <MatchOptionIcon src={LoadImage('friendly-matches/match-form/time-icon.svg')} alt="Option" />
            <P bold>
              <Trans ns="accounts" i18nKey="wallet.date">
                Date
              </Trans>
            </P>
          </MatchOptionTitle>

          <DateAndTimeContainer>
            <StartDateContainer
              {...!match && { onClick: () => setIsComponentVisible(!isComponentVisible) }}
              hasMargin
              isDisabled={!!match}
            >
              {selectedDate
                && (
                  <DateLabel>
                    <Trans ns="events" i18nKey="startDate">
                      Start Date
                    </Trans>
                  </DateLabel>
                )}

              {selectedDate
                && (
                  <SelectedDateValue>
                    {dateFormat(
                      new Date(selectedDate?.year, (selectedDate?.month - 1), selectedDate?.day),
                      'd mmm yyyy',
                    )}
                  </SelectedDateValue>
                )}

              {!selectedDate
                && (
                  <P bold small>
                    <Trans ns="events" i18nKey="startDate">
                      Start Date
                    </Trans>
                  </P>
                )}

              {selectedDate && !match && (
                <MatchFormClearResults
                  {...!match && { onClick: () => selectDate(null) }}
                  isOnFriendlyMatchesCreateMatches
                >
                  {/* eslint-disable-next-line max-len */}
                  <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" /></svg>
                </MatchFormClearResults>
              )}
            </StartDateContainer>

            {isComponentVisible
              && (
                <RangeDatePicker
                  handler={() => setIsComponentVisible(!isComponentVisible)}
                  calendarRef={ref}
                  firstSelect={selectedDate}
                  setFirstSelect={selectDate}
                  isRankedMatch={isRanked}
                  displaySingleCalendar
                  doNotDisplayOptions
                  isOnFriendlyMatchesCreateMatches
                  onlyPastDates
                />
              )}
          </DateAndTimeContainer>

          {hasTimeLimitError && (
            <P color="error" xSmall>
              <Trans ns="friendlyMatches" i18nKey="hasTimeLimitError">
                Sorry, but you cannot add a played Ranked match older than 5 days. The system only
                allows played Ranked matches to be added within 5 days of the actual match date.
                You can add this match as an Unranked match without points instead if you still
                want to add it to your match history.
              </Trans>
            </P>
          )}
        </MatchOptions>

        <MatchOptions>
          <MatchOptionTitle>
            <MatchOptionIcon src={LoadImage('friendly-matches/match-form/location-icon.svg')} alt="Option" />
            <P bold>
              <Trans ns="common" i18nKey="location">
                Location
              </Trans>
            </P>
          </MatchOptionTitle>

          <P small margin="0 0 10px 0">
            <Trans ns="friendlyMatches" i18nKey="countrySelect">
              {{ country: accountInfo?.location?.country }}
              {' '}
              <ChangeCountry onClick={() => enableChangeCountry(true)}>Change Country</ChangeCountry>
            </Trans>
          </P>

          {isChangeCountryEnabled && (
            <Select
              name="country"
              placeholder={commonT('country')}
              label={commonT('country')}
              options={countries}
              isSearchable={false}
              onChange={(country) => selectCountry(country.value)}
              height="50px"
              higher
            />
          )}

          <SelectGoogleAutocomplete
            name="location"
            autocompletionRequest={{
              componentRestrictions: {
                country: [selectedCountry || getCountryIsoCode(accountInfo?.location?.country).countryCode],
              },
            }}
            minLengthAutocomplete={3}
            hook={(e) => setLocation(e?.value)}
            placeholder={t('clubOrAddress')}
            label={commonT('location')}
            isClearable
            {...match && location && {
              defaultValue: { label: location?.name, value: location },
            }}
          />
        </MatchOptions>

        <MatchFormButtonsContainer>
          <MatchFormButtons outline onClick={() => setIsCancelModalVisible(!isCancelModalVisible)} second>
            <Trans ns="friendlyMatches" i18nKey="cancel">
              Cancel
            </Trans>
          </MatchFormButtons>
          <MatchFormButtons
            disabled={isFormDisabled || submittedForm}
            onClick={() => dispatchForm()}
            first
          >
            <Trans ns="friendlyMatches" i18nKey={edit ? 'updateMatch' : 'saveMatch'}>
              {edit ? 'updateMatch' : 'saveMatch'}
            </Trans>
          </MatchFormButtons>
        </MatchFormButtonsContainer>
      </MatchPlayedContainer>
      {isConfirmModalVisible && (
        <ConfirmModal
          played
          handler={() => {
            setIsConfirmModalVisible(false);

            if (!ratePlayerData?.playerToRate) {
              return routerHistory.push('/friendly-matches/available');
            }
          }}
          match={{
            opponent,
            opponentPartner,
            playerPartner: partner,
            matchFormat: selectedMatchFormat?.value,
          }}
        />
      )}

      {ratePlayerData?.playerToRate && !isConfirmModalVisible && (
        <SetRatingModal
          skippable
          user={ratePlayerData?.playerToRate}
          ratingInfo={ratePlayerData?.playerToRate?.ratingInfo}
          matchId={ratePlayerData?.matchInfo?._id}
          gameType={ratePlayerData?.matchInfo?.gameType}
          userPositionIndex={ratePlayerData?.userPositionIndex}
          onRequestClose={() => routerHistory.push('/friendly-matches/available')}
          onSubmit={(v) => handleNextPlayerRating({
            ratePlayerData,
            setRatePlayerData,
            handleSuccess: () => routerHistory.push('/friendly-matches/available'),
            skipThankYouModal: !!v?.skipThankYouModal,
          })}
          thankYouModal={ratePlayerData?.thankYouModal}
          mixpanelData={{
            source: 'Post-Match',
            matchType: ratePlayerData?.matchInfo?.ranked ? 'Ranked' : 'Unranked',
            assetType: 'PLAYED MATCH',
          }}
        />
      )}

      {isCancelModalVisible && (
        <CancelModal
          cancelHandler={() => setIsCancelModalVisible(!isCancelModalVisible)}
        />
      )}
    </>
  );
};

export default MatchPlayedForm;
