export default {
  SOMETHING_WENT_WRONG: "Tak ... a je to VENKU! Něco se nepovedlo. Musíme na tom zapracovat, tak nám prosím pomozte a napište nám, co se stalo, na hq@sportya.net.",
  CHANGE_PASSWORD_FAILED: "Heslo nelze aktualizovat.",
  CHANGE_PASSWORD_SUCCESS: "Vaše heslo bylo úspěšně aktualizováno.",
  ACCOUNT_ACTIVATED: "Váš účet byl úspěšně ověřen.",
  ACCOUNT_ALREADY_ACTIVATED: "Váš účet je již ověřen.",
  COULD_NOT_ACTIVATE_ACCOUNT: "Došlo k chybě a váš účet se nepodařilo ověřit. Musíme na tom zapracovat, tak nám prosím pomozte a napište nám, co se stalo, na hq@sportya.net.",
  ACCOUNT_ALREADY_EXIST: "Účet již existuje. Přihlaste nebo obnovte své heslo.",
  AUTH_FAILED: "Došlo k chybě a nelze vás ověřit. Musíme na tom zapracovat, tak nám prosím pomozte a napište nám, co se stalo, na hq@sportya.net.",
  INVALID_CREDENTIALS: "Neplatná oprávnění. Zkontrolujte pravopis a zkuste to znovu.",
  INTERNAL_ERROR: "Vnitřní chyba služby. Musíme na tom zapracovat, tak nám prosím pomozte a napište nám, co se stalo, na hq@sportya.net.",
  SERVICE_UNAVAILABLE: "Jejda! Něco nefunguje. Nahlaste prosím chybu na contact@sportya.net, abychom ji mohli co nejdříve opravit.",
  UPDATE_ACCOUNT_SUCCESS: "Účet byl úspěšně aktualizován.",
  UPDATE_ACCOUNT_FAILED: "Účet nelze aktualizovat. Musíme na tom zapracovat, tak nám prosím pomozte a napište nám, co se stalo, na hq@sportya.net.",
  UPDATE_CLUB_SUCCESS: 'Účet klubu byl úspěšně aktualizován.',
  UPDATE_CLUB_FAILED: 'Účet klubu nelze aktualizovat.',
  SESSION_EXPIRED: "Relace vypršela. Proveďte znovu ověření.",
  SUCCESSFULLY_FOLLOW_EVENT: "Nyní sledujete událost.",
  SUCCESSFULLY_UNFOLLOW_EVENT: "Zrušili jste sledování události.",
  FAILED_FOLLOW_EVENT: "Sledování události se nezdařilo / zrušit sledování.",
  ADD_FRIEND_SUCCESS: "Úspěšně jste odeslali žádost o přátelství",
  ACCEPT_FRIEND_SUCCESS: "Gratulujeme! Ve Sportya máte nového přítele",
  REMOVE_FRIEND_SUCCESS: "Hráč byl úspěšně odebrán z vašeho seznamu přátel",
  DECLINE_FRIEND_SUCCESS: "Úspěšně jste odmítli žádost o přátelství",
  CANCEL_FRIEND_SUCCESS: "Úspěšně jste zrušili odeslanou žádost o přátelství",
  UNBLOCK_PLAYER_SUCCESS: 'Přehrávač byl úspěšně odblokován.',
  BLOCK_PLAYER_SUCCESS: 'Hráč byl úspěšně zablokován.',
  SUCCESSFULLY_CREATED_REPORT: 'Hráč byl úspěšně nahlášen.',
  SUBSCRIPTION_ALREADY_ACTIVATED: "Vaše členství již bylo aktivováno.",
  COULD_NOT_AUTHORIZE_PAYMENT: "Vaši platbu jsme nemohli ověřit. Zkuste to znovu.",
  MEMBERSHIP_NOT_FOUND: "Vybrané členství nebylo nalezeno. Zkuste to znovu.",
  INSUFFICIENT_BALANCE: "Aktualizace členství se nezdařila. Nedostatečný zůstatek.",
  AMOUNT_NOT_VALID: "Aktualizace členství se nezdařila. Neplatná částka.",
  SUMMER_SUBSCRIPTION_ACTIVATED: "Je to tady! Váš Letní sezónní účet byl úspěšně aktivován.",
  PREMIUM_SUBSCRIPTION_ACTIVATED: "Je to tady! Váš Prémiový účet byl úspěšně aktivován.",
  PREMIUM_MEMBERSHIP_UPGRADE: "Je to tady! Váš Prémiový {{period}} účet byl úspěšně aktivován.",
  ALREADY_REGISTERED_TO_COMPETITION: "Pro tuto událost jste již zaregistrováni do maximálního počtu soutěží stejného typu.",
  MAX_ALLOWED_SAME_COMPETITION_ERROR: "Pro tuto událost jste již zaregistrováni do maximálního počtu soutěží stejného typu.",
  EVENT_REGISTRATION_SUCCESSFULLY: "Úspěšně jste se zaregistrovali do soutěže.",
  EVENT_WITHDRAWAL_SUCCESSFULLY: "Úspěšně jste se odstoupili ze soutěže.",
  FEE_TAX_NOT_AVAILABLE: "Poplatky pro tuto soutěž zatím nebyly stanoveny. Opakujte akci později.",
  NOT_REGISTERED_TO_COMPETITION: "Na tuto soutěž nejste přihlášeni.",
  PAYMENT_NOT_FOUND: "Pro tuto soutěž nelze spočítat daň z poplatku. Musíme na tom zapracovat, tak nám prosím pomozte a napište nám, co se stalo, na hq@sportya.net.",
  INVALID_FEE_TAX: "Pro tuto soutěž nelze spočítat daň z poplatku. Musíme na tom zapracovat, tak nám prosím pomozte a napište nám, co se stalo, na hq@sportya.net.",
  USER_INVALID_FEE_TAX: "Pro váš uživatelský účet nelze získat daň ze soutěžního poplatku. Opakujte akci později.",
  PARTNER_USER_INVALID_FEE_TAX: "Pro uživatelský účet vašeho partnera nelze získat daň ze soutěžního poplatku. Opakujte akci později.",
  CURRENT_PASSWORD_NOT_MATCH: "Vaše staré heslo je nesprávné. Zkuste to znovu.",
  USER_NOT_ALLOWED_CASE_LEVEL: "Registraci nelze zpracovat. Vaše herní úroveň neodpovídá registračním kritériím soutěže.",
  PARTNER_USER_NOT_ALLOWED_CASE_LEVEL: "Registraci nelze zpracovat. Herní úroveň vašeho týmového partnera neodpovídá registračním kritériím soutěže.",
  ACCESS_FORBIDDEN: 'Přístup zakázán.',
  ACCOUNT_BLOCKED: 'Váš účet byl zablokován. Obraťte se nás.',
  ERROR_TIMEFRAME: "Registrace do této soutěže ještě nejsou otevřeny.",
  USER_NOT_ALLOWED_CASE_GENDER: "Registraci nelze zpracovat. Vaše Pohlaví neodpovídá registračním kritériím soutěže.",
  PARTNER_USER_NOT_ALLOWED_CASE_GENDER: "Registraci nelze zpracovat. Pohlaví vašeho týmového partnera neodpovídá registračním kritériím soutěže.",
  WITHDRAW_NOT_ALLOWED: "Odstoupení od této události již není povoleno. Kontaktujte nás na hq@sportya.net.",
  ERROR_BANNED_USER: "Do této soutěže se nemůžete zapojit, protože máte v současné době zakázaný přístup. Před přihlášením do nové soutěže vyčkejte na uplynutí zákazu.",
  ERROR_BANNED_PARTNER: "Hráč {{ partnerName }} - {{ partnerId }} má v současné době zákaz přístupu k registracím události. Vyberte si jiného spoluhráče.",
  ERROR_USER_REGISTERED_SAME_WEEK: 'Jste již zaregistrováni do soutěže, která se uskuteční ve stejné době jako tato událost. V sekci Moje události, která je součástí správy vašeho účtu, zkontrolujte události, na které jste se již zaregistrovali.',
  USER_NOT_ALLOWED_CASE_ACCOUNTTYPE: "Registraci nelze zpracovat. Typ vašeho účtu (ZDARMA) neodpovídá registračním kritériím soutěže (pouze pro PRÉMIOVÉ účty).",
  PARTNER_USER_NOT_ALLOWED_CASE_ACCOUNTTYPE: "Registraci nelze zpracovat. Typ účtu vašeho týmového partnera (ZDARMA) neodpovídá registračním kritériím soutěže (pouze pro PRÉMIOVÉ účty).",
  USER_NOT_ALLOWED_CASE_MAXAGE: "Registraci nelze zpracovat. Váš věk neodpovídá registračním kritériím soutěže.",
  PARTNER_USER_NOT_ALLOWED_CASE_MAXAGE: "Registraci nelze zpracovat. Věk vašeho týmového partnera neodpovídá registračním kritériím soutěže.",
  USER_NOT_ALLOWED_CASE_MINAGE: "Registraci nelze zpracovat. Váš věk neodpovídá registračním kritériím soutěže.",
  PARTNER_USER_NOT_ALLOWED_CASE_MINAGE: "Registraci nelze zpracovat. Věk vašeho týmového partnera neodpovídá registračním kritériím soutěže.",
  USER_NOT_ALLOWED_CASE_ACCOUNTACTIVITY: "Nemáte minimální počet odehraných událostí (s ověřením), který je nutný pro účast v této soutěži.",
  PARTNER_USER_NOT_ALLOWED_CASE_ACCOUNTACTIVITY: "Váš týmový partner nemá minimální počet odehraných událostí (s ověřením), který je nutný pro účast v této soutěži.",
  USER_NOT_ALLOWED_CASE_RANK: "Nesplňujete registrační kritéria. Vaše aktuální hodnocení na žebříčku neodpovídá limitům stanoveným pro tuto soutěž.",
  PARTNER_USER_NOT_ALLOWED_CASE_RANK: "Váš týmový partner nesplňuje registrační kritéria. Jeho aktuální hodnocení na žebříčku neodpovídá limitům stanoveným pro tuto soutěž.",
  TEAM_NOT_ALLOWED_AVG_LEVEL: "Registraci nelze zpracovat. Úroveň vašeho týmu neodpovídá limitní úrovni, která platí pro tuto soutěž.",
  TEAM_LEVEL_FORBIDDEN_TO_TEAMS: "Registraci nelze zpracovat. Úrovně ve vašem týmu neodpovídají limitní úrovni, která platí pro tuto soutěž.",
  TEAM_MUST_BE_MIXT: "Registraci nelze zpracovat. Váš tým musí být složen tak, aby odpovídal registračním kritériím soutěže.",
  PARTNER_USER_NOT_ALLOWED_CASE_FORBIDDEN_AGE: 'Registraci nelze zpracovat. Do soutěží Sportya se mohou zapojit pouze hráči, kterým je nejméně 15 let.',
  USER_NOT_ALLOWED_CASE_FORBIDDEN_AGE: 'Registraci nelze zpracovat. Do soutěží Sportya se mohou zapojit pouze hráči, kterým je nejméně 15 let.',
  COULD_NOT_CREATE_FRIENDLY_MATCH: 'V tuto chvíli nelze vytvořit přátelský zápas. Prosím zkuste to znovu později!',
  SUCCESSFULLY_UPDATED_FRIENDLY_MATCH: 'Váš přátelský zápas byl úspěšně aktualizován.',
  COULD_NOT_UPDATE_FRIENDLY_MATCH: 'Váš přátelský zápas v tuto chvíli nelze aktualizovat. Prosím zkuste to znovu později!',
  SUCCESSFULLY_ACCEPTED_FRIENDLY_MATCH: 'Úspěšně jste přijali pozvánku na zápas.',
  COULD_NOT_ACCEPT_FRIENLDY_MATCH: 'Pozvánka na zápas nemůže být v tuto chvíli přijata. Prosím zkuste to znovu později!',
  SUCCESSFULLY_DECLINED_FRIENDLY_MATCH: 'Úspěšně jste odmítli pozvánku na přátelský zápas.',
  COULD_NOT_DECLINE_FRIENLDY_MATCH: 'Pozvánka na zápas nelze v tuto chvíli odmítnout. Prosím zkuste to znovu později!',
  SUCCESSFULLY_FRIENDLY_MATCH_WITHDRAWN: 'Úspěšně jsme zpracovali vaše odstoupení ze zápasu.',
  COULD_NOT_WITHDRAW_FRIENLDY_MATCH: 'Vaše odstoupení ze zápasu nemůže být v tuto chvíli zpracováno. Prosím zkuste to znovu později!',
  SUCCESSFULLY_CANCELED_FRIENDLY_MATCH: 'Váš zápas jsme úspěšně zrušili.',
  COULD_NOT_CANCEL_FRIENLDY_MATCH: 'Váš zápas v tuto chvíli nelze zrušit. Prosím zkuste to znovu později!',
  SUCCESSFULLY_REFUSED_FRIENDLY_MATCH: 'Úspěšně jste zápas nahlásili jako neodehraný.',
  COULD_NOT_REFUSE_FRIENLDY_MATCH: 'Zápas nelze aktuálně nahlásit jako neodehraný zápas. Prosím zkuste to znovu později!',
  SUCCESSFULLY_SAVED_SCORE: 'Výsledek byl úspěšně uložen.',
  COULD_NOT_SAVE_SCORE: 'Vaše skóre v tuto chvíli nelze uložit. Prosím zkuste to znovu později!',
  passwordMustMatch: 'Hesla se neshodují. Prosím, zkuste to znovu.',
  banned: `Váš hráčský účet je na <0>{{time}}</0> zablokován. Do nových soutěží se nebudete moci zaregistrovat do <1>{{until}}.</1>
  Pokud potřebujete více informací, můžete kontaktovat tým Sportya na adrese <2>contact@sportya.net</2>`,
  missingLocation: 'Místo konání této události zatím nebylo stanoveno.',
  notAvailable: 'Není k dispozici',
  weeks: '{{time}} týdny (týdnů)',
  singleWeek: '1 týden',
  free: 'Zdarma',
  rank: 'Hodnocení',
  age: 'Věk',
  minage: 'Min. věk',
  maxage: 'Max. věk',
  january: "Leden",
  february: "Únor",
  march: "Březen",
  april: "Duben",
  may: "Květen",
  june: "Červen",
  july: "Červenec",
  august: "Srpen",
  september: "Září",
  october: "Říjen",
  november: "Listopad",
  december: "Prosinec",
  Mon: "Po",
  Tue: "Út",
  Wed: "Stř",
  Thu: "Čt",
  Fri: "Pá",
  Sat: "So",
  Sun: "Ne",
  Sunday: "Neděle",
  Monday: "Pondělí",
  Tuesday: "Úterý",
  Wednesday: "Středa",
  Thursday: "Čtvrtek",
  Friday: "Pátek",
  Saturday: "Sobota",
  Jan: "Led",
  Feb: "Ún",
  Mar: "Bře",
  Apr: "Dub",
  Jun: "Čer",
  Jul: "Čvc",
  Aug: "Srp",
  Sep: "Zář",
  Oct: "Říj",
  Nov: "List",
  Dec: "Pros",
  January: "Leden",
  February: "Únor",
  March: "Březen",
  April: "Duben",
  May: "Květen",
  June: "Červen",
  July: "Červenec",
  August: "Srpen",
  September: "Září",
  October: "Říjen",
  November: "Listopad",
  December: "Prosinec",
  male: "Muž",
  female: "Žena",
  men: "Muži",
  women: "Ženy",
  mixt: "Smíšené",
  select: "Vybrat",
  level: "Úroveň",
  maintenance: 'Údržba',
  inMaintenance: 'Probíhá údržba',
  regBackSoon: 'Registrace budou brzy znovu otevřeny',
  continue: "Pokračovat",
  requiredField: "Požadované pole",
  nrNotValid: 'Telefonní číslo není platné',
  invalidEmail: "Neplatný e-mail",
  minChars: "Minimálně {{ chars }} znaků",
  maxChars: "Maximálně {{ chars }} znaků",
  mustContainerSpecialChars: "Zadejte alespoň jeden zvláštní znak (@,!,#, etc)",
  logout: "Odhlásit se",
  login: "Přihlásit se",
  signup: "Zaregistrovat se",
  password: "Heslo",
  email: "E-mailem",
  grass: 'Trávník',
  clay: 'Antuka',
  artificial: 'Umělý',
  hard: 'Pevný',
  close: 'Zavřít',
  firstName: "Křestní jméno",
  lastName: "Příjmení",
  phoneNumber: "Telefonní číslo",
  phone: 'Telefon',
  imClubManager: "Jsem manažer klubu",
  clubOwner: 'Majitel klubu',
  moreDetails: 'Další podrobnosti',
  birthdate: 'Datum narození',
  days: 'Dní',
  day: 'Den',
  from: 'Od',
  to: 'Pro',
  back: 'Zpět',
  openHours: 'Otevírací doba',
  dismiss: 'Zamítnout',
  metaDescription: 'Platforma Sportya je místem, jehož součástí se všichni toužíme stát, národem, který je ovládán naší vášní pro sport. Je napájena duchem soutěživosti. Není rozdělena podle věku, pohlaví, barvy pleti nebo jiného smýšlení. Území Sportya nemá geografické hranice, nejsou nutné žádné cestovní pasy a řídíme se pouze fair-play. Všichni mluvíme stejným jazykem, kterým je SPORT.',
  metaKeywords: 'sportya, tenis, tenisové akce, tenisové soutěže, tenisové turnaje, tenisová utkání, tenisová komunita, tenisové ligy, místní ligové soutěže, tenisový partner, tenisoví hráči, amatérští hráči, tenisové kluby, přátelská utkání, hrát tenis, vyhledat hráče, najít události, tenisové kurty, nejlepší shoda, sporty',
  ogTitle: 'Sportya - Země vašeho nejlepšího utkání',
  ogDescription: 'Přidejte se ke mně ve Sportya a hrajte přátelské zápasy a zúčastněte se národních a mezinárodních turnajů! \n\r Vytvořte si účet pomocí mého jedinečného zvacího odkazu a získejte odměny: \n\r {{ link }}',
  saveChanges: 'Uložit změny',
  languages: {
    en: "English",
    bg: "Български",
    ro: "Română",
    hu: "Magyar",
    cz: 'Čeština',
  },
  gold: "Zlato",
  silver: "Stříbro",
  platinum: "Platina",
  champions: "Mistři",
  winners: 'Vítězové',
  courtNr: 'Kurt {{courtNr}}',
  premium: "Prémiový",
  special: "Speciální",
  firstServe: 'První podání',
  league: 'Liga',
  allAges: "Všechny věkové skupiny",
  type: 'Typ',
  customAge: "Vybrat vlastní věk",
  custom: 'Vlastní',
  location: "Místo",
  LocationGmaps: 'Místo',
  category: "Kategorie",
  month: "Měsíc",
  year: "Rok",
  premiumAccountMembershipUpgrade: 'SPORTYA Upgrade členství prémiového účtu',
  virtualWalletTopUp: `SPORTYA Virtuální peněženka - dobíjení {{ amount }} {{ currency }}`,
  eventRegistration: 'SPORTYA Registrace na {{ eventName }}',
  partnerEventRegistration: 'SPORTYA Registrace partner na {{ eventName }}',
  cookieConsent: 'Tento web používá soubory cookie, aby vám poskytl nejlepší zážitky. Používáním platformy Sportya souhlasíte s používáním souborů cookie v souladu s našimi <1>Privacy Policy<1>',
  cookieConsentButton: 'Povolit soubory cookie',
  all: 'Vše',
  romania: 'Rumunsko',
  bulgaria: 'Bulharsko',
  moldova: 'Republika Moldávie',
  bahrain: 'Bahrajn',
  armenia: 'Arménie',
  hungary: `Mad'arsko`,
  czechRepublic: 'Česká republika',
  city: 'Město',
  countriesByKey: {
    ro: 'Rumunsko',
    md: 'Republika Moldávie',
    bg: 'Bulharsko',
    cz: 'Česko',
    am: 'Arménie',
    hu: `Mad'arsko`,
    bh: 'Bahrajn',
  },
  country: 'Země',
  soonToBeAnnounced: 'Brzy bude oznámeno',

  migration: {
    1: `Probíhá migrace dat ze systému Tenis Partener na platformu Sportya. 
    Během této doby bude část vašeho hráčského profilu nedostupná. 
    Děkujeme za vaši trpělivost.`,
  },

  players: "Hráči",
  teams: 'Týmy',
  areYouSure: 'Jste si jisti?',
  setLevel: 'Nastavit úroveň',

  allLocations: 'Všechna místa',
  allCategories: 'Všechny kategorie',
  allMonths: 'Všechny měsíce',
  allYears: 'Všechny roky',
  allLevels: 'Všechny úrovně',

  onlyfemale: 'Pouze ženy',
  onlymale: 'Pouze muži',
  onlymixt: 'Pouze smíšené týmy',

  right: 'Vpravo',
  left: 'Vlevo',
  searchPlayer: 'Vyhledat hráče',
  'Romania-All': 'Rumunsko (Vše)',
  'Bulharsko-Vše': 'Bulharsko (Vše)',
  'Moldávie-Vše': 'Moldávie (Vše)',
  'Maďarsko-Vše': 'Maďarsko (Vše)',
  'Bahrajn-Vše': 'Bahrajn (Vše)',
  'Arménie-Vše': 'Arménie (Vše)',
  'Česko-Vše': 'Česko (Vše)',
  officialBall: 'Oficiální ples',
  eventPrizes: 'Ceny za událost',
  eventPrizesDescription: 'Standardní ceny pro klasický turnaj (Stříbro, Zlato, Platina, Speciální turnaj) pro každou soutěžní událost tvoří medaile a diplom. Veškeré další ceny budou aktualizovány v této sekci. \n\n V případě místních ligových soutěží nám zašlete zprávu na adresu contact@sportya.net, abychom vám mohli vygenerovat digitální diplom za 1. místo.',
  gameLevel: 'Úroveň hry',
  uploadPlaceholder: 'Sem přetáhněte soubory nebo je nahrajte kliknutím.',
  download: "Stáhnout",
  viewPDF: "Zobrazit hlavní losování jako PDF",
  clearResults: 'Vymazat výsledky',
  onlyLatin: `Používejte pouze písmena latinky. 
  Toto omezení je nezbytné, aby vás ostatní hráči mohli na platformě Sportya lépe najít 
  (příklad: při registraci týmu do čtyřhry na akci).`,
  noResultsFound: 'Nebyly nalezeny žádné výsledky',
  typeAtLeast3Chars: 'Zadejte alespoň 3 znaky...',
  allActivities: 'Všechny aktivity',
  eventSponsors: 'Sponzoři události',
  sponsors: 'Sponzoři',
  coordinators: 'Koordinátoři',
  officialStore: 'Oficiální obchod',
  officialSponsors: 'Oficiální sponzoři',
  proximityRadius: 'Vzdálenost',
  currentLocation: 'Moje poloha',
  calendar: 'Kalendář',
  radius: 'Rádius',
  page_not_found: 'Stránka nebyla nalezena.',
  go_back: 'Přejít zpět',
  isLoading: 'Načítá se...',
  show: 'Zobrazit',
  SUCCESSFULLY_INITIATED_CHAT: 'Conversation was successfully initiated.',
  COULD_NOT_INITIATE_CHAT: 'Conversation could not be initiated. Try again later.',
  cancelPopup: {
    title: 'Opravdu chcete opustit tuto stránku?',
    stay: 'Zůstávám na stránce',
    quit: 'Opouštím stránku',
  },
  COULD_NOT_RATE_PLAYER: 'Hodnocení v tuto chvíli nelze uložit. Prosím zkuste to znovu později.',
  SUCCESSFULLY_RATED_PLAYER: 'Hodnocení bylo úspěšně uloženo.',
  SUCCESSFULLY_DELETED_RATING: 'Hodnocení bylo úspěšně odvoláno.',
  PaidParking: "Placené parkování",
  FreeParking: "Bezplatné parkování",
  EquipmentRental: "Pronájem vybavení",
  Store: "Obchod",
  Lockers: "Šatny",
  Showers: "Sprchy",
  Restaurant: "Restaurace",
  Cafeteria: "KAVÁRNA",
  Bar: "Bar",
  VendingMachine: "Automat",
  SpecialAccess: "Speciální přístup",
  KidsPlayground: "Dětské hřiště",
  referralCode: "Kód pozvánky (volitelné)",
  INVALID_REFERRAL_CODE: 'Neplatný zvací kód',
  REFERRAL_CODE_APPLIED_SUCCESSFULLY: 'Kód pozvánky byl úspěšně použit!',
  date: "Datum",
  time: "Hodina",
  no: "Ne",
  yes: "Ano",
  FAILED_TO_BOOK_COURT: "Tenisový kurt nelze rezervovat! Zkus to znovu!",
  BOOKING_COULD_NOT_BE_CREATED: "Tenisový kurt nelze rezervovat! Zkus to znovu!",
  COULD_NOT_CALCULATE_BOOKING_PRICE: "Tenisový kurt nelze rezervovat! Zkus to znovu!",
  COURT_NO_LONGER_AVAILABLE: "Tento tenisový kurt již není k dispozici! Zkuste si zarezervovat jiný.",
  START_DATE_OUTSIDE_MAX_BOOKING_INTERVAL: "Tenisový kurt nelze rezervovat! Zkus to znovu!",
  BOOKING_CONFIRMED_SUCCESSFULLY: "Gratulujeme! Vybraný tenisový kurt byl úspěšně rezervován!",
  COURT_IS_NOT_AVAILABLE: "Tento tenisový kurt již není k dispozici! Zkuste si zarezervovat jiný.",
  CLUB_BOOKING_DISABLED: "Toto hřiště bylo právě deaktivováno klubem. Zkuste si zarezervovat jiný.",
  FAILED_TO_CONFIRM_BOOKING: "Rezervaci nebylo možné potvrdit. Zkuste to znovu nebo nás kontaktujte.",
  ALREADY_WITHDRAWN: "Z této soutěže jste se již odhlásili.",
  RESOURCE_NOT_FOUND: "Route could not be found.",
  BOOKING_CANCELED_SUCCESSFULLY: "Rezervace byla úspěšně zrušena.",
  FAILED_TO_CANCEL_BOOKING: "Rezervaci nebylo možné zrušit.",
  BOOKING_ALREADY_CANCELED: "Rezervace již byla zrušena.",
  INVALID_FRIENDLY_MATCH_SCORE_FORMAT: 'Neplatné skóre na základě vámi zvoleného formátu zápasu!',
  INVOICE_UNAVAILABLE: 'Faktura není k dispozici.',
  bookNow: "Book Now",
  searchAndBookCourts: "<0>BIG NEWS!</0> You can now SEARCH and BOOK a court in Sportya Partner Clubs",
  sports: {
    tennis: "Tennis",
    padel: "Padel",
  }
};
