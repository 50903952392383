import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Contain, P } from '../../../../../components/Collection';
import LoadImage from '../../../../../components/common/LoadImage';
import { timeSince } from '../../../../../components/common/timeSince';

import {
  MatchType,
  OptionIcon,
  PlayerMetaData,
} from '../../../StartMatch/components/WantToPlay/styles';

import {
  MatchGametype,
  MatchStatusBadge,
  RankedIcon,
  DueToP,
} from '../../../Matches/styles';

import {
  Bold,
  ExpandedMatchesButtonContainer,
  ExpandedMatchesContainer,
  ExpandedMatchesContent,
  ExpandedMatchesButton,
  ExpandedMatchesFooter,
  ExitIcon,
} from './styles';

import Player from '../../../Scores/components/components/Player';
import { WLBox } from '../../../Scores/styles';
import { Badge as ShieldBadge } from '../../../../../components/LevelsBadges/styles';
import { formatMatchId, getWinner } from '../../../helpers';
import { Paragraph } from '../../../../../components';
import MatchWarnings from '../../../Matches/components/components/MatchWarnings';
import LinkWrapper from '../../../../../components/Link';

const MatchesExpandedContainer = ({
  match,
  isOnHold,
  buttonsToShow,
  timeUntilScoreValidation,
  myPosition,
  displayPlayedTag: isPlayedMatch,
  matchStartDateFormated,
  modalHandler,
  exitHandler,
  initiateConversation,
  isDoubles,
}) => {
  const history = useHistory();
  const { t } = useTranslation('friendlyMatches');
  const {
    player,
    opponent,
    playerPartner,
    opponentPartner,
    pastMatch,
    gameType,
    ranked,
    location,
    format,
    createdAt,
  } = match || {};
  const {
    edit, accept, decline, chat, cancel, confirmMatch, noMatch, addScore, changeScore, confirmScore, withdraw,
  } = buttonsToShow || {};

  const handleEditMatch = () => {
    history.push(`/friendly-matches/edit${match.pastMatch ? '/played' : ''}`, { match });
  };

  let winner = getWinner(match);
  if (!['player', 'opponent'].includes(winner)) {
    winner = undefined;
  }
  const teamLevel = ((player?.gameLevel?.doubles ?? 0) + (playerPartner?.gameLevel?.doubles ?? 0)) / 2;
  const opponentTeamLevel = ((opponent?.gameLevel?.doubles ?? 0) + (opponentPartner?.gameLevel?.doubles ?? 0)) / 2;

  return (
    <ExpandedMatchesContainer>
      <ExitIcon
        onClick={() => exitHandler()}
        src={LoadImage('friendly-matches/matches/button-dropup.svg')}
        alt=""
        dropdownIcon
      />
      <ExpandedMatchesContent>
        <Contain direction="column" minWidth="40%">
          <P large bold>
            <Trans ns="friendlyMatches" i18nKey="players">Players</Trans>
          </P>

          {!isDoubles && (
            <Contain direction="column">

              <Contain direction="row" className="player-data">
                <Contain direction="column" className="mr10">
                  <Player player={player} currentPlayer={myPosition === 'player'} />
                </Contain>

                {winner && (
                  <Contain direction="row" margin="5px 0 0 0">
                    {match?.player?.type === 'BYE' && (<P className="bye mt0 mb0 mr10">Ab.&nbsp;</P>)}
                    {(match?.player?.score || []).map((score, key) => (
                      <P key={key} margin="0 20px 0 0" bold={winner === 'player'}>
                        {score.points}
                        {score.tie ? (<sup>{score.tie}</sup>) : ''}
                      </P>
                    ))}
                    {winner === 'opponent'
                      ? <WLBox lose top="-3px" margin="0 0 0 10px">L</WLBox>
                      : <WLBox win top="-3px" margin="0 0 0 10px">W</WLBox>}
                  </Contain>
                )}
              </Contain>

              <Contain direction="row" className="player-data">
                <Contain direction="column" className="mr10">
                  <Player player={opponent} currentPlayer={myPosition === 'opponent'} />
                </Contain>

                {winner && (
                  <Contain direction="row" margin="5px 0 0 0">
                    {match?.opponent?.type === 'BYE' && (<P className="bye mt0 mb0 mr10">Ab.&nbsp;</P>)}
                    {(match?.opponent?.score || []).map((score, key) => (
                      <P key={key} margin="0 20px 0 0" bold={winner === 'opponent'}>
                        {score.points}
                        {score.tie ? (<sup>{score.tie}</sup>) : ''}
                      </P>
                    ))}
                    {winner === 'player'
                      ? <WLBox lose top="-3px" margin="0 0 0 10px">L</WLBox>
                      : <WLBox win top="-3px" margin="0 0 0 10px">W</WLBox>}
                  </Contain>
                )}
              </Contain>

              <Contain justify="end">
                {player?.points > 0 && (
                  <P xSmall margin="5px 0 5px 10px">
                    Points:
                    <Bold>
                      +
                      {player?.points}
                    </Bold>
                  </P>
                )}
              </Contain>

            </Contain>
          )}

          {isDoubles && (
            <Contain direction="column">
              <Contain direction="row" className="player-data">
                <Contain direction="column" className="mr10">
                  <Contain direction="row">
                    <P margin="10px 0" xSmall bold>
                      {myPosition.includes('player') ? t('yourTeam') : t('opponentTeam')}
                    </P>

                    <ShieldBadge double padding="0" medium position="relative" top="6px" margin="0 0 0 6px">
                      <P xxSmall bold>{teamLevel}</P>
                    </ShieldBadge>
                  </Contain>

                  <Player player={player} isDoubles currentPlayer={myPosition === 'player'} />
                  <Player player={playerPartner} isDoubles currentPlayer={myPosition === 'playerPartner'} />
                </Contain>

                {winner && (
                  <Contain direction="row" justify="center" align="center">
                    {match?.player?.type === 'BYE' && (<P className="bye mt0 mb0 mr10">Ab.&nbsp;</P>)}
                    {(match?.player?.score || []).map((score, key) => (
                      <P key={key} margin="0 20px 0 0" bold={winner === 'player'}>
                        {score.points}
                        {score.tie ? (<sup>{score.tie}</sup>) : ''}
                      </P>
                    ))}
                    {winner === 'opponent'
                      ? <WLBox lose margin="0 0 0 10px">L</WLBox>
                      : <WLBox win margin="0 0 0 10px">W</WLBox>}
                  </Contain>
                )}
              </Contain>

              <Contain direction="row" className="player-data">
                <Contain direction="column" className="mr10">
                  <Contain direction="row">
                    <P margin="10px 0" xSmall bold>
                      {myPosition.includes('opponent') ? t('yourTeam') : t('opponentTeam')}
                    </P>

                    <ShieldBadge double padding="0" medium position="relative" top="6px" margin="0 0 0 6px">
                      <P xxSmall bold>{opponentTeamLevel}</P>
                    </ShieldBadge>
                  </Contain>

                  <Player player={opponent} isDoubles currentPlayer={myPosition === 'opponent'} />
                  <Player
                    player={opponentPartner}
                    isDoubles
                    currentPlayer={myPosition === 'opponentPartner'}
                    isPartner
                  />
                </Contain>

                {winner && (
                  <Contain direction="row" justify="center" align="center">
                    {match?.opponent?.type === 'BYE' && (<P className="bye mt0 mb0 mr10">Ab.&nbsp;</P>)}
                    {(match?.opponent?.score || []).map((score, key) => (
                      <P key={key} margin="0 20px 0 0" bold={winner === 'opponent'}>
                        {score.points}
                        {score.tie ? (<sup>{score.tie}</sup>) : ''}
                      </P>
                    ))}
                    {winner === 'player'
                      ? <WLBox lose margin="0 0 0 10px">L</WLBox>
                      : <WLBox win margin="0 0 0 10px">W</WLBox>}
                  </Contain>
                )}
              </Contain>

              <Contain justify="end">
                {player?.points > 0 && (
                  <P xSmall margin="5px 0 5px 10px">
                    Points:
                    <Bold>
                      +
                      {player?.points}
                    </Bold>
                  </P>
                )}
              </Contain>

            </Contain>
          )}
        </Contain>

        <Contain direction="column">
          <Contain>
            <MatchType src={LoadImage(`friendly-matches/matches/${gameType}-match.svg`)} alt={t(gameType)} />
            <PlayerMetaData>
              <MatchGametype bold>
                <Trans ns="friendlyMatches" i18nKey={gameType === 'singles' ? 'singlesMatch' : 'doublesMatch'}>
                  {gameType}
                  {' '}
                  Match
                </Trans>
                {ranked
                && <RankedIcon src={LoadImage('friendly-matches/matches/ranked-match.svg')} alt={t('ranked')} />}
                {myPosition === 'player' && (
                  <MatchStatusBadge isYourMatch>
                    <Trans ns="friendlyMatches" i18nKey="yourMatch">Your Match</Trans>
                  </MatchStatusBadge>
                )}
                {isPlayedMatch && (
                  <MatchStatusBadge>
                    <Trans ns="friendlyMatches" i18nKey="played">Played</Trans>
                  </MatchStatusBadge>
                )}
                <span className="match-id">{formatMatchId(match.matchId)}</span>
              </MatchGametype>
              <P margin="2px 0 0 0" className="location">
                {matchStartDateFormated}
                {` - ${location?.clubName ? location?.clubName : location?.name}`}
              </P>
            </PlayerMetaData>
          </Contain>

          <Contain display="block" className="event-data">
            <Paragraph bold>
              <Trans ns="friendlyMatches" i18nKey="location">Location</Trans>
              :
              {' '}
            </Paragraph>
            <Paragraph>{`${location?.isClub ? `${location?.clubName} - ` : ''} ${location?.name}`}</Paragraph>
            <Paragraph>
              <a
                href={`https://maps.google.com/maps?q=${location?.coords?.lat},${location?.coords?.lng}&zoom=9`}
                target="_blank"
                rel="noreferrer"
                className="text-underline"
              >
                <Trans ns="friendlyMatches" i18nKey="seeLocation">See Location</Trans>
              </a>
            </Paragraph>
          </Contain>

          <Contain display="block" className="event-data">
            <Paragraph bold>
              <Trans ns="friendlyMatches" i18nKey="matchType">Match Type</Trans>
              :
            </Paragraph>
            <Paragraph className="d-flex">
              {ranked ? t('ranked') : t('unranked')}
              {ranked && <img src={LoadImage('friendly-matches/matches/ranked-match.svg')} alt="" className="ml5" />}
            </Paragraph>
          </Contain>

          <Contain className="event-data">
            <P>
              <Bold>
                <Trans ns="friendlyMatches" i18nKey="matchFormat">Match Format</Trans>
                :
              </Bold>
              {' '}
              <br />
              {t(format)}
            </P>
          </Contain>

          <Contain className="event-data">
            <P>
              <Bold>
                <Trans ns="friendlyMatches" i18nKey="createdBy">Created By</Trans>
                :
              </Bold>
              {' '}
              <br />
              <LinkWrapper to={`/accounts/${player.id}`} className="text-decoration-none">
                {player.firstName}
                {' '}
                {player.lastName}
                {' '}
                <span className="color-secondary">
                  #
                  {player.userId}
                </span>
              </LinkWrapper>
              {' '}
              (
              {timeSince(new Date(createdAt))}
              )
            </P>
          </Contain>
        </Contain>
      </ExpandedMatchesContent>
      <ExpandedMatchesFooter>
        <MatchWarnings
          match={match}
          myPosition={myPosition}
          isOnHold={isOnHold}
          timeUntilScoreValidation={timeUntilScoreValidation}
          displayMessage
        />

        <ExpandedMatchesButtonContainer>
          {addScore && !(edit && isOnHold)
            && (
              <ExpandedMatchesButton disabled={isOnHold} onClick={() => modalHandler('addScore')}>
                <Trans ns="friendlyMatches" i18nKey="addScore">Add Score</Trans>
              </ExpandedMatchesButton>
            )}

          {changeScore && !(edit && isOnHold)
            && (
              <div className="position-relative">
                <ExpandedMatchesButton disabled={isOnHold} onClick={() => modalHandler('changeScore')}>
                  <Trans ns="friendlyMatches" i18nKey="changeScore">Change Score</Trans>
                </ExpandedMatchesButton>
                <DueToP small expanded>{timeUntilScoreValidation}</DueToP>
              </div>
            )}

          {confirmScore && !(edit && isOnHold) && (
            <div className="position-relative">
              <ExpandedMatchesButton disabled={isOnHold} onClick={() => modalHandler('confirmScore')}>
                <Trans ns="friendlyMatches" i18nKey="confirmScore">Confirm Score</Trans>
              </ExpandedMatchesButton>
              <DueToP small expanded>{timeUntilScoreValidation}</DueToP>
            </div>
          )}

          {noMatch && !confirmMatch
            && (
              <ExpandedMatchesButton
                ExpandedMatchesButton
                small
                declineButton
                outline
                noBorder
                onClick={() => modalHandler(pastMatch ? 'noMatchFromRequestsTab' : 'noMatchFromMatchesTab')}
              >
                <Trans ns="friendlyMatches" i18nKey="noMatch">No Match</Trans>
              </ExpandedMatchesButton>
            )}

          {edit
            && (
              <ExpandedMatchesButton onClick={() => handleEditMatch()}>
                <Trans ns="friendlyMatches" i18nKey="editMatch">Edit Match</Trans>
              </ExpandedMatchesButton>
            )}

          {accept
            && (
              <ExpandedMatchesButton onClick={() => modalHandler('accept')}>
                <Trans ns="friendlyMatches" i18nKey="acceptMatch">Accept Match</Trans>
              </ExpandedMatchesButton>
            )}

          {decline
            && (
              <ExpandedMatchesButton onClick={() => modalHandler('decline')} small declineButton outline noBorder>
                <Trans ns="friendlyMatches" i18nKey="decline">Decline</Trans>
              </ExpandedMatchesButton>
            )}

          {confirmMatch
            && (
              <ExpandedMatchesButton disabled={isOnHold} onClick={() => modalHandler('confirmMatchScore')}>
                <Trans ns="friendlyMatches" i18nKey="confirmMatch">Confirm Match</Trans>
              </ExpandedMatchesButton>
            )}

          {noMatch && confirmMatch
            && (
              <ExpandedMatchesButton
                ExpandedMatchesButton
                small
                declineButton
                outline
                noBorder
                onClick={() => modalHandler(pastMatch ? 'noMatchFromRequestsTab' : 'noMatchFromMatchesTab')}
              >
                <Trans ns="friendlyMatches" i18nKey="noMatch">No Match</Trans>
              </ExpandedMatchesButton>
            )}

          {withdraw
            && (
              <ExpandedMatchesButton
                whiteBoxShadow
                onClick={() => modalHandler('withdraw')}
                declineButton
                outline
                noBorder
              >
                <Trans ns="friendlyMatches" i18nKey="withdraw">Withdraw</Trans>
              </ExpandedMatchesButton>
            )}

          {cancel
            && (
              <ExpandedMatchesButton onClick={() => modalHandler('cancel')} small declineButton outline noBorder>
                <Trans ns="friendlyMatches" i18nKey="cancelMatch">Cancel Match</Trans>
              </ExpandedMatchesButton>
            )}

          {chat
            && (
              <ExpandedMatchesButton outline middle onClick={initiateConversation}>
                <Trans ns="friendlyMatches" i18nKey="chat">Chat</Trans>
              </ExpandedMatchesButton>
            )}

          <OptionIcon
            onClick={() => exitHandler()}
            src={LoadImage('friendly-matches/matches/button-dropup.svg')}
            alt=""
            hideDesktop
          />
        </ExpandedMatchesButtonContainer>
      </ExpandedMatchesFooter>
    </ExpandedMatchesContainer>
  );
};

export default MatchesExpandedContainer;
