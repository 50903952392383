export default {
  seoTitle: '{{club}} - Tenisz Klub - {{location}} | Sportya',
  see_all: 'Lásd az összeset',
  find_court: 'Keress egy pályát és játssz',
  view_details: 'Részletek megtekintése',
  location: 'Hely',
  about: 'Részletek',
  events: 'Események',
  amenities: 'Felszereltség',
  openNow: 'Nyisd meg most',
  pickLocation: 'Válassz egy helyet',
  date: 'Dátum',
  today: 'Ma',
  hour: 'Óra',
  anytime: 'Bármikor',
  search: 'Keresés',
  seeOnMap: 'Lásd a térképen',

  title: 'Szervezz rendezvényeket és bérelj pályákat helyi játékosaidnak',
  modalTitle: 'Klubprofil szerkesztése',
  seoTitle1: 'Kluboknak | Sportya',
  info1: {
    title: 'Teniszpályák kezelése',
    desc: 'Tartsd egyetlen pillantással teljes mértékben kézben a teniszpályáidat.',
  },
  info2: {
    title: 'Keress teniszszakembereket',
    desc: 'Foglalj leckéket magasan képzett teniszszakemberekkel egy közeledben levő teniszpályán.',
  },
  info3: {
    title: 'Szervezd meg az eseményeidet',
    desc: 'Koncentrálj játékosaid és létesítményed gondozására.',
  },
  form: {
    title: 'Többet szeretnél megtudni?',
    desc: 'Adj meg néhány információt, és megkeresünk.',
    firstName: 'Keresztnév',
    lastName: 'Vezetéknév',
    jobTitle: 'Foglalkozás',
    email: 'E-mail',
    phone: 'Telefonszám',
    city: 'Város',
    clubName: 'Klub / Helyszín neve',
    sport: 'Sport',
    numberOfCourts: 'Teniszpályák száma',
    button: 'Kapcsolatfelvételt kérek',
    ToS: 'A kapcsolatfelvétellel megerősíted, hogy elfogadod személyes adataidnak az <1>Adatvédelmi irányelvek<1> című dokumentumban foglaltak szerinti kezelését.',
    validation: {
      required: 'Kötelező mező',
      invalidEmail: 'Érvénytelen e-mail',
      success: 'Kérésed sikeresen elküldve. Köszönjük szépen!',
      error: 'Valami baj történt',
    },
  },
  call: {
    title: 'Hívj minket',
    phoneNr: '+4 031 805 4042',
  },
  contact: {
    title: 'Lépj kapcsolatba velünk',
    email: 'join@sportya.net',
  },
  CLUB_LEAD_CREATED: 'Kérésedet sikeresen elküldtük!',
  CLUB_LEAD_ALREADY_EXIST: 'Egy klub már használja ezt az e-mail címet.',
  CLUB_LEAD_CREATE_ERROR: 'Kérésedet nem sikerült elküldeni!',

  filterEvents: 'Események állapota',
  sportyaOSCTA: 'Van Klub- Vagy Szervezői Fiókod?',
  sportyaOSButton: 'Lépjen a SportyaOS',
  tennisClubsFound: '<0>{{ totalResults }} klub</0> található',
  padelClubsFound: '<0>{{ totalResults }} klub</0> található',
  tennisClubsFound_plural: '<0>{{ totalResults }} klub</0> található',
  padelClubsFound_plural: '<0>{{ totalResults }} klub</0> található',
  tennisClubsFound_with_location: '<0>{{ totalResults }} klubot</0> találtunk <2>{{ location }}</2> számára',
  padelClubsFound_with_location: '<0>{{ totalResults }} klubot</0> találtunk <2>{{ location }}</2> számára',
  tennisClubsFound_plural_with_location: '<0>{{ totalResults }} klubot</0> találtunk <2>{{ location }}</2> számára',
  padelClubsFound_plural_with_location: '<0>{{ totalResults }} klubot</0> találtunk <2>{{ location }}</2> számára',
  startingPriceFrom: "{{ startingPrice }} {{ currency }}-tól",
  indoorCourts: "Indoor Courts",
  noClubsFound: "Nincs találat erre a keresésre",
  filters: 'Szűrők',
  onlyAvailableCourts: "Csak elérhető földek",
  onlyAvailableSlots: "Csak elérhető földek",
  showMap: "Térkép megjelenítése",
  selfService: "Self-Service",
  clearAll: "Mindent töröl",
  apply: "Alkalmaz",
  surface: "Felület",
  surfaceType: {
    grass: 'Fű',
    clay: 'Salak',
    artificial: 'Mű',
    hard: 'Kemény',
  },
  courtType: "Tereptípus",
  courtTypes: {
    outdoor: "Szabadtéri",
    indoor: "Beltéri",
  },
  courtSize: "Dimenzió",
  courtSizes: {
    singles: 'Egyéni',
    doubles: 'Páros',
  },
  seeOtherAvailabilities: "Lásd a többi lehetőséget",
  currentlyUnavailable: "Pillanatnyilag elérhetetlen",
  comingSoon: "Hamarosan",
  court: "Teniszpálya",
  withLighting: "világítással",
  checkout: "Megerősítés",
  addPlayersToFriendlyMatches: "Adjon hozzá játékosokat a barátságos mérkőzés hez",
  gameType: "Játék típusa",
  bookingId: "Foglalás azonosítója: <1>{{ bookingId }}",
  start: "Dátum",
  startTime: "Kezdési időpont",
  duration: "Időtartam",
  orderId: "Rendelésazonosító",
  totalToBePaid: "Összesen fizetendő (ÁFA-val)",
  totalWithVat: "Összesen (ÁFA-val)",
  billingData: "Számlázási adatok",
  updateBillingData: "Számlázási adatok",
  billingDataNotSet: "Számlázási adatok nincsenek beállítva!",
  clickToUpdateBillingData: "Kattintson a gombra a vállalati számlázási adatok módosításához.",
  selectBillingInformation: "Válassza ki a számlázási információkat",
  individual: "Fizikai személy",
  company: "Vállalat",
  durationMin: '{{ duration }} perc',
  payWithCard: 'Fizetés kártyával',
  agreeBookingTos: 'A foglalás folytatásával megerősítem, hogy elfogadom a \n <1>Használati feltételek</1> és a <4>Visszatérítési szabályzat</4>.',
  friendlyMatch: 'Barátságos Meccs',
  changePlayer: 'Játékos változtatása',
  additionalInfo: 'További információ’',
  bookACourt_tennis: 'Tartalékföld',
  bookACourt_padel: 'Tartalékföld',
  selfServiceCourts: 'Self-Service földek',
  cancellationWithRefund: 'Lemondás visszatérítéssel - legalább <1>{{ hours }} órával korábban</1>',
  available: 'Elérhető',
  notAvailable: 'Nem érhető el',
  yourBooking: 'Az én foglalásom',
  selfServiceCourtsPopupDescription: 'Minden mérkőzés végén a játékosoknak fel kell készíteni a pályát a következő mérkőzésre.',
  cancellationWithRefundPopupDescription: 'A klub foglalásait a kezdés előtt legalább {{ hours }} órával lehet lemondani.',
  failedToFetchData: 'A klub adatait nem sikerült lekérni.',
  myBookingTooltip: "Az én foglalásom <br /> {{ startHour }} - {{ endHour }} ({{ duration}} perc)",
  totalActiveCourts: '{{ totalCourts }} Pályák ({{ courtSurfaces }})',
  noAvailableCourts: 'Hamarosan elérhető lesz az online bírósági foglalás.',
  couldNotFetchCourtsBookings: 'A foglalásokat nem sikerült lekérni. Próbáld újra!’',
  upcomingNoBookings: 'Nem található foglalás',
  historyNoBookings: 'Nem található foglalás',
  myBookings: 'Foglalásaim',
  upcoming: 'Közelgő',
  history: 'Történelmi',
  confirmedStatus: 'Elkészült',
  canceledStatus: 'Visszavonás',
  pendingStatus: 'Függőben levő’',
  cancelBooking: 'Törölje a foglalást',
  refunded: 'Visszafizetés',
  lateCancellation: 'Késői lemondás',
  lateCancellationText: 'Biztosan törölni szeretné ezt a foglalást? \n\r A Klub lemondási szabályzata értelmében a foglalás törlésre kerül <1>VISSZATÉRÍTÉS NÉLKÜL</1>',
  earlyCancellation: 'Foglalás lemondása',
  earlyCancellationText: 'Biztosan törölni szeretné ezt a foglalást? \n\n A Klub lemondási szabályzata értelmében a foglalás törlésre kerül <1>VISSZATÉRÍTÉSSEL</1>',
  successPaymentStatus: 'Siker',
  pendingPaymentStatus: 'Függőben levő',
  refundedPaymentStatus: 'Visszafizetés',
  minBookingTime: 'Minimum Booking Time',
  sportType: 'Sport Type',
};
