import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import dateFormat from 'dateformat';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import mixpanel from '../../mixpanel';

import {
  Hero,
  Controls,
  NationalCalendar,
  Container,
  ListHeader,
  EventsContainer,
  Events,
  ContainFeatured,
  EventsOptions,
  ListFormat,
  GroupedEvents,
  RefPosition,
  PaginationWrapper,
} from './styles';

import FeaturedEvents from './components/FeaturedEvents';
import { setActiveFilters, fetchEvents, resetEventsLocations } from './reducers';
import EventFilters from './components/EventFilters';
// eslint-disable-next-line import/namespace
import SortBy from './components/SortBy';
import ContentLoader from '../../components/ContentLoader';
import Event from '../../components/Event';
import Pagination from '../../components/Pagination';
import LoadImage from '../../components/common/LoadImage';

import AdvancedFilters from './components/EventFilters/AdvancedFilters';
import FilterButton from './components/FilterButton';

import Modal from '../../components/Modal/Modal';
import useModal from '../../components/Modal/useModal';

import {
  H2, Button, P, Alert, Contain,
} from '../../components/Collection';

import NoResultsBanner from './components/NoResultsBanner';
import DuracellAndPringles from '../../components/Campaign/DuracellAndPringles';

const ListEvents = ({ isBanned, masterSport }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('events');

  const router = useLocation();
  const {
    location,
    radius,
    coordinates,
    endedEvents,
    gameType,
  } = router?.state || {};

  const { data: events, status, sportType } = useSelector(state => state.events?.list);
  const { data: activeFilters } = useSelector(state => state.events?.activeFilters);
  const { data: { eligibleCountries } = {} } = useSelector(state => state.settings);
  const { isLoggedIn } = useSelector(state => state.session);
  const [listFormatRows, setListFormatRows] = useState(true);
  const [filters, setFilters] = useState(router?.state ? {
    location,
    radius,
    gameType,
    coordinates,
    endedEvents,
  } : (!isEmpty(activeFilters) ? JSON.parse(JSON.stringify(activeFilters)) : {}));

  const [formFilters, setFormFilters] = useState({ ...filters });
  const [currentPage, setCurrentPage] = useState(activeFilters.page ? activeFilters.page - 1 : 0);
  const [displayFeatured, setDisplayFeatured] = useState(false);
  const hasResults = !isEmpty(events?.results) && status !== 'loading';
  const isLoading = status === 'loading';
  const totalActiveFilters = Object.keys(filters)
    .filter(key => ['category', 'gender', 'minAge' || 'maxAge', 'playMode'].includes(key)).length;

  const initialOptions = {
    page: activeFilters.page ? activeFilters.page : 1,
    limit: 20,
  };

  const [options, setOptions] = useState(initialOptions);
  const [readableFilters, setReadableFilters] = useState();
  const [sortByDistance, setSortByDistance] = useState(false);
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    if (Cookies.get('listFormat')) {
      const content = Cookies.get('listFormat');
      setListFormatRows(content === 'row');
    }

    mixpanel.track('Visit Find an Event', {
      sp_find_an_event_logged_in: isLoggedIn,
    });
  }, []);

  useEffect(() => {
    if (status === 'idle' || (sportType && sportType !== masterSport && status === 'succeeded')) {
      dispatch(fetchEvents({ filters, options }));
      dispatch(resetEventsLocations());
    }
  }, [status, dispatch, masterSport, sportType]);

  useEffect(() => {
    if (status === 'succeeded') {
      if (filters.sortBy) {
        options.sortBy = filters.sortBy;
      }
      dispatch(fetchEvents({ filters, options }));
      dispatch(resetEventsLocations());
    }

    if (filters.location) {
      setReadableFilters(`${filters.location.replace('null, ', '')}`);
    } else {
      setReadableFilters(null);
    }
  }, [filters, options]);

  const dispatchFilters = data => {
    if (!isEmpty(data)) {
      // eslint-disable-next-line no-param-reassign
      delete data.limit;
      // eslint-disable-next-line no-param-reassign
      delete data.role;
      // eslint-disable-next-line no-param-reassign
      delete data.page;
    }

    dispatch(setActiveFilters(data));
  };

  const handleListFormat = () => {
    setListFormatRows(!listFormatRows);

    if (!listFormatRows) {
      Cookies.set('listFormat', 'row', {
        expires: 30,
        path: '/',
      });
    } else {
      Cookies.set('listFormat', 'grid', {
        expires: 30,
        path: '/',
      });
    }
  };

  const handleFilters = (name, value) => {
    const predefinedFilters = ['availableEntries', 'endedEvents'];
    const isEndedEvents = name === 'endedEvents';
    const isAvailableEntries = name === 'availableEntries';
    const isAllActive = name === 'allActive';
    let newFilters = {
      ...filters,
    };

    predefinedFilters.forEach(item => {
      if (newFilters[item]) {
        delete newFilters[item];
        delete filters[item];
      }
    });

    if (predefinedFilters.includes(name)) {
      newFilters = {
        ...filters,
        [name]: value,
      };
    }

    delete newFilters.allEvents;

    if (isEndedEvents) {
      setOptions({ ...options, sortBy: 'createdAt:desc' });
    }

    if (isEndedEvents) {
      setOptions({ ...options, sortBy: 'createdAt:desc' });
    }

    if (isAvailableEntries || isAllActive) {
      setOptions({ ...options, sortBy: 'startDate:asc' });
    }

    handlePagination({ selected: 0 });
    setFilters(newFilters);
    dispatchFilters(newFilters);
  };

  // eslint-disable-next-line no-shadow
  const handleCalendar = ({ location }) => {
    const newFilters = {
      ...filters.location !== location && {
        location,
      },
    };

    handlePagination({ selected: 0 });
    setFilters(newFilters);

    setFormFilters({
      ...formFilters.location !== location && {
        location,
      },
    });

    dispatchFilters(newFilters);
    triggerScroll();
  };

  function handleModalFilters(data) {
    const modalFilters = {};

    Object.keys(data).forEach(item => {
      if (['minAge', 'maxAge'].includes(item)) {
        if (data[item]) {
          modalFilters[item] = data[item];
        } else {
          delete filters[item];
        }
      } else if (!isEmpty(data[item])) {
        modalFilters[item] = data[item];
      } else {
        delete filters[item];
      }
    });

    const newFilters = {
      ...filters,
      ...modalFilters,
    };

    handlePagination({ selected: 0 });
    setFilters(newFilters);
    dispatchFilters(newFilters);
    triggerScroll();
  }

  const triggerScroll = () => {
    listRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const listRef = useRef(null);

  const handlePagination = (data) => {
    const page = data.selected + 1;

    if (currentPage !== data.selected) {
      setOptions({
        ...options,
        page,
      });
      setCurrentPage(data.selected);
    }

    dispatch(setActiveFilters({ ...filters, page }));
  };

  const eventsFound = readableFilters
    ? (events?.totalResults === 1 ? 'eventFound' : 'eventsFound')
    : (events?.totalResults === 1 ? 'eventFoundNc' : 'eventsFoundNc');

  const sortEvents = () => {
    const eventsSorted = {};

    events?.results?.forEach(event => {
      const date = dateFormat(event?.startDate, 'mmmm yyyy');
      if (!eventsSorted[date]) {
        eventsSorted[date] = [];
      }
      eventsSorted[date].push(event);
    });
    return eventsSorted;
  };

  const sortByNewest = options.sortBy === 'createdAt:desc';
  const sortByClosest = options.sortBy === 'closest';

  const isSelected = country => filters?.location === `null, ${country}`;

  return (
    <>
      <Hero isBanned={isBanned}>
        <Helmet>
          <title>{t('seoTitle')}</title>
        </Helmet>
        <Controls>
          <H2
            color="white"
            margin="0 0 20px 0"
          >
            <Trans ns="events" i18nKey={`${masterSport}QuickFindTitle`}>
              Quickly find the best <span>{masterSport}</span> event for you
            </Trans>
          </H2>
          <EventFilters {...{
            filters, setFilters, formFilters, setFormFilters, handlePagination, triggerScroll, isBanned,
          }}
          />
          <NationalCalendar>
            <P
              large
              margin="0 0 20px 0"
              color="white"
            >
              <Trans ns="events" i18nKey={`${masterSport}NationalCalendar`}>
                Choose your National <u>{masterSport}</u> Calendar
              </Trans>
            </P>
            <Contain
              maxWidth="750px"
              flexFlow="wrap"
              align="center"
              justify="center"
              width="100%"
              className="countries"
            >
              {eligibleCountries?.filter(({ key }) => !['hu', 'cz'].includes(key)).map((country, key) => {
                const selectedCountry = isSelected(country.name.split(' ')[0]);
                return (
                  <Button
                    key={key}
                    {...selectedCountry && { className: 'selected' }}
                    onClick={() => handleCalendar({ location: `null, ${country.name.split(' ')[0]}` })}
                    boxShadow="#fff"
                    minWidth="140px"
                    width="max-content"
                    padding="0 20px"
                    noImgMargin
                  >
                    <img
                      src={LoadImage(`flags/${country.key}${selectedCountry ? '-border' : ''}.svg`)}
                      alt={`${country.name} Flag`}
                    />
                    <Trans ns="common" i18nKey={`countriesByKey.${country.key}`}>
                      {country.name}
                    </Trans>
                  </Button>
                );
              })}
            </Contain>
          </NationalCalendar>
        </Controls>
        <Button
          className="showFeatured"
          minWidth="220px"
          padding="0 20px"
          boxShadow="#fff"
          small
          onClick={() => setDisplayFeatured(!displayFeatured)}
        >
          <P
            small
            bold
            color="white"
            margin="0 10px 0 0"
          >
            <Trans ns="events" i18nKey={!displayFeatured ? 'showFeaturedEvents' : 'hideFeaturedEvents'}>
              Show Featured Events
            </Trans>
          </P>
          <img src={LoadImage(`${!displayFeatured ? 'expand.svg' : 'collapse.svg'}`)} alt="Show Featured Events" />
        </Button>
      </Hero>
      <ContainFeatured displayFeatured={displayFeatured}>
        <FeaturedEvents />
      </ContainFeatured>
      <Container grid={!listFormatRows}>
        <ListHeader>
          <RefPosition ref={listRef} />
          {listFormatRows && (
            <>
              <P size={24} margin="0 0 12px 0">
                <Trans ns="events" i18nKey={eventsFound} count={events?.totalResults || 0}>
                  <strong>{{ totalResults: events?.totalResults || 0 }} events</strong>
                  found
                  {{ location: readableFilters }}
                </Trans>
              </P>
              <SortBy
                className="desktop"
                {...{
                  options, filters, setOptions, setSortByDistance, sortByDistance,
                }}
              />
            </>
          )}
        </ListHeader>
        <Contain className="eventListing">
          <EventsContainer>
            <EventsOptions grid={!listFormatRows}>
              {!listFormatRows && (
                <Contain direction="column" margin="0 auto 20px 0" className="desktop">
                  <P size={24} margin="0 0 12px 0">
                    <Trans ns="events" i18nKey={eventsFound} count={events?.totalResults || 0}>
                      <strong>{{ totalResults: events?.totalResults || 0 }} events</strong>
                      found
                      {{ location: readableFilters }}
                    </Trans>
                  </P>
                  <SortBy {...{
                    options, filters, setOptions, setSortByDistance, sortByDistance,
                  }}
                  />
                </Contain>
              )}
              <Contain overflowX="auto">
                <FilterButton
                  active={!filters?.availableEntries && !filters?.endedEvents && !filters.allEvents}
                  onClick={() => handleFilters('allActive', true)}
                  margin="0 0 0 auto"
                >
                  <Trans ns="events" i18nKey="allActive">All Active</Trans>
                </FilterButton>
                <FilterButton
                  active={filters?.availableEntries}
                  onClick={() => handleFilters('availableEntries', true)}
                >
                  <Trans ns="events" i18nKey="availableEntries">Available Entries</Trans>
                </FilterButton>
                <FilterButton
                  active={filters?.endedEvents}
                  onClick={() => handleFilters('endedEvents', true)}
                >
                  <Trans ns="events" i18nKey="showEndedEvents">Ended Events</Trans>
                </FilterButton>
              </Contain>
              <Button
                small
                outline
                noBorder
                noImgMargin
                minWidth="116px"
                margin="0 0 0 30px"
                padding="15px 18px"
                className="filters"
                onClick={() => toggle(!isShowing)}
              >
                <img src={LoadImage('filter.svg')} alt="" />
                <P bold margin="0 0 0 10px">{t('filter_all')}</P>
                {totalActiveFilters > 0 && (
                  <P small bold color="white" className="filters-amount">{totalActiveFilters}</P>)}
              </Button>
              <Contain>
                <SortBy
                  className="mobile sorting"
                  {...{
                    options, filters, setOptions, setSortByDistance, sortByDistance,
                  }}
                />
                <Contain
                  height="60px"
                  justify="end"
                  align="center"
                  margin="0 0 0 20px"
                  className="listFormat"
                >
                  <ListFormat margin="0 15px 0 0" onClick={handleListFormat} {...listFormatRows && { active: true }}>
                    <img src={LoadImage('rows-icon.svg')} alt="Display events as rows" />
                  </ListFormat>
                  <ListFormat onClick={handleListFormat} {...!listFormatRows && { active: true }}>
                    <img src={LoadImage('cells-icon.svg')} alt="Display events as grid" />
                  </ListFormat>
                </Contain>
              </Contain>
            </EventsOptions>
            <Events grid={!listFormatRows && hasResults && !isLoading}>
              {/* loader */}
              {isLoading && listFormatRows && (
                <ContentLoader title={false} type="eventsList" items={options.limit} />
              )}

              {/* result grouped by month */}
              {hasResults && !sortByNewest && !sortByClosest && listFormatRows && (
                Object.keys(sortEvents()).map((month, key) => (
                  <GroupedEvents key={key} className="groupedEvents">
                    <P large key={key} className="eventsDate">{month}</P>
                    {sortEvents()[month]?.map((event, id) => (
                      <>
                        <Event key={id} event={event} grid={!listFormatRows} />
                        {key === 0 && id === 1 && (<DuracellAndPringles section="events" />)}
                      </>
                    ))}
                  </GroupedEvents>
                ))
              )}

              {/* if sort by newest */}
              {hasResults && !(!sortByNewest && !sortByClosest && listFormatRows) && (
                events?.results?.map((event, id) => (
                  <Event key={id} event={event} grid={!listFormatRows} />
                ))
              )}

              {/* no results */}
              {!hasResults && !isLoading && (
                <>
                  <Alert persist margin="0 auto" background="#C1E6E5">
                    <P bold>
                      <Trans ns="events" i18nKey="noEventsFound">No events found.</Trans>
                    </P>
                  </Alert>
                  {isSelected(filters?.location?.split(' ')[1]) && (
                    <NoResultsBanner />)}
                </>
              )}
            </Events>
          </EventsContainer>
        </Contain>
        <Modal
          type2
          noborder
          nobackground
          isShowing={isShowing}
          hide={toggle}
          header
          noMarginFormGroup
          title={t('filters')}
          nooverflow
        >
          <AdvancedFilters {...{
            handleModalFilters,
            toggle,
            setFilters,
            filters,
            handlePagination,
            dispatchFilters,
            masterSport,
          }}
          />
        </Modal>

        <PaginationWrapper onClick={() => triggerScroll()}>
          <Pagination
            pageCount={events?.totalPages || 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePagination}
            forcePage={currentPage}
          />
        </PaginationWrapper>
      </Container>
    </>
  );
};

export default ListEvents;
