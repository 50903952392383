import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { Statistic, StatisticContent, StatsContainer } from '../styles';

import { H4, P } from '../../../../../components/Collection';
import { UserContext } from '../../..';

const FriendlyMatchStats = ({ isOnDashboard }) => {
  const userContext = useContext(UserContext);
  const { masterSport } = useSelector(state => state.session);
  const profile = userContext?.profile || 'myProfile';

  const { stats: { data } } = useSelector(state => state.accounts.info.tabs[profile]);
  const { friendlyMatches: { singlesPlayed, doublesPlayed } = {} } = data || {};

  return (
    <StatsContainer extend isOnDashboard={isOnDashboard}>
      <P large bold>
        <Trans ns="friendlyMatches" i18nKey="friendlyMatches" context={masterSport}>
          Friendly Match Stats
        </Trans>
      </P>

      <P small bold margin="20px 0 10px 0">
        <Trans ns="dashboard" i18nKey="accountLeaderboard.singles">
          Singles
        </Trans>
      </P>
      <Statistic>
        <StatisticContent>
          <P xSmall>{new Date().getFullYear()}</P>
          <H4>{singlesPlayed?.currentYear?.total || 0}</H4>

          <div className="mt20 text-center">
            <P xSmall className="mb10">
              <Trans ns="dashboard" i18nKey="rankedMatches">
                <strong>{{ ranked: singlesPlayed?.currentYear?.ranked }}</strong>
                {' '}
                Ranked
              </Trans>
            </P>
            <P xSmall className="mt10">
              <Trans ns="dashboard" i18nKey="unrankedMatches">
                <strong>{{ unranked: singlesPlayed?.currentYear?.unranked }}</strong>
                {' '}
                Unranked
              </Trans>
            </P>
          </div>
        </StatisticContent>
        <StatisticContent noBorder>
          <P xSmall><Trans ns="dashboard" i18nKey="allTime">All Time</Trans></P>
          <H4>{singlesPlayed?.allTime?.total || 0}</H4>

          <div className="mt20 text-center">
            <P xSmall className="mb10">
              <Trans ns="dashboard" i18nKey="rankedMatches">
                <strong>{{ ranked: singlesPlayed?.allTime?.ranked }}</strong>
                {' '}
                Ranked
              </Trans>
            </P>
            <P xSmall className="mt10">
              <Trans ns="dashboard" i18nKey="unrankedMatches">
                <strong>{{ unranked: singlesPlayed?.allTime?.unranked }}</strong>
                {' '}
                Unranked
              </Trans>
            </P>
          </div>
        </StatisticContent>
      </Statistic>

      <P small bold margin="20px 0 10px 0">
        <Trans ns="dashboard" i18nKey="accountLeaderboard.doubles">
          Doubles
        </Trans>
      </P>
      <Statistic>
        <StatisticContent>
          <P xSmall>{new Date().getFullYear()}</P>
          <H4>{doublesPlayed?.currentYear?.total || 0}</H4>

          <div className="mt20 text-center">
            <P xSmall className="mb10">
              <Trans ns="dashboard" i18nKey="rankedMatches">
                <strong>{{ ranked: doublesPlayed?.currentYear?.ranked }}</strong>
                {' '}
                Ranked
              </Trans>
            </P>
            <P xSmall className="mt10">
              <Trans ns="dashboard" i18nKey="unrankedMatches">
                <strong>{{ unranked: doublesPlayed?.currentYear?.unranked }}</strong>
                {' '}
                Unranked
              </Trans>
            </P>
          </div>
        </StatisticContent>
        <StatisticContent noBorder>
          <P xSmall><Trans ns="dashboard" i18nKey="allTime">All Time</Trans></P>
          <H4>{doublesPlayed?.allTime?.total || 0}</H4>

          <div className="mt20 text-center">
            <P xSmall className="mb10">
              <Trans ns="dashboard" i18nKey="rankedMatches">
                <strong>{{ ranked: doublesPlayed?.allTime?.ranked }}</strong>
                {' '}
                Ranked
              </Trans>
            </P>
            <P xSmall className="mt10">
              <Trans ns="dashboard" i18nKey="unrankedMatches">
                <strong>{{ unranked: doublesPlayed?.allTime?.unranked }}</strong>
                {' '}
                Unranked
              </Trans>
            </P>
          </div>
        </StatisticContent>
      </Statistic>
    </StatsContainer>
  );
};

export default FriendlyMatchStats;
