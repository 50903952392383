/* eslint-disable max-len */
import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';

import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import {
  P, Link, Contain, Button,
} from '../../../../components/Collection';

import {
  Avatar, ContainAvatar, FriendsIcon, ID, Initials, Player,
} from './styles';

import getAgeFromDate from '../../../../components/common/getAgeFromDate';
import LoadImage from '../../../../components/common/LoadImage';
import LevelsBadges from '../../../../components/LevelsBadges';
import ToggleVisible from '../../../../components/common/ToggleVisible';
import { DropdownItemContainer } from '../../../PlayerProfile/components/TopSection/components/Options/components/VisitedProfileOptions/components/styles';
import { addConversation } from '../../../Chat/actions';
import BlockModal from '../../../PlayerProfile/components/TopSection/components/Options/components/VisitedProfileOptions/components/components/BlockModal';
import ReportModal from '../../../PlayerProfile/components/TopSection/components/Options/components/VisitedProfileOptions/components/components/ReportModal';
import UnfriendModal from '../../../PlayerProfile/components/TopSection/components/Options/components/VisitedProfileOptions/components/components/UnfriendModal';
import { acceptFriend, blockPlayer, deleteFriend } from '../../../MyAccount/Settings/reducers';

import { timeSince } from '../../../../components/common/timeSince';
import mixpanel from '../../../../mixpanel';

const Dropdown = styled.div(() => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 8px 34px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  backgroundColor: 'white',
  top: '35px',
  left: '50%',
  transform: 'translateX(-50%)',
  maxWidth: '199px',
  zIndex: 1000,
}));

export const FriendContext = createContext();

const FriendCard = ({
  player,
  status,
  socketClientId,
  initiatingChat,
  setInitiatingChat,
  requestedAt,
  friendsCount,
  setFriendsCount,
}) => {
  const {
    profilePicture: photo,
    gameLevel,
    firstName,
    lastName,
    userId,
    id,
    birthDate: age,
  } = player;
  const time = timeSince(new Date(requestedAt));
  const location = player?.location?.name ?? '';
  const fullName = `${firstName} ${lastName}`;

  const { addToast } = useToasts();
  const { t: commonT } = useTranslation('common');
  const [modal, setModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [unfriendModal, setUnfriendModal] = useState(false);
  const [reportReason, setReportReason] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);

  const contextValue = useMemo(() => ({
    user: player,
    socketClientId,
    isBlocked: false,
  }), [player, status]);

  const dispatch = useDispatch();

  const handleDropdown = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const initiateConversation = async () => {
    const payload = {
      members: [player],
    };

    await addConversation({
      payload,
      addToast,
      t: commonT,
    });

    setInitiatingChat(true);
  };

  const modalHandler = () => {
    if (!modal) {
      mixpanel.track('Block Player from Friend player actions (in Friends section)');
    }
    setModal(!modal);
    setIsComponentVisible(false);
  };

  const unfriendModalHandler = () => {
    if (!unfriendModal) {
      mixpanel.track('Unfriend from All Friends (section in Friends) - click Unfriend from All Friends');
    }
    setUnfriendModal(!unfriendModal);
  };

  const confirmationModalHandler = () => {
    setConfirmationModal(!confirmationModal);
  };

  const reportModalHandler = () => {
    if (!reportModal) {
      mixpanel.track('Report Player from Friend player actions (in Friends section)');
    }
    setReportModal(!reportModal);
    setIsComponentVisible(false);
    setReportReason(null);
  };

  const block = () => {
    mixpanel.track('Confirm [Block] in confirmation dialog');
    dispatch(blockPlayer({ blockingPlayerId: id, socketClientId }));
    setModal(!modal);
  };

  const friendCancel = () => {
    dispatch(deleteFriend({ data: { friendUserId: id, socketClientId } }));
    setUnfriendModal(false);
  };

  const friendAccept = () => {
    dispatch(acceptFriend({ friendUserId: id, socketClientId }));
  };

  useEffect(() => {
    afterShow();
  }, [isComponentVisible]);

  const afterShow = () => {
    const popup = ref?.current?.getBoundingClientRect();
    const overflownLeft = popup?.left < 0;
    const overflownRight = popup?.right > window?.innerWidth;
    if (overflownLeft) {
      ref?.current?.style.setProperty('transform', 'translateX(0%)');
    } else if (overflownRight) {
      ref?.current?.style.setProperty('left', '-30%');
      ref?.current?.style.setProperty('transform', 'translateX(-70%)');
    }
  };

  return (
    <Player>
      {status === 'accepted' && (
        <Contain>
          <Contain
            position="absolute"
            right="10px"
            top="5px"
            cursor="pointer"
            width="40px"
            height="40px"
            align="center"
            justify="center"
            onClick={() => { handleDropdown(); }}
          >
            <img src={LoadImage('three-dots-icon.svg')} width="20px" height="5px" alt="options" />
            {isComponentVisible && (
              <Dropdown ref={ref}>
                <DropdownItemContainer
                  onClick={() => {
                    initiateConversation();
                    mixpanel.track('Initiate Chat from Friends (Click on Chat in player action menu in All Friends)');
                  }}
                  disabled={initiatingChat}
                >
                  <Contain margin="0 10px 0 0">
                    <img src={LoadImage('chat-icon.svg')} width="20px" height="20px" alt="chat" />
                  </Contain>
                  <Trans ns="settings" i18nKey="chat">
                    Chat
                  </Trans>
                </DropdownItemContainer>
                <DropdownItemContainer onClick={() => { unfriendModalHandler(); }}>
                  <Contain margin="0 10px 0 0">
                    <img src={LoadImage('black-cancel-request-icon.svg')} width="20px" height="20px" alt="unfriend" />
                  </Contain>
                  <Trans ns="settings" i18nKey="unfriend">
                    Unfriend
                  </Trans>
                </DropdownItemContainer>
                <DropdownItemContainer onClick={() => { modalHandler(); }}>
                  <Contain margin="0 10px 0 0">
                    <img src={LoadImage('block-icon.svg')} width="20px" height="20px" alt="block player" />
                  </Contain>
                  <span className="dropdown-text">
                    <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.blockPlayer">
                      Block player
                    </Trans>
                  </span>
                </DropdownItemContainer>
                <DropdownItemContainer onClick={() => { reportModalHandler(); }}>
                  <Contain margin="0 10px 0 0">
                    <img src={LoadImage('report-icon.svg')} width="20px" height="20px" alt="report player" />
                  </Contain>
                  <span className="dropdown-text">
                    <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.reportPlayer">
                      Report Player
                    </Trans>
                  </span>
                </DropdownItemContainer>
              </Dropdown>
            )}
          </Contain>
        </Contain>
      )}
      <ContainAvatar to={`/accounts/${id}`}>
        {photo
          ? (
            <Avatar
              src={LoadImage(`accounts/${userId}/${photo}`, true)}
              alt={`${firstName.charAt(0)} ${lastName.charAt(0)}`}
            />
          )
          : (
            <Initials>
              <P large bold>{firstName && lastName ? firstName.charAt(0) + lastName.charAt(0) : ''}</P>
            </Initials>
          )}
        <LevelsBadges white small level={gameLevel?.singles ?? '?'} levelDouble={gameLevel?.doubles ?? '?'} className="badge" />
      </ContainAvatar>

      <Link to={`/accounts/${id}`} className="Player Name">
        <P bold className="ellipsis">
          {status === 'accepted' && (
            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
          )}
          {`${firstName} ${lastName}`}
          <ID># {userId}</ID>
        </P>
      </Link>
      {age && location ? (
        <P small>
          {getAgeFromDate(age)} - {location}
        </P>
      ) : ''}

      {status === 'received' && (
        <Contain justify="space-between" margin="31px 0 10px 0">
          <Button
            onClick={() => {
              friendAccept();
              mixpanel.track('Confirm Request from received Friend Requests (section in Friends)');
            }}
            left
            width="109px"
            padding="10px 24px"
            fontSize="14px"
            small
            className="mr10"
          >
            <Trans ns="friends" i18nKey="confirm">Confirm</Trans>
          </Button>

          <Button
            onClick={() => {
              friendCancel();
              mixpanel.track('Decline Request from received Friend Requests (section in Friends)');
            }}
            outline
            right
            width="109px"
            padding="10px 24px"
            fontSize="14px"
            small
          >
            <Trans ns="friends" i18nKey="decline">Decline</Trans>
          </Button>
        </Contain>
      )}

      {status === 'sent' && (
        <Contain justify="space-between" margin="31px 0 10px 0">
          <Button
            onClick={() => { friendCancel(); mixpanel.track('Cancel Request from My Sent Requests (in Friends)'); }}
            outline
            right
            width="227px"
            padding="10px 24px"
            fontSize="14px"
            small
          >
            <Trans ns="friends" i18nKey="cancelRequest">Cancel Request</Trans>
          </Button>
        </Contain>
      )}

      {status !== 'accepted' && (
        <P small color="grey2">
          {time}
        </P>
      )}

      <FriendContext.Provider value={contextValue}>
        {reportModal && (
          <ReportModal
            modalHandler={reportModalHandler}
            setReportReason={setReportReason}
            reportReason={reportReason}
            fullName={fullName}
            reportModal={reportModal}
            setReportModal={setReportModal}
            content={player}
            socketClientId={socketClientId}
            setConfirmationModal={setConfirmationModal}
          />
        )}

        {confirmationModal && (
          <ReportModal
            modalHandler={confirmationModalHandler}
            blocked
          />
        )}

        {modal && (
          <BlockModal modalHandler={modalHandler} block={block} fullName={fullName} />
        )}

        {unfriendModal && (
          <UnfriendModal
            handler={unfriendModalHandler}
            fullName={fullName}
            unfriend={friendCancel}
            friendsCount={friendsCount}
            setFriendsCount={setFriendsCount}
          />
        )}
      </FriendContext.Provider>
    </Player>
  );
};

export default FriendCard;
