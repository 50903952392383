export default {
  RAISE_HAND_SUCCESS: 'You raised your hand for a friendly match.',
  LOWER_HAND_SUCCESS: 'You lowered your hand.',
  createMatch: 'New Match',
  createNewMatchInvite: 'New Match Invite',
  addPlayedMatch: 'Add Played Match',
  raiseHand: {
    text: 'Raise Hand',
    title: 'Raise Hand for a Friendly Match!',
    description: 'Show other players that you\'re eager to play immediately.',
    gameTypeLabel: 'Play a friendly match for',
    within: 'Within',
    next3Days: 'Next 3 days',
    next7Days: 'Next 7 days',
    thisWeekend: 'This Weekend',
    withPlayers: 'With Players',
    aboveMyLevel: 'My level +/-1',
    myLevel: 'Only my level',
    lower: 'Lower Hand',
    daysLeft: '{{time}} days',
    dayLeft: '{{time}} day',
    singlesAndDoubles: 'Singles & Doubles',
    sameLevelDescription: 'Same Level',
    aboveLevelDescription: 'Level +/-1',
    tooltip: 'Eager to play a Friendly Match immediately',
  },
  startMatch: 'Start',
  startMatchButton: 'Start Match',
  activeMatches: 'Matches <1>({{ matches }})</1>',
  requests: 'Requests <1>({{ requests }})</1>',
  scores: 'Scores',
  friendlyMatches: 'Friendly Matches',
  friendlyMatches_tennis: 'Tennis Friendly Matches',
  friendlyMatches_padel: 'Padel Friendly Matches',
  inviteFriends: 'Invite your friends or other players to a new match, or add your already played match to Sportya',
  wantToPlay: 'Want to Play',
  wantToPlayPopUp: 'Players who raised their hands for an immediate friendly match.',
  viewMore: 'View More',
  nearby: 'Nearby',
  distanceAway: '{{ kmDistance }} km',
  invite: 'Invite',
  rematch: 'Rematch',
  invitePlayerInArea: 'Invite a Player in your Area',
  invitePlayerInAreaPopUp: 'Players within a 30 km proximity radius around the location set in your Sportya profile',
  otherTopPicks: 'Other Top Picks',
  otherTopPicksPopUp: 'Players you previously met in friendly or competition matches and/or your eligible friends on Sportya.',
  sportyaPartnerClubs: 'Sportya Partner Clubs',
  partnerClubsPopup: 'Sportya Partner Clubs are selected sports venues in which you can participate in Sportya events or friendly matches.',
  searchSpecificPlayer: 'Want to search for a specific player?',
  findAPlayer: 'Find a Player',
  singlesMatch: 'Singles Match',
  doublesMatch: 'Doubles Match',
  singlesFriendlyMatch: 'Singles Friendly Match',
  doublesFriendlyMatch: 'Doubles Friendly Match',
  scoresData: {
    opponent: 'Opponent',
    opponents: 'Opponents',
  },
  ranked: 'Ranked',
  unranked: 'Unranked',
  matchType: 'Match Type',
  matchFormat: 'Match Format',
  createdBy: 'Created by',
  players: 'Players',
  yourTeam: 'Your Team',
  opponentTeam: 'Opponent Team',
  viewInActivity: 'View in Activity',
  reportMatch: 'Report Match',
  matchId: 'Match ID: {{ matchId }}',
  singleSet: 'Single Set',
  '2setsWithSuperTieBreak': '2 Sets + Super Tiebreak',
  '2setsOutOf3': '2 Sets out of 3',
  later: 'Not selected',
  playFriendlyMatch: 'Play a Friendly Match',
  playFriendlyMatchSubtitle: 'Be the first one to show that you want to play a friendly match as soon as possible!',
  noMatch: 'No Match',
  editMatch: 'Edit Match',
  cancelMatch: 'Cancel Match',
  confirmMatch: 'Confirm Match',
  withdraw: 'Withdraw',
  goToFriendlyMatches: 'Go to Friendly Matches',
  requestsArrow: 'Requests',
  matches: 'Matches',
  latestRequest: 'Latest Request',
  nextMatch: 'Next Match',
  yourMatch: 'Your Match',
  noRequestsOrMatches: 'No Requests or Matches?',
  noRequestsOrMatchesDescription: 'Invite your friends or other players to a new match, or add your already played match to Sportya',
  modalAcceptMatchTitle: 'Accept Match Invite',
  modalDeclineMatchTitle: 'Decline Match Invite',
  modalCancelMatchTitle: 'Cancel Match',
  modalNoMatchFromRequestsTitle: 'No-Match',
  modalNoMatchFromMatchesTitle: 'No-Match',
  modalAcceptMatchSubtitle: 'Do you accept the match invitation?',
  modalDeclineMatchSubtitle: 'Are you sure you want to decline this match invitation?',
  modalCancelMatchSubtitle: 'Are you sure you want to cancel this match?',
  modalNoMatchFromRequestsSubtitle: 'Are you sure you want to report this match as undisputed?',
  modalNoMatchFromMatchesSubtitle: 'Are you sure you want to report this match as undisputed?',
  modalWithdrawSubtitle: 'Are you sure you want to withdraw from this match?',
  accept: 'Accept',
  acceptMatch: 'Accept Match',
  decline: 'Decline',
  cancel: 'Cancel',
  goBack: 'Go Back',
  mark: 'No Match',
  addScore: 'Add Score',
  confirmScore: 'Confirm Score',
  changeScore: 'Change Score',
  abandoned: 'Abandoned',
  addScoreInfo: 'Your score will be pending confirmation from the other participants for up to 48h after which it will be automatically validated and points will be generated in case of a Ranked match.',
  changeScoreInfo: 'If the current score is incorrect, please fill in and confirm the match with the correct score. New scores will be pending confirmation from the other participants for a maximum of 48h before being validated.',
  confirmScoreInfo: 'If the current score is incorrect, please fill in and confirm the match with the correct score. New scores will be pending confirmation from the other participants for a maximum of 48h before being validated.',
  confirmMatchScoreInfo: 'If the current score is incorrect, please fill in and confirm the match with the correct score. New scores will be pending confirmation from the other participants for a maximum of 48h before being validated.',
  played: 'Played',
  seeLocation: 'See on Map',
  noActiveMatches: 'No Active Matches',
  noMatchesSubtitle: 'You have no friendly matches scheduled at the moment. Invite your friends or other players and start a new match now!',
  noOpenRequests: 'No Open Requests',
  noRequestsSubtitle: 'You have no open match requests at the moment. Invite your friends or other players and start a new match now!',
  noScores: 'No Scores',
  noScoresSubtitle: 'You have not played any friendly matches with validated scores yet. Please ensure that scores have been submitted and validated for all matches you have played so far, or invite your friends or other players and start a new match now!',
  reportReasons: {
    reason: 'Reason',
    selectTheReason: 'Select the Reason',
    incorrectScore: 'Incorrect Score',
    undisputedMatch: 'Undisputed Match',
    other: 'Other',
    reportTextAreaInfo: 'Please provide additional information (optional)',
  },
  reportedMatch: 'You reported this match',
  reportedMatchDescription: 'We will review your report and get back to you if any information is required.',
  dateAndTime: 'Date & Time',
  clubOrAddress: 'Club or Address',
  minute: 'Minute',
  hour: 'Hour',
  format: 'Format',
  decideLater: 'Decide Later',
  sendInvite: 'Send Invite',
  captain: 'Captain',
  partner: 'Partner',
  pending: 'Pending',
  accepted: 'Accepted',
  declined: 'Declined',
  score: 'Score',
  tie: 'Tie',
  matchDetails: 'Match Details',
  selectMatchType: 'Select the Match Type',
  proposedMatchFormat: 'Proposed Match Format',
  countrySelect: 'Showing locations from {{country}} <2>Change Country</2>',
  playerMinMaxLevelError: 'Opponent level not compatible for your Ranked Match. Please select a player within the Level Range {{min}}-{{max}}, or switch to Unranked Match to continue playing with the selected player.',
  teamMinMaxLevelError: 'Team average game level not compatible for your Ranked Match. Please select a team within the Average Level Range {{min}}-{{max}}, or switch to Unranked Match to continue playing with the selected team.',
  pendingScorePopup: 'Score Pending Confirmation - Please confirm the score or submit any corrections in {{ dueTime }}',
  invitationDeclined: 'Invitation Declined - Please review match details and take any necessary actions',
  playerWithdrawn: 'Withdrawn Player - Please review match details and take any necessary actions',
  noMatchReported: 'Player reported No-Match - Please review match details and take any necessary actions',
  inactiveScoreUpdate: 'The score cannot be added/confirmed/changed at the moment due to player withdrawals/unconfirmed players',
  decideGameFormatLater: 'Players can decide the game format later or complete it after the match',
  hasTimeLimitError: 'Sorry, but you cannot add a played Ranked match older than 5 days. The system only allows played Ranked matches to be added within 5 days of the actual match date. You can add this match as an Unranked match without points instead if you still want to add it to your match history.',
  confirmMatchScore: 'Confirm Match & Score',
  rankedFriendlyMatches: 'Ranked Friendly Matches',
  generalRank: 'General Rank',
  rankedMatch: 'Ranked Match',
  unrankedMatch: 'Unranked Match',
  totalMatches: 'Total Matches',
  allActivity: 'All Activity',
  minutesUntil: 'due in {{time}} min',
  hoursUntil: 'due in {{time}} hrs',
  daysUntil: 'due in {{time}} days',
  monthsUntil: 'due in {{time}} mos',
  yearsUntil: 'due in {{time}} yrs',
  updateMatch: 'Update Match',
  saveMatch: 'Save Match',
  gameType: 'Game Type',
  generatesPoints: 'Winners get points in Rankings. Match format only: 2 Sets out of 3.',
  doesntGeneratePoints: 'Winners don\'t get points in Rankings. Diverse Match formats are available.',
  matchInvitation: 'Match Invitation',
  playedMatch: 'Played Match',
  editParticipantWarning: 'Please be aware that changing any player in the match will reset any existing scores, therefore scores will have to be submitted again and confirmed for validation.',
  playedMatchForm: {
    title: 'You successfully added your Played Match with:',
    desc: 'Your match is awaiting confirmation from the other participants for the final score validation. To speed up the final score validation process, we kindly suggest that you personally reach out to the other participants and encourage them to confirm your match and score.',
    button: 'Got it',
  },
  matchInviteForm: {
    title: 'You successfully sent your Match Invitation to:',
    desc: 'To ensure the highest turnout, we kindly suggest that you personally reach out to the invited players to encourage their acceptance.',
    button: 'Got it',
  },
  invitationPendingConfirmation: 'Match invitations will be pending acceptance by the invited players.',
  timeRequiredForPlayedMatch: 'Played Ranked Matches can be added up to 5 days after the match date.',
  modalWithdrawTitle: 'Withdraw from Match',
  modalWithdraw: 'Are you sure you want to withdraw from this match?',
  guide: {
    quickGuide: 'Quick Guide',
    title: 'Quick Guide for Friendly Matches',
    intro: 'Experience the joy of playing tennis with Friendly Matches.<br> Play with friends or other players in casual Unranked matches or competitive Ranked matches, for Singles or Doubles, and earn points to climb the rankings.',
    ranked: '<strong>Ranked Matches generate points for winners</strong> in Rankings when final scores are validated:<br><strong>+4 points</strong> per win against the same level<br><strong>+2 points</strong> per win against a lower level<br><strong>+6 points</strong> per win against a higher level',
    unranked: '<strong>Unranked Matches</strong> are casual matches for fun or practice.',
    scores: '<strong>Scores</strong> must be confirmed by opponents (or at least by one opponent for Doubles) within 48h before being validated. Any score change restarts the timer.',
    playedMatches: '<strong>Played Ranked Matches</strong> can be added up to 5 days after the match date.',
    withdrawals: 'Any player <strong>Withdrawals</strong> or <strong>No Match</strong> reports from participants require the match owner\'s attention to review the match details or take any other necessary actions.<br><strong>1 x No-Match</strong> report in a Singles match cancels the match for both players<br><strong>2 x No-Match</strong> reports from the Opponent Team in a Doubles match cancel the match for both teams',
    contact: '<strong>Personally reach out to the other participants</strong> in the match to <strong>encourage their acceptance</strong>, or to inform them in advance about your intention to make any changes or match cancellations. This will help avoid any confusion or inconvenience for other players.',
  },
  report: 'Report',
  gotIt: 'Got it',
  withdrawn: 'Withdrawn',
  canceled: 'Canceled',
  scoresChangeInfo: 'You can only modify the score for this match using the Change Score option after you save any updates and close this form.',
  rankedTooltip: {
    title: 'Winners will receive:',
    1: '+4 points per win against the same level',
    2: '+2 points per win against a lower level',
    3: '+6 points per win against a higher level',
  },
  rankBeforeMatch: 'Rank before match:',
  inviteToMatch: 'Invite to Match',
  cancelPopup: {
    title: 'Are you sure you want to leave this page?',
    stay: 'Stay on Page',
    quit: 'Leave Page',
  },
  invitationMovedToMatches: 'Invitation successfully accepted! Match {{matchId}} has been moved to ',
  matchMovedToScores: 'Score successfully validated! Match {{matchId}} has been moved to ',
  playedMatchMovedToScores: 'Score successfully validated! Match {{matchId}} has been moved to ',
  playedMatchMovedToMatches: 'Match successfully confirmed! Match {{matchId}} has been moved to ',
  scoreValidationInvalidWinner: 'Please double-check your score and fill in the correct and complete score so that a winner/winning team can be determined.',
  landingPage: {
    header: {
      title: 'Play Your Perfect Match',
      description: `Engage in casual Unranked matches or competitive Ranked matches, both Singles and Doubles, in tennis, padel, and other sports. Unlock the excitement of playing with friends or fellow players in Sportya and discovering diverse styles of play. `,
    },
    section1: {
      title: 'Revitalize Your Competitive Spirit',
      description: `Unleash your passion for tennis, padel and other racket sports by connecting with amateur players in your area through Sportya. Whether it's challenging old friends or seeking new rivals, experience the thrill of Competitive and Practice matches. Keep tabs on your progress and see who emerges victorious at the end of each season.`,
    },
    section2: {
      title: 'Flex Play - Customize Your Play, Your Way',
      description: `Enjoy the ultimate flexibility with Friendly Matches, available anytime, anywhere. Take charge by using Sportya Chat to connect with players, set the time and location, and get ready for match day. Need suggestions for playing venues? Check out our partner clubs and book online their courts and sport facilities. `,
    },
    section3: {
      title: 'Elevate Your Skills, Embrace New Challenges',
      description: `Every player presents a unique challenge, offering you the chance to evolve and become a better athlete. Embrace diverse play styles and seize the opportunities to enhance your game with each match.`,
    },
    section4: {
      title: 'Raise Hand - Let Other Players Know You Are Looking for a Match',
      description: `Use the "Raise your hand" option in Sportya and let other players in your region know that you are seeking a match in the near future. `,
    },
    section5: {
      title: 'Match Found? Book a Court / Sport Facility Through Sportya',
      description: `Discover available courts and sport facilities in your area and gather your friends for an unforgettable match. Sportya will fast-track your journey from match finder to court / facility booking in just a few clicks. Say goodbye to tedious phone calls and wasted time.`,
    },
    actions: {
      title: 'Land your best match!',
      button: 'Join Sportya',
    }
  },
};
